import { useCallback, useEffect, useMemo, useState } from 'react'
import useIsMounted from 'ismounted'
import useAuthState from '../../store/slices/auth/useAuthState'
import useProfile from '../../store/slices/profile/useProfile'
import { getAuthCheck, postGhostLogin, postGoogleLogin } from './api'
import { PostLoginData } from './types'

// interface useFakeHookInterface {}
interface useWdAuthenticatorResponse {
  loading: boolean
  logout: () => void
  ghostIn: (id: number) => Promise<void>
  googleIn: (data: PostLoginData) => Promise<void>
  isLoggedIn: boolean
  authcheck: () => Promise<boolean>
  googleId: string | null

  // login: (r: LoginResponseType | null) => Promise<void>
}

const useWdAuthenticator = (): useWdAuthenticatorResponse => {
  const isMounted = useIsMounted()
  const [loading, setLoading] = useState<boolean>(false)
  const { load: loadProfile } = useProfile(false)
  const { auth, logoutState, loginState } = useAuthState()

  const isLoggedIn = !!auth.email && !!auth.tokenId && !!auth.sessionId

  const logout = useCallback(async () => {
    // Do server stuff if needed
    logoutState() // Set the redux state
  }, [logoutState])

  const ghostIn = useCallback(
    async (id: number) => {
      if (isMounted.current) {
        setLoading(true)
      }
      const resp = await postGhostLogin(id, {
        token: auth.tokenId,
        email: auth.email ?? ''
      })
      await loginState(resp)
      if (resp) {
        await loadProfile(true)
      }
      if (isMounted.current) {
        setLoading(false)
      }
    },
    [auth.email, auth.tokenId, isMounted, loadProfile, loginState]
  )

  const googleIn = useCallback(
    async (data: PostLoginData) => {
      if (isMounted.current) {
        setLoading(true)
      }
      const resp = await postGoogleLogin(data)
      await loginState(resp)
      if (isMounted.current) {
        setLoading(false)
      }
    },
    [isMounted, loginState]
  )

  const authcheck = useCallback(async (): Promise<boolean> => {
    if (!isLoggedIn) {
      return false
    }
    const authorized = await getAuthCheck()
    if (!authorized) {
      await logout()
    }
    return authorized
  }, [isLoggedIn, logout])

  useEffect(() => {}, [])
  return useMemo(
    () => ({
      loading,
      logout,
      ghostIn,
      isLoggedIn,
      authcheck,
      googleIn,
      googleId: auth.googleId ?? null
    }),
    [ghostIn, isLoggedIn, loading, logout, authcheck, googleIn, auth.googleId]
  )
}

export default useWdAuthenticator

import { cloneElement, FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography
} from '@mui/material'
import useIsMounted from 'ismounted'
import { useNavigate } from 'react-router-dom'
import DialogTransitionDown from '../../../../components/popups/DialogTransitionDown'
import {
  PopupStackInstanceProps,
  WithoutPopupProps
} from '../../../../contexts/context-types'
import {
  PopupStackTrigger,
  usePopupStackContext
} from '../../../../contexts/PopupStackContext'
import { getJobTicketTypeIcon } from '../Dashboard/utilities'
import {
  JobTicketEntity,
  JobTicketType
} from '../../../../store/slices/job-tickets/entity'
import useProfile from '../../../../store/slices/profile/useProfile'
import UserQuickPick from '../../../../components/people/UserQuickPick'
import { useActiveUsers } from '../../../../store/slices/users/useUsers'
import { newJobTicketEntity } from '../../../../store/slices/job-tickets/utilities'
import { post } from '../../../../remote'
import { WdqlResponse } from '../../../../store/wdql/types'
import { wdqlProcessJobTickets } from '../../../../store/slices/job-tickets/dispatch'
import NoContentComplex from '../../../../components/helpers/NoContentComplex'
import SpinnerIcon from '../../../../components/icons/SpinnerIcon'

export type NewJobTicketHookProps = WithoutPopupProps<NewJobTicketPopupProps>

export const triggerNewJobTicketPopup = ({
  trigger
}: {
  trigger: PopupStackTrigger
}) => {
  trigger<NewJobTicketPopupProps>(NewJobTicketPopup, {})
}

export const useNewJobTicketPopup = (): {
  newJobTicket: (p?: NewJobTicketHookProps) => string
} => {
  const { trigger } = usePopupStackContext()
  const newJobTicket = useCallback(
    (p?: NewJobTicketHookProps): string =>
      trigger<NewJobTicketPopupProps>(NewJobTicketPopup, p ?? {}),
    [trigger]
  )
  return { newJobTicket }
}

export type NewJobTicketPopupProps = {
  customerId?: number | null
} & PopupStackInstanceProps

const NewJobTicketPopup: FC<NewJobTicketPopupProps> = ({
  popupCode,
  popupOnClose,
  customerId,
  ...props
}: NewJobTicketPopupProps) => {
  const { id } = useProfile()
  const { users } = useActiveUsers(false)
  const isMounted = useIsMounted()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [userId, setUserId] = useState<number | null>(null)
  const [type, setType] = useState<JobTicketType | null>(null)

  useEffect(() => {
    if (id) {
      setUserId(id)
    }
  }, [id])
  const handleClose = loading
    ? () => null
    : () => {
        popupOnClose(popupCode)
      }

  const handleSave = async () => {
    if (userId && type) {
      setLoading(true)
      const ticket = newJobTicketEntity({ userId, type })
      ticket.customerId = customerId ?? null
      const resp = await post<WdqlResponse<JobTicketEntity>>(
        '/api/v2/job-ticket',
        ticket
      )
      if (resp?.data && resp.data.valid && !!resp.data.payload.id) {
        wdqlProcessJobTickets([resp.data.payload], 'merge')
        const newId = resp.data.payload.id
        handleClose()
        if (isMounted.current) {
          setLoading(false)
        }
        navigate('/app/job-tickets/edit/' + newId)
      }
    }
  }

  return (
    <Dialog
      {...props}
      open={true}
      onClose={handleClose}
      fullWidth={false}
      maxWidth={'md'}
      TransitionComponent={DialogTransitionDown}
    >
      {loading && (
        <NoContentComplex
          heading={<>Creating Ticket...</>}
          icon={<SpinnerIcon size={40} />}
        />
      )}
      {!loading && (
        <>
          <DialogContent sx={{ mt: 0, pt: 3 }}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={1}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Typography variant={'h5'} sx={{ mb: 1 }}>
                  Service Ticket
                </Typography>
                <NewTicketTypeButton
                  type={'service'}
                  onClick={setType}
                  selected={type === 'service'}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Typography variant={'h5'} sx={{ mb: 1 }}>
                  Contract Ticket
                </Typography>
                <NewTicketTypeButton
                  type={'contract'}
                  onClick={setType}
                  selected={type === 'contract'}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Typography variant={'h5'} sx={{ mb: 1 }}>
                  Change Ticket
                </Typography>
                <NewTicketTypeButton
                  type={'change'}
                  onClick={setType}
                  selected={type === 'change'}
                />
              </Box>
            </Stack>
            <Box maxWidth={{ xs: 300, md: 500 }}>
              {!type && (
                <Box sx={{ pt: 1 }}>
                  Click a ticket type above in order to start the process
                </Box>
              )}
              {type === 'service' && (
                <Box sx={{ pt: 1 }}>
                  Service tickets are for existing customers that need actions
                  taken (usually small or on retainer)
                </Box>
              )}
              {type === 'contract' && (
                <Box sx={{ pt: 1 }}>
                  Contract tickets are for full standalone projects (in
                  Pipeline)
                </Box>
              )}
              {type === 'change' && (
                <Box sx={{ pt: 1 }}>
                  Change tickets specifically edit or cancel existing tickets or
                  products/services
                </Box>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <div style={{ flex: 1 }}>
              <Button onClick={handleClose}>Cancel</Button>
            </div>
            <div>
              <UserQuickPick
                users={users}
                value={userId}
                onChange={setUserId}
              />
            </div>
            <div>
              <Button
                variant={'contained'}
                onClick={handleSave}
                disabled={!userId || !type}
              >
                Start Ticket
              </Button>
            </div>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default NewJobTicketPopup

const NewTicketTypeButton = ({
  type,
  onClick,
  selected
}: {
  type: JobTicketType
  onClick: (v: JobTicketType) => void
  selected: boolean
}) => {
  const info = getJobTicketTypeIcon(type)
  const icon = cloneElement(info.icon, {
    fontSize: 'large',
    style: { width: '150px', height: '150px' }
  })

  const buttonStyle = {
    width: '160px',
    height: '160px',
    paddingTop: '20px',
    backgroundColor: selected ? 'rgba(0, 0, 255, 0.1)' : 'transparent', // Subtle blue background when selected
    borderWidth: '2px',
    borderColor: selected ? 'blue' : 'gray',
    boxShadow: selected ? '0 0 10px rgba(0, 0, 255, 0.3)' : 'none' // Subtle box-shadow when selected
  }

  return (
    <Button
      variant="outlined"
      size="large"
      style={buttonStyle}
      onClick={() => onClick(type)}
    >
      <Box style={{ opacity: 0.15 }}>{icon}</Box>
    </Button>
  )
}

import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteEntityHelperById,
  mergeEntitiesHelperById,
  setEntitiesHelperById
} from '../../../wdql/helpers'
import {
  PartialHelpScoutConversationEntity,
  HelpScoutConversationEntity
} from './entity'

const initialState: Array<HelpScoutConversationEntity> = []

export const helpScoutConversationSlice = createSlice({
  name: 'helpScoutConversations',
  initialState,
  reducers: {
    resetHelpScoutConversations: () => [...initialState],
    replaceHelpScoutConversations: (
      state,
      action: PayloadAction<Array<PartialHelpScoutConversationEntity>>
    ) => action.payload as Array<HelpScoutConversationEntity>,
    setHelpScoutConversations: (
      state,
      action: PayloadAction<Array<PartialHelpScoutConversationEntity>>
    ) => {
      setEntitiesHelperById(state, action.payload)
    },
    mergeHelpScoutConversations: (
      state,
      action: PayloadAction<Array<PartialHelpScoutConversationEntity>>
    ) => {
      mergeEntitiesHelperById(state, action.payload)
    },
    patchHelpScoutConversation: (
      state,
      action: PayloadAction<PartialHelpScoutConversationEntity>
    ) => {
      mergeEntitiesHelperById(state, [action.payload], false)
    },
    deleteHelpScoutConversation: (
      state,
      action: PayloadAction<number | Partial<HelpScoutConversationEntity>>
    ) => deleteEntityHelperById(state, action.payload)
  }
})

export const {
  setHelpScoutConversations,
  replaceHelpScoutConversations,
  mergeHelpScoutConversations,
  deleteHelpScoutConversation,
  patchHelpScoutConversation,
  resetHelpScoutConversations
} = helpScoutConversationSlice.actions

export default helpScoutConversationSlice.reducer

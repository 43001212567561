import { version } from './_version'
import rollbarConfig from './rollbar'
// Configuration Loads
export const APP_VERSION = version
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT
export const IN_DEV_MODE = ENVIRONMENT === 'development'
export const API_URL_PREFIX = process.env.REACT_APP_API_URL_PREFIX
export const AUTOPOLL_AUTH_MS = process.env.REACT_APP_AUTOPOLL_AUTH_MS
export const GOOGLE_LOGIN_APP_ID = process.env.REACT_APP_GOOGLE_LOGIN_APP_ID
export const AUTHCHECK_INTERVAL = IN_DEV_MODE ? 9999999 : 300000 // 25000
export const ROLLBAR_CONFIG = rollbarConfig
export const SERVER_TIMEZONE = process.env.REACT_APP_SERVER_TIMEZONE // 'Etc/UTC' for UTC

export const PD_ACTIVE_PERSON_STATUS_ID = 174558 // The current status for ACTIVE in PD for filtering

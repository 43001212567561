import { useCallback, useEffect, useMemo, useState } from 'react'
import useLoading from '../../../hooks/useLoading'
import useWdAuthenticator from '../../../packages/WdAuthenticator/useWdAuthenticator'

// interface useFakeHookInterface {}
interface useGhostResponse {
  loading: boolean
  ghostLogin: (id: number) => void
}

const useGhost = (): useGhostResponse => {
  const [loggingIn, setLoggingIn] = useState<boolean>(false)
  const { setAppLoading } = useLoading()
  const { ghostIn } = useWdAuthenticator()

  const ghostLogin = useCallback(
    async (id: number) => {
      setLoggingIn(true)
      setAppLoading(true)
      ghostIn(id).then(() => {
        setLoggingIn(false)
        setAppLoading(false)
      })
    },
    [ghostIn, setAppLoading]
  )

  useEffect(() => {}, [])
  return useMemo(
    () => ({ loading: loggingIn, ghostLogin }),
    [ghostLogin, loggingIn]
  )
}

export default useGhost

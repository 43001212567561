import { FC } from 'react'
import { Box } from '@mui/material'
import { LogoProps } from '../LogoSign/types'

const ControlCenterLogo: FC<LogoProps> = (props: LogoProps) => {
  const { size = 48, color = 'black' } = props
  let sizes: number[]

  if (color === 'black') {
    sizes = [512, 384, 256, 192, 16]
  } else if (color === 'white') {
    sizes = [512, 16]
  } else if (color === 'lightblue') {
    sizes = [512, 256, 64, 16]
  } else {
    sizes = [512]
  }

  const logoDim = sizes.reduce((iSize, s) => (size < s ? s : iSize), 512)
  return (
    <Box
      component="img"
      sx={{
        height: size,
        width: size
        // maxHeight: { xs: size, md: size },
        // maxWidth: { xs: size, md: size }
      }}
      alt="Control Center"
      src={
        '/assets/images/logo/' + color + '-' + logoDim + 'x' + logoDim + '.png'
      }
    />
  )
}

export default ControlCenterLogo

import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { RouteComponentLoader } from '../utils/routes'
// Pages

// const Overview = Loader(lazy(() => import('../content/overview')))

const Login = RouteComponentLoader(lazy(() => import('../content/pages/Login')))

// Status

const Status404 = RouteComponentLoader(
  lazy(() => import('../content/pages/Status/Status404'))
)
const Status500 = RouteComponentLoader(
  lazy(() => import('../content/pages/Status/Status500'))
)
const StatusComingSoon = RouteComponentLoader(
  lazy(() => import('../content/pages/Status/ComingSoon'))
)
const StatusMaintenance = RouteComponentLoader(
  lazy(() => import('../content/pages/Status/Maintenance'))
)

const baseRoutes = [
  {
    path: '/',
    element: <Login />
  },
  {
    path: 'overview',
    element: <Navigate to="/" replace />
  },
  {
    path: '*',
    element: <Status404 />
  },
  {
    path: 'status',
    children: [
      {
        path: '500',
        element: <Status500 />
      },
      {
        path: 'maintenance',
        element: <StatusMaintenance />
      },
      {
        path: 'coming-soon',
        element: <StatusComingSoon />
      }
    ]
  },
  {
    path: '*',
    element: <Status404 />
  }
]

export default baseRoutes

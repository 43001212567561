import { FC } from 'react'
import { Box } from '@mui/material'
import Scrollbar from '../../../../components/helpers/Scrollbar'
import SavedSearches from './SavedSearches'
import RecentSearches from './RecentSearches'

interface SearchQuickLinksProps {
  setSearch: (s: string) => void
}

const SearchQuickLinks: FC<SearchQuickLinksProps> = ({ setSearch }) => (
  <Box
    sx={{
      height: 450
    }}
  >
    <Scrollbar>
      <Box pb={2} px={2}>
        <RecentSearches setSearch={setSearch} />
        <SavedSearches setSearch={setSearch} />
      </Box>
    </Scrollbar>
  </Box>
)

export default SearchQuickLinks

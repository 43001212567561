import {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  Box,
  Card,
  InputBase,
  styled,
  Typography,
  useTheme
} from '@mui/material'
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone'
import BackspaceIcon from '@mui/icons-material/Backspace'
import { useTranslation } from 'react-i18next'

const SearchInputWrapper = styled(InputBase)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(18)};
    padding: ${theme.spacing(2)};
    width: 100%;
`
)

interface SearchFormProps {
  onChange?: (s: string) => void
  onSubmit?: (s: string) => void
  searched: string
}

const SearchForm: FC<SearchFormProps> = (props: SearchFormProps) => {
  const { onChange, onSubmit, searched } = props
  const { t }: { t: any } = useTranslation()
  const theme = useTheme()
  const [searchValue, setSearchValue] = useState<string>('')
  // const debouncedSearchValue = useDeferredValue(searchValue)

  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (searchValue === '' && searched !== '') {
      setSearchValue(searched)
    }
  }, [searchValue, searched])

  useEffect(() => {
    inputRef.current?.focus()
  })

  const clearSearch = useCallback(async () => {
    setSearchValue('')
    if (onChange) {
      await onChange('')
    }
  }, [onChange])

  const submitSearch = useCallback(
    async (event: FormEvent<HTMLFormElement>): Promise<void> => {
      event.preventDefault()
      const formElements = event.currentTarget.elements
      let inputElement: HTMLInputElement | undefined
      for (let i = 0; i < formElements.length; i++) {
        const element = formElements[i]
        if (element instanceof HTMLInputElement) {
          inputElement = element
          break
        }
      }
      const inputValue = inputElement?.value ?? ''
      if (onSubmit) {
        await onSubmit(inputValue)
      }
    },
    [onSubmit]
  )

  const handleSearchChange = useCallback(
    async (event: ChangeEvent<{ value: string }>): Promise<void> => {
      event.preventDefault()
      if (event.target.value) {
        setSearchValue(event.target.value)
      } else {
        setSearchValue('')
      }
      if (onChange) {
        await onChange(event.target.value)
      }
    },
    [onChange]
  )

  const prefixIcon =
    searchValue === '' ? (
      <SearchTwoToneIcon
        sx={{
          ml: 2,
          color: theme.colors.secondary.main
        }}
      />
    ) : (
      <BackspaceIcon
        sx={{
          ml: 2,
          color: 'red'
        }}
        onClick={clearSearch}
      />
    )

  return (
    <form onSubmit={submitSearch}>
      <Box display="flex" alignItems="center">
        <Box flexGrow={1} display="flex" alignItems="center">
          {prefixIcon}
          <SearchInputWrapper
            value={searchValue}
            onChange={handleSearchChange}
            autoFocus
            placeholder={t('Search terms here...')}
            fullWidth
            inputRef={inputRef}
          />
        </Box>
        <Card
          sx={{
            ml: 'auto',
            mr: 2,
            py: 0.5,
            px: 1,
            background: theme.colors.alpha.black[10]
          }}
        >
          <Typography variant="body2" color="text.secondary" fontWeight="bold">
            esc
          </Typography>
        </Card>
      </Box>
    </form>
  )
}

export default SearchForm

import { patch, post, remove } from '../../../remote'
import { WdqlResponse } from '../../wdql/types'
import { FollowEntity, PartialFollowEntity } from './entity'
import { wdqlProcessFollows } from './dispatch'

export const saveFollow = async (
  follow: PartialFollowEntity
): Promise<boolean> => {
  if (follow.id && follow.userId && follow.entity && follow.entityId) {
    wdqlProcessFollows([follow as FollowEntity], 'merge')
  }
  if (follow.id) {
    const resp = await patch<WdqlResponse<FollowEntity>>(
      '/api/v2/follow/' + follow.id,
      follow
    )
    if (resp?.data && resp.data.valid) {
      wdqlProcessFollows([resp?.data.payload], 'merge')
      return true
    }
    return false
  } else {
    const resp = await post<WdqlResponse<FollowEntity>>(
      '/api/v2/follow',
      follow
    )
    if (resp?.data && resp.data.valid) {
      wdqlProcessFollows([resp?.data.payload], 'merge')
      return true
    }
    return false
  }
}

export const deleteFollow = async (
  id: number | FollowEntity | null | undefined
): Promise<boolean> => {
  if (!id) {
    return false
  }
  const follow = typeof id === 'number' ? { id } : id
  wdqlProcessFollows([follow as FollowEntity], 'delete')
  const resp = await remove<WdqlResponse<number>>('/api/v2/follow/' + follow.id)
  if (resp?.data && resp.data.valid) {
    wdqlProcessFollows([{ id: resp.data.payload } as FollowEntity], 'delete')
    return true
  }
  return false
}

import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteEntityHelperById,
  mergeEntitiesHelperById,
  setEntitiesHelperById
} from '../../wdql/helpers'
import { PartialAccountExecutiveEntity, AccountExecutiveEntity } from './entity'

const initialState: Array<AccountExecutiveEntity> = []

export const accountexecutiveSlice = createSlice({
  name: 'accountexecutives',
  initialState,
  reducers: {
    resetAccountExecutives: () => [...initialState],
    replaceAccountExecutives: (
      state,
      action: PayloadAction<Array<PartialAccountExecutiveEntity>>
    ) => action.payload as Array<AccountExecutiveEntity>,
    setAccountExecutives: (
      state,
      action: PayloadAction<Array<PartialAccountExecutiveEntity>>
    ) => {
      setEntitiesHelperById(state, action.payload)
    },
    mergeAccountExecutives: (
      state,
      action: PayloadAction<Array<PartialAccountExecutiveEntity>>
    ) => {
      mergeEntitiesHelperById(state, action.payload)
    },
    patchAccountExecutive: (
      state,
      action: PayloadAction<PartialAccountExecutiveEntity>
    ) => {
      mergeEntitiesHelperById(state, [action.payload], false)
    },
    deleteAccountExecutive: (
      state,
      action: PayloadAction<number | Partial<AccountExecutiveEntity>>
    ) => deleteEntityHelperById(state, action.payload)
  }
})

export const {
  setAccountExecutives,
  replaceAccountExecutives,
  mergeAccountExecutives,
  deleteAccountExecutive,
  patchAccountExecutive,
  resetAccountExecutives
} = accountexecutiveSlice.actions

export default accountexecutiveSlice.reducer

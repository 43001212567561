import { useCallback, useEffect, useMemo, useState } from 'react'
import useIsMounted from 'ismounted'
import { useAppSelector } from '../../index'
import { get } from '../../../remote'
import { WdqlResponse } from '../../wdql/types'
import useDeepEqualMemo from '../../../hooks/useDeepEqualMemo'
import useProfile from '../profile/useProfile'
import { FollowEntity, FollowEntityType } from './entity'
import { deleteFollow, saveFollow } from './api'

type MyFollowEntityExists = FollowEntity & {
  found: true
  unfollow: () => Promise<boolean>
}
type MyFollowEntityNone = { found: false; follow: () => Promise<boolean> }
type MyFollowObject = MyFollowEntityExists | MyFollowEntityNone

const fetchFollows = async (onlyMine: boolean) =>
  get<WdqlResponse<Array<FollowEntity>>>(
    onlyMine ? '/api/v2/follows/list/mine' : '/api/v2/follows/list'
  )

// interface useFakeHookInterface {}
interface useFollowsResponse {
  follows: Array<FollowEntity>
  loadMine: () => Promise<void>
  loading: boolean
  findFollow: (id: number) => FollowEntity | null
  myFollow: (type: FollowEntityType, id: number) => MyFollowObject | null
}

const useFollows = (
  initLoad: boolean = true,
  onlyMine: boolean = true
): useFollowsResponse => {
  const isMounted = useIsMounted()
  const { id: profileId } = useProfile()
  const [loading, setLoading] = useState<boolean>(false)
  const follows = useAppSelector((state) => state.entities.follows)
  const followList = useDeepEqualMemo(follows)

  const findFollow = useCallback(
    (id: number): FollowEntity | null =>
      followList?.find((u) => u.id === id) ?? null,
    [followList]
  )

  const myFollowFunction = useCallback(
    (type: FollowEntityType, id: number): MyFollowObject | null => {
      if (!profileId) {
        return null
      }
      const entry =
        followList?.find(
          (f) =>
            f.userId === profileId && f.entity === type && f.entityId === id
        ) ?? null
      if (entry) {
        return {
          found: true,
          ...entry,
          unfollow: async () => deleteFollow(entry.id)
        }
      }
      return {
        found: false,
        follow: async () =>
          saveFollow({
            id: 0,
            userId: profileId,
            entity: type,
            entityId: id
          })
      }
    },
    [followList, profileId]
  )

  const loadMyFollows = useCallback(async () => {
    if (isMounted.current) {
      setLoading(true)
      fetchFollows(onlyMine).then(() => {
        if (isMounted.current) {
          setLoading(false)
        }
      })
    }
  }, [isMounted, onlyMine])

  useEffect(() => {
    if (initLoad && profileId) {
      loadMyFollows().then()
    }
  }, [initLoad, loadMyFollows, profileId])

  return useMemo(
    () => ({
      follows: followList ?? [],
      loadMine: loadMyFollows,
      loading,
      findFollow,
      myFollow: myFollowFunction
    }),
    [followList, loadMyFollows, loading, findFollow, myFollowFunction]
  )
}

export default useFollows

import { SliceModes } from '../../wdql/types'
import { storeDispatch } from '../../index'
import { PartialUserEntity, UserEntity } from './entity'
import {
  deleteUser,
  mergeUsers,
  patchUser,
  replaceUsers,
  setUsers
} from './reducer'

export const wdqlProcessUsers = (
  data: Array<UserEntity> | Array<PartialUserEntity>,
  mode: SliceModes
) => {
  switch (mode) {
    case 'replace':
      storeDispatch(setUsers(data))
      break
    case 'merge':
      storeDispatch(mergeUsers(data))
      break
    case 'patch':
      storeDispatch(patchUser(data[0]))
      break
    case 'clear':
      storeDispatch(replaceUsers(data))
      break
    case 'delete':
      storeDispatch(deleteUser(data[0]))
      break
  }
}

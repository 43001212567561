import { useEffect, useRef } from 'react'

function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(() => {})

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null && delay > 0) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
    return undefined // Explicitly return undefined when no interval is set
  }, [delay])
}

export default useInterval

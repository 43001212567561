export const setEntitiesHelperById = <T extends { id: number | string }>(
  state: T[],
  payload: T[]
): void => {
  payload.forEach((a) => {
    const i = state.findIndex((f) => f.id === a.id)
    void (i >= 0 ? (state[i] = a) : state.push(a))
  })
}

export const setEntitiesHelperByKey = <T extends { key: string }>(
  state: T[],
  payload: T[]
): void => {
  payload.forEach((a) => {
    const i = state.findIndex((f) => f.key === a.key)
    void (i >= 0 ? (state[i] = a) : state.push(a))
  })
}

export const addEntitiesHelperById = <T extends { id: number | string }>(
  state: T[],
  payload: T[]
): void => {
  const existingIds = new Set(state.map((item) => item.id))

  payload.forEach((a) => {
    if (!existingIds.has(a.id)) {
      state.push(a)
      existingIds.add(a.id)
    }
  })
}

export const mergeEntitiesHelperById = <T extends { id: number | string }>(
  state: T[],
  payload: T[],
  insertMissing: boolean = true
): void => {
  payload.forEach((a) => {
    const i = state.findIndex((f) => f.id === a.id)
    if (i >= 0) {
      state[i] = { ...state[i], ...a }
    } else if (insertMissing) {
      state.push(a)
    }
  })
}

export const mergeEntitiesHelperByKey = <T extends { key: string }>(
  state: T[],
  payload: T[],
  insertMissing: boolean = true
): void => {
  payload.forEach((a) => {
    const i = state.findIndex((f) => f.key === a.key)
    if (i >= 0) {
      state[i] = { ...state[i], ...a }
    } else if (insertMissing) {
      state.push(a)
    }
  })
}

export const deleteEntityHelperById = <T extends { id: number | string }>(
  state: T[],
  payload: number | Partial<T>
): T[] => {
  const id = typeof payload === 'number' ? payload : payload.id
  return state.filter((e) => e.id !== id)
}

export const deleteEntityHelperByKey = <T extends { key: string }>(
  state: T[],
  payload: string | Partial<T>
): T[] => {
  const i = typeof payload === 'string' ? payload : payload.key
  return state.filter((e) => e.key !== i)
}

import { memoize } from 'lodash'
import { ReactElement } from 'react'
import { getStoreInstance } from '../../../../store'
import { scoreString } from '../../../../utils/strings'
import { CustomerEntity } from '../../../../store/slices/customers/entity'
import CustomerLogo from '../../../../components/segments/customers/CustomerLogo'

export interface SearchResult {
  label: string
  link: string
  category: string
  score: number
  icon: () => ReactElement
  active: boolean
}

export interface SearchResultSet {
  [k: string]: Array<SearchResult>
}

const searchScore = (search: string, haystack: string): number =>
  scoreString(haystack, search)

const getSearchResults = async (search: string): Promise<SearchResultSet> => {
  if (search.trim() === '') {
    return {}
  }
  const customers: Array<CustomerEntity> =
    getStoreInstance()?.getState().entities.customers ?? []

  const cList = memoize((cust: Array<CustomerEntity>, sear: string) =>
    cust
      .map((c) => {
        const isActive = c.status === 'ACTIVE'
        const sScore = searchScore(sear, c.name) * (isActive ? 100 : 1)

        // Determine border color based on score
        let borderColor: string
        if (sScore > 0.5) {
          borderColor = 'green'
        } else if (sScore > 0.25) {
          borderColor = 'yellow'
        } else {
          borderColor = 'red'
        }

        return {
          label: c.name,
          link: '/app/customers/detail/' + c.id + '/dashboard',
          category: 'Customers',
          score: sScore,
          active: isActive,
          icon: () => (
            <CustomerLogo
              customer={c}
              sx={{
                mr: 1,
                width: 20,
                height: 20,
                fontSize: 10,
                border: 2,
                borderColor
              }}
            />
          )
        }
      })
      .filter((s) => s.score > 0)
      .sort((a, b) => b.score - a.score)
      .slice(0, 10)
  )

  return { Customers: cList(customers, search) }
}

export default getSearchResults

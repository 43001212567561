import { FC } from 'react'
import { Backdrop, CircularProgress } from '@mui/material'

interface LoadingGlobalProps {
  show: boolean
}

const LoadingGlobal: FC<LoadingGlobalProps> = (props: LoadingGlobalProps) => {
  const { show = false } = props
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={show}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

export default LoadingGlobal

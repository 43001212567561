import { createContext, FC, ReactNode, useEffect, useState } from 'react'
import isOnline from 'is-online'

type GlobalContextType = {
  loaded: boolean
  setLoaded: (b: boolean) => void
  online: boolean
  isSpinning: boolean
  setSpinning: (b: boolean) => void
}

interface GlobalContextProps {
  children: ReactNode
}

export const GlobalContext = createContext<GlobalContextType>({
  loaded: false,
  setLoaded: () => null,
  online: true,
  isSpinning: false,
  setSpinning: () => null
})

const GlobalContextWrapper: FC<GlobalContextProps> = (
  props: GlobalContextProps
) => {
  const { children } = props
  const [loaded, setLoaded] = useState<boolean>(false)
  const [online, setOnline] = useState<boolean>(true)
  const [isSpinning, setSpinning] = useState<boolean>(false)
  // console.log('GlobalContext', loaded)
  useEffect(() => {
    isOnline().then((r) => setOnline(r))
    setLoaded(false)
  }, [])

  return (
    <GlobalContext.Provider
      value={{
        loaded,
        setLoaded,
        online,
        isSpinning,
        setSpinning
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContextWrapper

import { FC } from 'react'
import { alpha, Box, Link, styled, Typography } from '@mui/material'
import KeyboardArrowRightTwoToneIcon from '@mui/icons-material/KeyboardArrowRightTwoTone'
import { Link as RouterLink } from 'react-router-dom'
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled'
import BusinessTwoToneIcon from '@mui/icons-material/BusinessTwoTone'
import { SearchResult } from './getSearchResults'

const ListButton = styled(Box)(
  ({ theme }) => `
      background-color: transparent;
      color:  ${theme.colors.alpha.black[100]};
      transition: ${theme.transitions.create(['all'])};
      border: ${theme.colors.alpha.black[10]} solid 1px;
      border-radius: ${theme.general.borderRadius};
      padding: ${theme.spacing(1)};
      margin: ${theme.spacing(1, 0)};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > div > .MuiSvgIcon-root {
        color:  ${theme.colors.alpha.black[50]};
        transition: ${theme.transitions.create(['all'])};
      }

      &:hover {
        background-color: ${alpha(theme.colors.primary.main, 0.08)};
        color:  ${theme.colors.primary.main};
        border-color: ${alpha(theme.colors.primary.main, 0.3)};

        & > div > .MuiSvgIcon-root {
          color:  ${theme.colors.primary.main};
        }
      }
`
)

const typeStyle = {
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none'
  }
}

interface SearchResultItemProps {
  item: SearchResult
  onClick?: () => void
}

const SearchResultItem: FC<SearchResultItemProps> = ({ item, onClick }) => {
  const opacity = item.active ? 1 : 0.4
  return (
    <Link
      component={RouterLink}
      to={item.link}
      onClick={onClick}
      style={typeStyle}
    >
      <ListButton>
        <Box display="flex" alignItems="flex-start" sx={{ opacity }}>
          {item.icon()}
          <Typography style={typeStyle}>{item.label}</Typography>
        </Box>
        <Box sx={{ opacity }}>
          {!item.active && <DomainDisabledIcon fontSize="small" />}
          {item.active && (
            <BusinessTwoToneIcon fontSize="small" style={{ color: 'blue' }} />
          )}
          <KeyboardArrowRightTwoToneIcon fontSize="small" color="error" />
        </Box>
      </ListButton>
    </Link>
  )
}

export default SearchResultItem

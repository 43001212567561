import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteEntityHelperById,
  mergeEntitiesHelperById,
  setEntitiesHelperById
} from '../../wdql/helpers'
import { PartialActivityEntity, ActivityEntity } from './entity'

const initialState: Array<ActivityEntity> = []

export const activitySlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    resetActivities: () => [...initialState],
    replaceActivities: (
      state,
      action: PayloadAction<Array<PartialActivityEntity>>
    ) => action.payload as Array<ActivityEntity>,
    setActivities: (
      state,
      action: PayloadAction<Array<PartialActivityEntity>>
    ) => {
      setEntitiesHelperById(state, action.payload)
    },
    mergeActivities: (
      state,
      action: PayloadAction<Array<PartialActivityEntity>>
    ) => {
      mergeEntitiesHelperById(state, action.payload)
    },
    patchActivity: (state, action: PayloadAction<PartialActivityEntity>) => {
      mergeEntitiesHelperById(state, [action.payload], false)
    },
    deleteActivity: (
      state,
      action: PayloadAction<number | Partial<ActivityEntity>>
    ) => deleteEntityHelperById(state, action.payload),
    deleteActivityByCompanyId: (state, action: PayloadAction<number>) =>
      state.filter((a) => a.relations?.customerId !== action.payload)
  }
})

export const {
  setActivities,
  replaceActivities,
  mergeActivities,
  deleteActivity,
  patchActivity,
  resetActivities,
  deleteActivityByCompanyId
} = activitySlice.actions

export default activitySlice.reducer

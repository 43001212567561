import { SliceModes } from '../../wdql/types'
import { storeDispatch } from '../../index'
import { PartialSearchEntity, SearchEntity } from './entity'
import {
  deleteSearch,
  mergeSearches,
  patchSearch,
  replaceSearches,
  setSearches
} from './reducer'

export const wdqlProcessSearches = (
  data: Array<SearchEntity> | Array<PartialSearchEntity>,
  mode: SliceModes
) => {
  switch (mode) {
    case 'replace':
      storeDispatch(setSearches(data))
      break
    case 'merge':
      storeDispatch(mergeSearches(data))
      break
    case 'patch':
      storeDispatch(patchSearch(data[0]))
      break
    case 'clear':
      storeDispatch(replaceSearches(data))
      break
    case 'delete':
      storeDispatch(deleteSearch(data[0]))
      break
  }
}

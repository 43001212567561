import { useCallback, useEffect, useMemo, useState } from 'react'
import useIsMounted from 'ismounted'
import { useAppSelector } from '../../index'
import { get } from '../../../remote'
import { WdqlResponse } from '../../wdql/types'
import useDeepEqualMemo from '../../../hooks/useDeepEqualMemo'
import { DomainEntity } from './entity'
import { wdqlProcessDomains } from './dispatch'

// interface useFakeHookInterface {}
interface useDomainsResponse {
  domains: Array<DomainEntity>
  load: () => Promise<void>
  loading: boolean
  findDomain: (id: number) => DomainEntity | null
  customerDomains: (customerId: number) => Array<DomainEntity>
}

const useDomains = (initLoad: boolean = true): useDomainsResponse => {
  const isMounted = useIsMounted()
  const [loading, setLoading] = useState<boolean>(false)
  const domains = useAppSelector((state) => state.entities.domains)
  const domainList = useDeepEqualMemo(domains)

  const customerDomains = useCallback(
    (customerId: number) =>
      (domainList ?? []).filter((d) => d.customerId === customerId),
    [domainList]
  )

  const findDomain = useCallback(
    (id: number): DomainEntity | null =>
      domainList?.find((u) => u.id === id) ?? null,
    [domainList]
  )

  const loadDomains = useCallback(async () => {
    if (isMounted.current) {
      setLoading(true)
      await get<WdqlResponse<Array<DomainEntity>>>('/api/v2/domains').then(
        (resp) => {
          if (resp?.data.valid && !resp.cached) {
            wdqlProcessDomains(resp.data.payload, 'merge')
          }
          if (isMounted.current) {
            setLoading(false)
          }
        }
      )
    }
  }, [isMounted])

  useEffect(() => {
    if (initLoad) {
      loadDomains().then()
    }
  }, [initLoad, loadDomains])

  return useMemo(
    () => ({
      domains: domainList ?? [],
      load: loadDomains,
      loading,
      findDomain,
      customerDomains
    }),
    [domainList, loadDomains, loading, findDomain, customerDomains]
  )
}

export default useDomains

import { FC, ReactNode } from 'react'
import { Box, styled } from '@mui/material'

const NoResults = styled(Box)(
  ({ theme }) => `
      background-color: transparent;
      text-align: center;
      color:  ${theme.colors.alpha.black[100]};
      transition: ${theme.transitions.create(['all'])};
      border: ${theme.colors.alpha.black[10]} solid 1px;
      border-radius: ${theme.general.borderRadius};
      padding: 25px;
      margin: ${theme.spacing(1, 0)};
`
)

interface NoContentSimpleProps {
  children: ReactNode
}

const NoContentSimple: FC<NoContentSimpleProps> = ({ children }) => (
  <NoResults>{children}</NoResults>
)

export default NoContentSimple

import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  addEntitiesHelperById,
  deleteEntityHelperById,
  mergeEntitiesHelperById,
  setEntitiesHelperById
} from '../../wdql/helpers'
import { PartialCustomerEntity, CustomerEntity } from './entity'

const initialState: Array<CustomerEntity> = []

export const customerSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    resetCustomers: () =>
      // console.log('resetCustomers')
      [...initialState],
    replaceCustomers: (
      state,
      action: PayloadAction<Array<PartialCustomerEntity>>
    ) =>
      // console.log('replaceCustomers', action.payload)
      action.payload as Array<CustomerEntity>,
    setCustomers: (
      state,
      action: PayloadAction<Array<PartialCustomerEntity>>
    ) => {
      // console.log('setCustomers', action.payload)
      setEntitiesHelperById(state, action.payload)
    },
    addCustomers: (
      state,
      action: PayloadAction<Array<PartialCustomerEntity>>
    ) => {
      addEntitiesHelperById(state, action.payload)
    },
    mergeCustomers: (
      state,
      action: PayloadAction<Array<PartialCustomerEntity>>
    ) => {
      // console.log('mergeCustomers', action.payload)
      mergeEntitiesHelperById(state, action.payload)
    },
    patchCustomer: (state, action: PayloadAction<PartialCustomerEntity>) => {
      // console.log('patchCustomer', action.payload)
      mergeEntitiesHelperById(state, [action.payload], false)
    },
    deleteCustomer: (
      state,
      action: PayloadAction<number | Partial<CustomerEntity>>
    ) =>
      // console.log('deleteCustomer', action.payload)
      deleteEntityHelperById(state, action.payload)
  }
})

export const {
  addCustomers,
  setCustomers,
  replaceCustomers,
  mergeCustomers,
  deleteCustomer,
  patchCustomer,
  resetCustomers
} = customerSlice.actions

export default customerSlice.reducer

import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteEntityHelperById,
  mergeEntitiesHelperById,
  setEntitiesHelperById
} from '../../../wdql/helpers'
import { PartialPaymoProjectEntity, PaymoProjectEntity } from './entity'

const initialState: Array<PaymoProjectEntity> = []

export const paymoProjectSlice = createSlice({
  name: 'paymoProjects',
  initialState,
  reducers: {
    resetPaymoProjects: () => [...initialState],
    replacePaymoProjects: (
      state,
      action: PayloadAction<Array<PartialPaymoProjectEntity>>
    ) => action.payload as Array<PaymoProjectEntity>,
    setPaymoProjects: (
      state,
      action: PayloadAction<Array<PartialPaymoProjectEntity>>
    ) => {
      setEntitiesHelperById(state, action.payload)
    },
    mergePaymoProjects: (
      state,
      action: PayloadAction<Array<PartialPaymoProjectEntity>>
    ) => {
      mergeEntitiesHelperById(state, action.payload)
    },
    patchPaymoProject: (
      state,
      action: PayloadAction<PartialPaymoProjectEntity>
    ) => {
      mergeEntitiesHelperById(state, [action.payload], false)
    },
    deletePaymoProject: (
      state,
      action: PayloadAction<number | Partial<PaymoProjectEntity>>
    ) => deleteEntityHelperById(state, action.payload)
  }
})

export const {
  setPaymoProjects,
  replacePaymoProjects,
  mergePaymoProjects,
  deletePaymoProject,
  patchPaymoProject,
  resetPaymoProjects
} = paymoProjectSlice.actions

export default paymoProjectSlice.reducer

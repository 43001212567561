import { FC, SyntheticEvent, useCallback } from 'react'
import {
  alpha,
  Box,
  IconButton,
  styled,
  Tooltip,
  Typography
} from '@mui/material'
import RestoreTwoToneIcon from '@mui/icons-material/RestoreTwoTone'
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone'
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone'
import { useTranslation } from 'react-i18next'
import {
  deleteSearchItem,
  saveSavedSearch
} from '../../../../store/slices/searches/actions'

const ListButton = styled(Box)(
  ({ theme }) => `
      background-color: transparent;
      color:  ${theme.colors.alpha.black[100]};
      transition: ${theme.transitions.create(['all'])};
      border: ${theme.colors.alpha.black[10]} solid 1px;
      border-radius: ${theme.general.borderRadius};
      padding: ${theme.spacing(1)};
      margin: ${theme.spacing(1, 0)};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > div > .MuiSvgIcon-root {
        color:  ${theme.colors.alpha.black[50]};
        transition: ${theme.transitions.create(['all'])};
      }

      &:hover {
        background-color: ${alpha(theme.colors.primary.main, 0.08)};
        color:  ${theme.colors.primary.main};
        border-color: ${alpha(theme.colors.primary.main, 0.3)};

        & > div > .MuiSvgIcon-root {
          color:  ${theme.colors.primary.main};
        }
      }
`
)

const recentIcon = (
  <RestoreTwoToneIcon
    sx={{
      mr: 1
    }}
    fontSize="small"
  />
)

const savedIcon = (
  <StarTwoToneIcon
    sx={{
      mr: 1
    }}
    fontSize="small"
  />
)

interface SearchTriggerProps {
  id: number
  term: string
  type: 'recent' | 'saved'
  onActivate: (s: string) => void
}

const SearchTrigger: FC<SearchTriggerProps> = ({
  id,
  term,
  type,
  onActivate
}) => {
  const { t }: { t: any } = useTranslation()

  const canSave = type === 'recent'
  const prefixIcon = type === 'recent' ? recentIcon : savedIcon
  const saveSearch = useCallback(() => {
    saveSavedSearch(id)
  }, [id])
  const deleteItem = useCallback(() => deleteSearchItem(id), [id])
  const onClick = useCallback(() => onActivate(term), [onActivate, term])

  const handleSaveClick = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation()
      saveSearch()
    },
    [saveSearch]
  )

  const handleDeleteClick = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation()
      deleteItem()
    },
    [deleteItem]
  )

  return (
    <ListButton onClick={onClick}>
      <Box display="flex" alignItems="center">
        {prefixIcon}
        <Typography>{term}</Typography>
      </Box>
      <Box>
        {canSave && (
          <Tooltip placement="top" arrow title={t('Save this search')}>
            <IconButton size="small" color="primary" onClick={handleSaveClick}>
              <StarTwoToneIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip
          placement="top"
          arrow
          title={t('Remove this search from ' + type)}
        >
          <IconButton size="small" color="error" onClick={handleDeleteClick}>
            <CloseTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    </ListButton>
  )
}

export default SearchTrigger

import { FC, MouseEvent, useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Avatar from '@mui/material/Avatar'
import Chip from '@mui/material/Chip'
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined'
import { UserEntity } from '../../store/slices/users/entity'

interface UserQuickPickProps {
  value?: number | UserEntity | null
  onChange?: (u: number | null) => void
  users: Array<UserEntity>
  allowNone?: boolean
}

const UserQuickPick: FC<UserQuickPickProps> = ({
  value = null,
  onChange = () => null,
  users,
  allowNone = true
}: UserQuickPickProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  let id: number | null

  if (value) {
    if (typeof value === 'number') {
      id = value
    } else {
      id = value.id
    }
  } else {
    id = null
  }

  const openPicker = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClick = (userId: number | null) => {
    onChange(userId)
    setAnchorEl(null)
  }

  const user =
    (typeof value === 'object' ? value : users.find((u) => u.id === id)) ?? null
  const chipContent = {
    label: !!user ? user.fullName : 'No User Selected',
    avatar: !!user ? (
      <Avatar src={user.picture} />
    ) : (
      <NotInterestedOutlinedIcon />
    )
  }

  return (
    <div>
      <Chip
        avatar={chipContent.avatar}
        label={chipContent.label}
        variant="outlined"
        onClick={openPicker}
      />
      <Menu anchorEl={anchorEl} open={open} onClose={() => handleClick(null)}>
        {allowNone && (
          <MenuItem
            key={'null'}
            selected={null === id}
            onClick={() => handleClick(null)}
          >
            <NotInterestedOutlinedIcon sx={{ width: 30, height: 30, mr: 1 }} />{' '}
            Clear / No User
          </MenuItem>
        )}
        {users.map((user) => (
          <MenuItem
            key={user.id}
            selected={user.id === id}
            onClick={() => handleClick(user.id)}
          >
            <Avatar
              alt={user.fullName}
              src={user.picture}
              sx={{ width: 30, height: 30, mr: 1 }}
            />{' '}
            {user.fullName}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default UserQuickPick

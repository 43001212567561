import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { positionValues, Scrollbars } from 'react-custom-scrollbars-2'

import { Box, useTheme } from '@mui/material'
// import useTraceUpdate from '../../../hooks/useTraceUpdate'

interface ScrollbarProps {
  className?: string
  children?: ReactNode
  onReachBottom?: () => Promise<void>
}

const triggerPromiseJump = async (
  promiseCall: () => Promise<void>,
  jumpTo: number
): Promise<number> => {
  await promiseCall()
  // return 600
  // return jumpTo - 100
  // return 400
  return jumpTo
}

const Scrollbar: FC<ScrollbarProps> = ({
  children,
  onReachBottom,
  ...rest
}) => {
  const theme = useTheme()
  const [loading, setLoading] = useState<boolean>(false)
  const [jumpTo, setJumpTo] = useState<number>(0)
  const [scrollHeight, setScrollHeight] = useState<number>(0)
  const scrollbarRef = useRef<Scrollbars>(null)
  const currentHeight = scrollbarRef?.current?.getScrollHeight() ?? 0

  useEffect(() => {
    if (jumpTo > 0 && currentHeight !== scrollHeight) {
      // console.log('Do the Jump', currentHeight, jumpTo)
      setScrollHeight(currentHeight)
      scrollbarRef?.current?.scrollTop(jumpTo)
      setJumpTo(0)
    }
  }, [currentHeight, jumpTo, scrollHeight])

  const handleScroll = useCallback(
    async (values: positionValues) => {
      // console.log(
      //   'SCROLL:',
      //   values,
      //   scrollbarRef?.current?.getScrollTop(),
      //   scrollbarRef?.current?.getScrollHeight()
      // )
      const triggerAt = 0.9 // (values.scrollHeight - 100) * 0.9
      if (
        onReachBottom &&
        scrollbarRef.current &&
        // values.scrollTop > triggerAt &&
        values.top >= triggerAt &&
        // values.top >= 1 &&
        // values.top <= 1.1 &&
        values.scrollHeight > values.clientHeight &&
        !loading
      ) {
        // console.log('VALUES', values)
        const setTo = values.scrollTop ?? 0
        // setScrollTop(setTo)
        // console.log('SET scrollTop TO', setTo)
        setLoading(true)
        setJumpTo(setTo)
        scrollbarRef?.current?.scrollTop(setTo - 1)
        triggerPromiseJump(onReachBottom, setTo).then((scrollToTop) => {
          setLoading(false)
          // console.log('JUMP TO TOP', scrollToTop)
          scrollbarRef?.current?.scrollToTop()
          // console.log('JUMP TO scrollToTop', scrollToTop)
          scrollbarRef?.current?.scrollTop(scrollToTop)
        })

        // console.log('JUMP TO scrollTop', scrollTop)
        // scrollbarRef.current.scrollToTop()
        // scrollbarRef.current.scrollTop(scrollTop)
      }
    },
    [loading, onReachBottom]
  )

  // useTraceUpdate(
  //   {
  //     loading: loading,
  //     jumpTo: jumpTo,
  //     scrollHeight: scrollHeight,
  //     scrollbarRef: scrollbarRef,
  //     currentHeight: currentHeight,
  //     handleScroll: handleScroll
  //   },
  //   'Scrollbar',
  //   false,
  //   false
  // )

  // const handleContentUpdate = useCallback(() => {
  //   scrollbarRef.current?.scrollTop(scrollTop)
  // }, [scrollTop])

  return (
    <Scrollbars
      ref={scrollbarRef}
      autoHide
      onScrollFrame={handleScroll}
      // onUpdate={handleContentUpdate}
      renderThumbVertical={() => (
        <Box
          sx={{
            width: 5,
            background: `${theme.colors.alpha.black[10]}`,
            borderRadius: `${theme.general.borderRadiusLg}`,
            transition: `${theme.transitions.create(['background'])}`,

            '&:hover': {
              background: `${theme.colors.alpha.black[30]}`
            }
          }}
        />
      )}
      {...rest}
    >
      {children}
      {loading && <>LOADING...</>}
    </Scrollbars>
  )
}

export default Scrollbar

import { lazy } from 'react'
import { RouteComponentLoader } from '../utils/routes'

const JobTicketHome = RouteComponentLoader(
  lazy(() => import('../content/pages/JobTickets'))
)

const TicketWizardHome = RouteComponentLoader(
  lazy(() => import('../content/pages/JobTickets/Editor'))
)

const jobTicketRoutes = [
  {
    path: '',
    element: <JobTicketHome />
  },
  { path: 'edit/:ticketId?', element: <TicketWizardHome /> }
]

export default jobTicketRoutes

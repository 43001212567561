import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { ReactNode, useRef, useState } from 'react'

export interface ActionMenuOption<AMO = Function> {
  label: string
  onClick?: AMO
  icon?: ReactNode
}

export interface ActionMenuPopupProps<
  AMO = Function,
  SDATA = any,
  DATA extends object = {}
> {
  children: ReactNode
  menu: Array<ActionMenuOption<AMO>>
  data?: SDATA
  extra?: DATA
}

const ActionMenuPopup = <
  AMO extends Function,
  SDATA = any,
  DATA extends object = {}
>(
  props: ActionMenuPopupProps<AMO, SDATA, DATA>
) => {
  const { children, menu, data, extra } = props
  const actionMenuRef = useRef<any>(null)
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  const openMenu = () => {
    setMenuOpen(true)
  }

  const clickItem = () => {
    setMenuOpen(false)
  }

  return (
    <>
      <div onClick={openMenu} ref={actionMenuRef}>
        {children}
      </div>
      <Menu
        disableScrollLock
        anchorEl={actionMenuRef.current}
        onClose={() => setMenuOpen(false)}
        open={menuOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{ p: 0, padding: 0 }}
        style={{ padding: 0 }}
        MenuListProps={{ sx: { padding: 0 } }}
      >
        {menu.map((mOption) => {
          const optClick = () => {
            clickItem()
            return mOption.onClick ? mOption.onClick(data, extra) : undefined
          }
          return (
            <MenuItem key={mOption.label} onClick={optClick}>
              {mOption.icon && (
                <ListItemIcon sx={{ pr: 1 }}>{mOption.icon}</ListItemIcon>
              )}
              <ListItemText>{mOption.label}</ListItemText>
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

export default ActionMenuPopup

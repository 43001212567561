import { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Avatar, Link, SxProps, Theme } from '@mui/material'
import BusinessTwoToneIcon from '@mui/icons-material/BusinessTwoTone'
import CustomerMutator from '../../../store/slices/customers/mutator'
import { CustomerEntity } from '../../../store/slices/customers/entity'

interface CustomerLogoProps {
  customer?: CustomerEntity
  linked?: boolean
  sx?: SxProps<Theme> | undefined
}

const CustomerLogo: FC<CustomerLogoProps> = (props: CustomerLogoProps) => {
  const { customer, linked = false, sx: sxProp = {} } = props

  const avatar = (
    <Avatar
      sx={{
        mr: 1,
        ...sxProp
      }}
      src={!!customer ? CustomerMutator(customer).logo() : undefined}
    >
      <BusinessTwoToneIcon fontSize="large" />
    </Avatar>
  )
  if (!linked) {
    return avatar
  }

  return (
    <Link
      component={RouterLink}
      to={`/app/customers/detail/` + customer?.id + '/dashboard'}
    >
      {avatar}
    </Link>
  )
}

export default CustomerLogo

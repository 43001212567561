import { getUniqueId } from '../../../utils/randomId'
import {
  JobTicketDraftData,
  JobTicketEntity,
  JobTicketSegment,
  JobTicketSegmentItem,
  JobTicketType
} from './entity'

export const emptyDraftData: JobTicketDraftData = {
  newFolder: { create: false },
  newCustomer: { create: false }
}

export const emptyJobTicket: Omit<JobTicketEntity, 'userId' | 'type'> = {
  id: 0,
  uniqId: '',
  customerId: null,
  accounting: { upfrontComplete: false, billingComplete: false },
  approvals: [],
  authorization: null,
  folderId: null,
  platformId: null,
  pipelineIds: [],
  helpscoutScopeIds: [],
  relatedToTickets: [],
  history: [],
  notes: [],
  paymoProjects: [],
  people: [],
  segments: [],
  state: 'draft',
  status: 'pending',
  ticketAt: null,
  title: null,
  draftData: emptyDraftData
}

export const emptyJobTicketSegment: Omit<JobTicketSegment, 'uniqId'> = {
  title: null,
  description: null,
  fileIds: [],
  paymoId: null,
  items: []
}

export const emptyJobTicketSegmentItem: Omit<
  JobTicketSegmentItem,
  'uniqId' | 'pricebookId'
> = {
  configuration: null
}

interface NewJobTicketInput {
  userId: number
  type: JobTicketType
}
export const newJobTicketEntity = ({
  userId,
  type
}: NewJobTicketInput): JobTicketEntity => ({
  ...emptyJobTicket,
  uniqId: getUniqueId(),
  userId,
  type
})

export const newJobTicketSegment = (): JobTicketSegment => ({
  ...emptyJobTicketSegment,
  uniqId: getUniqueId()
})

export const newJobTicketSegmentItem = (
  pricebookId: number
): JobTicketSegmentItem => ({
  ...emptyJobTicketSegmentItem,
  uniqId: getUniqueId(),
  pricebookId
})

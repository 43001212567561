import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteEntityHelperById,
  mergeEntitiesHelperById,
  setEntitiesHelperById
} from '../../../wdql/helpers'
import {
  PartialBucketLedgerEntryEntity,
  BucketLedgerEntryEntity
} from './entity'

const initialState: Array<BucketLedgerEntryEntity> = []

export const bucketledgerentrySlice = createSlice({
  name: 'bucketledgerentries',
  initialState,
  reducers: {
    resetBucketLedgerEntries: () => [...initialState],
    replaceBucketLedgerEntries: (
      state,
      action: PayloadAction<Array<PartialBucketLedgerEntryEntity>>
    ) => action.payload as Array<BucketLedgerEntryEntity>,
    setBucketLedgerEntries: (
      state,
      action: PayloadAction<Array<PartialBucketLedgerEntryEntity>>
    ) => {
      setEntitiesHelperById(state, action.payload)
    },
    mergeBucketLedgerEntries: (
      state,
      action: PayloadAction<Array<PartialBucketLedgerEntryEntity>>
    ) => {
      mergeEntitiesHelperById(state, action.payload)
    },
    patchBucketLedgerEntry: (
      state,
      action: PayloadAction<PartialBucketLedgerEntryEntity>
    ) => {
      mergeEntitiesHelperById(state, [action.payload], false)
    },
    deleteBucketLedgerEntry: (
      state,
      action: PayloadAction<number | Partial<BucketLedgerEntryEntity>>
    ) => deleteEntityHelperById(state, action.payload)
  }
})

export const {
  setBucketLedgerEntries,
  replaceBucketLedgerEntries,
  mergeBucketLedgerEntries,
  deleteBucketLedgerEntry,
  patchBucketLedgerEntry,
  resetBucketLedgerEntries
} = bucketledgerentrySlice.actions

export default bucketledgerentrySlice.reducer

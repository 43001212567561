import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import './mocks'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import './ReactotronConfig'
import './utils/extenders'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import ScrollTop from './hooks/useScrollTop'

// CSS Files
import 'nprogress/nprogress.css'
import 'react-image-crop/dist/ReactCrop.css'
import './components/modals/ImageManipulator/styles.css'

import store from './store'
import App from './App'
import { SidebarProvider } from './contexts/SidebarContext'
import * as serviceWorker from './serviceWorker'
import { ROLLBAR_CONFIG } from './config'
import GlobalContextWrapper from './contexts/GlobalContext'
import { PopupStackProvider } from './contexts/PopupStackContext'

// import { AuthProvider } from './contexts/AMAuthContext'

TimeAgo.addDefaultLocale(en)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <GlobalContextWrapper>
    <RollbarProvider config={ROLLBAR_CONFIG}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ErrorBoundary>
          <HelmetProvider>
            <Provider store={store}>
              <PopupStackProvider>
                <RecoilRoot>
                  <SidebarProvider>
                    <BrowserRouter>
                      <ScrollTop />
                      <App />
                    </BrowserRouter>
                  </SidebarProvider>
                </RecoilRoot>
              </PopupStackProvider>
            </Provider>
          </HelmetProvider>
        </ErrorBoundary>
      </LocalizationProvider>
    </RollbarProvider>
  </GlobalContextWrapper>
)

serviceWorker.unregister()

import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteEntityHelperById,
  mergeEntitiesHelperById,
  setEntitiesHelperById
} from '../../wdql/helpers'
import { FavoriteEntity, PartialFavoriteEntity } from './entity'

const initialState: Array<FavoriteEntity> = []

export const favoriteSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    resetFavorites: () => [...initialState],
    replaceFavorites: (
      state,
      action: PayloadAction<Array<PartialFavoriteEntity>>
    ) => action.payload as Array<FavoriteEntity>,
    setFavorites: (
      state,
      action: PayloadAction<Array<PartialFavoriteEntity>>
    ) => {
      setEntitiesHelperById(state, action.payload)
    },
    mergeFavorites: (
      state,
      action: PayloadAction<Array<PartialFavoriteEntity>>
    ) => {
      mergeEntitiesHelperById(state, action.payload)
    },
    deleteFavorite: (
      state,
      action: PayloadAction<number | PartialFavoriteEntity>
    ) => deleteEntityHelperById(state, action.payload)
  }
})

export const {
  setFavorites,
  mergeFavorites,
  deleteFavorite,
  replaceFavorites,
  resetFavorites
} = favoriteSlice.actions

export default favoriteSlice.reducer

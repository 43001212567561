import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteEntityHelperById,
  mergeEntitiesHelperById,
  setEntitiesHelperById
} from '../../../wdql/helpers'
import {
  PartialPricebookCategoryEntity,
  PricebookCategoryEntity
} from './entity'

const initialState: Array<PricebookCategoryEntity> = []

export const pricebookcategorySlice = createSlice({
  name: 'pricebookcategories',
  initialState,
  reducers: {
    resetPricebookCategories: () => [...initialState],
    replacePricebookCategories: (
      state,
      action: PayloadAction<Array<PartialPricebookCategoryEntity>>
    ) => [...action.payload] as Array<PricebookCategoryEntity>,
    setPricebookCategories: (
      state,
      action: PayloadAction<Array<PartialPricebookCategoryEntity>>
    ) => {
      setEntitiesHelperById(state, action.payload)
    },
    mergePricebookCategories: (
      state,
      action: PayloadAction<Array<PartialPricebookCategoryEntity>>
    ) => {
      mergeEntitiesHelperById(state, action.payload)
    },
    patchPricebookCategory: (
      state,
      action: PayloadAction<PartialPricebookCategoryEntity>
    ) => {
      mergeEntitiesHelperById(state, [action.payload], false)
    },
    deletePricebookCategory: (
      state,
      action: PayloadAction<number | Partial<PricebookCategoryEntity>>
    ) => deleteEntityHelperById(state, action.payload)
  }
})

export const {
  setPricebookCategories,
  replacePricebookCategories,
  mergePricebookCategories,
  deletePricebookCategory,
  patchPricebookCategory,
  resetPricebookCategories
} = pricebookcategorySlice.actions

export default pricebookcategorySlice.reducer

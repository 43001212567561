export const browserWindowPopUp = (url: string) => {
  try {
    window.open(url, '_blank', 'noreferrer')
  } catch (_e) {
    console.log(
      'ERROR: Something Wrong with Pop Up - Usuaully From Pop-Up Blocker Browser Extension'
    )
  }
}

export default null

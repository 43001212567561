import { forwardRef, ReactElement, Ref } from 'react'
import { TransitionProps } from '@mui/material/transitions'
import { Slide } from '@mui/material'

const DialogTransitionDown = forwardRef(
  (
    props: TransitionProps & { children: ReactElement<any, any> },
    ref: Ref<unknown>
  ) => {
    const { children, ...rest } = props
    return (
      <Slide direction="down" ref={ref} {...rest}>
        {children}
      </Slide>
    )
  }
)

export default DialogTransitionDown

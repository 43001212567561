import { lazy } from 'react'
import { RouteComponentLoader } from '../utils/routes'

const RevenueTeamIncomeReportHome = RouteComponentLoader(
  lazy(() => import('../content/pages/Revenue/Reports/IncomeReport'))
)

const salesRoutes = [
  {
    path: 'reports/income',
    element: <RevenueTeamIncomeReportHome />
  }
]

export default salesRoutes

import { useContext, useMemo } from 'react'
import { AppContext } from '../contexts/AppContext'

// interface useFakeHookInterface {}
interface useLoadingResponse {
  appLoading: boolean
  setAppLoading: (loading: boolean) => void
}

const useLoading = (): useLoadingResponse => {
  const { loading, setLoading } = useContext(AppContext)

  return useMemo(
    () => ({ appLoading: loading, setAppLoading: setLoading }),
    [loading, setLoading]
  )
}

export default useLoading

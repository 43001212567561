import { AuthState } from './types'

interface SessionDataResponse {
  sessionId: string | null
  sessionName: string
}
export const sessionData = (): SessionDataResponse => {
  const sId = sessionStorage.getItem('sessionId')
  const defaultName = 'PHPSESSID'
  return {
    sessionId: sId !== '' ? (sId ?? null) : null,
    sessionName: sessionStorage.getItem('sessionName') || defaultName
  }
}

export const authorizationToken = (): string => {
  const tId = sessionStorage.getItem('tokenId')
  return tId ?? ''
}

export const initializeAuth = (): AuthState => {
  // Load Values from Browser Storage
  const storedEmail =
    sessionStorage.getItem('email') || localStorage.getItem('email') || null
  const storedToken =
    sessionStorage.getItem('tokenId') || localStorage.getItem('tokenId') || null
  const storedGoogleId =
    sessionStorage.getItem('googleId') ||
    localStorage.getItem('googleId') ||
    null
  const storedSessionId =
    sessionStorage.getItem('sessionId') ||
    localStorage.getItem('sessionId') ||
    null
  const storedSessionName =
    sessionStorage.getItem('sessionName') ||
    localStorage.getItem('sessionName') ||
    null
  const storedGhostId =
    sessionStorage.getItem('ghostId') || localStorage.getItem('ghostId') || null

  // Check if LocalStorage value is set, push it into the session
  if (storedEmail) {
    sessionStorage.setItem('email', storedEmail)
  }
  if (storedToken) {
    sessionStorage.setItem('tokenId', storedToken)
  }
  if (storedGoogleId) {
    sessionStorage.setItem('googleId', storedGoogleId)
  }
  if (storedSessionId) {
    sessionStorage.setItem('sessionId', storedSessionId)
  }
  if (storedSessionName) {
    sessionStorage.setItem('sessionName', storedSessionName)
  }
  if (storedGhostId) {
    sessionStorage.setItem('ghostId', storedGhostId)
  }

  return {
    email: storedEmail ?? null,
    tokenId: storedToken ?? null,
    googleId: storedGoogleId ?? null,
    sessionId: storedSessionId ?? null,
    sessionName: storedSessionName ?? null,
    ghostId: storedGhostId ?? null
  }
}

export const setAuthValue = (k: keyof AuthState, v: string | null) => {
  if (v === null) {
    localStorage.removeItem(k)
    sessionStorage.removeItem(k)
  } else {
    localStorage.setItem(k, v)
    sessionStorage.setItem(k, v)
  }
}

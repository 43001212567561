import { SliceModes } from '../../wdql/types'
import { storeDispatch } from '../../index'
import { PartialJobTicketEntity, JobTicketEntity } from './entity'
import {
  deleteJobTicket,
  mergeJobTickets,
  patchJobTicket,
  replaceJobTickets,
  setJobTickets
} from './reducer'

export const wdqlProcessJobTickets = (
  data: Array<JobTicketEntity> | Array<PartialJobTicketEntity>,
  mode: SliceModes
) => {
  switch (mode) {
    case 'replace':
      storeDispatch(setJobTickets(data))
      break
    case 'merge':
      storeDispatch(mergeJobTickets(data))
      break
    case 'patch':
      storeDispatch(patchJobTicket(data[0]))
      break
    case 'clear':
      storeDispatch(replaceJobTickets(data))
      break
    case 'delete':
      storeDispatch(deleteJobTicket(data[0]))
      break
  }
}

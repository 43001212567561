import { FC, useState } from 'react'
import { IconButton, styled, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import StarIcon from '@mui/icons-material/Star'
import { useLocation } from 'react-router-dom'
import TextPopupEditor from '../../modals/TextPopupEditor'
import useFavorites from '../../../store/slices/favorites/useFavorites'
import useProfile from '../../../store/slices/profile/useProfile'
import { PartialFavoriteEntity } from '../../../store/slices/favorites/entity'
import {
  deleteFavorite,
  saveFavorite
} from '../../../store/slices/favorites/api'
import ActionMenuPopup, { ActionMenuOption } from '../../popups/ActionMenuPopup'

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        width: ${theme.spacing(4)};
        height: ${theme.spacing(4)};
        border-radius: ${theme.general.borderRadiusLg};
`
)

const FavoriteToggleButton: FC = () => {
  const { t }: { t: any } = useTranslation()
  const { pathname, search } = useLocation()
  const { id: profileId } = useProfile()
  const fullPath = pathname + search
  const [open, setOpen] = useState<boolean>(false)

  const { findFavorite } = useFavorites()
  const fav = findFavorite(fullPath)
  const favId = fav?.id ?? null

  const currentLabel = fav?.name ?? ''

  const menuOptions: Array<ActionMenuOption> = [
    {
      label: 'Edit Favorite',
      onClick: () => {
        setOpen(true)
      },
      icon: <EditIcon fontSize="small" />
    },
    {
      label: 'Delete Favorite',
      onClick: () => {
        deleteFavorite(fav?.id)
      },
      icon: <DeleteIcon fontSize="small" />
    }
  ]

  const openEditor = () => {
    setOpen(true)
  }

  const closeEditor = () => {
    setOpen(false)
  }

  const saveFavoriteSubmit = (s: string) => {
    const newFav: PartialFavoriteEntity = Object.assign({ id: 0 }, fav ?? {}, {
      name: s,
      url: fullPath,
      userId: profileId ?? undefined
    })
    // console.log('SAVE FAVORITE:', newFav)
    saveFavorite(newFav)
    setOpen(false)
  }

  return (
    <>
      {!favId && (
        <Tooltip arrow title={t('Add to Favorites')}>
          <IconButtonWrapper
            color="primary"
            onClick={openEditor}
            sx={{ mr: 1 }}
          >
            <StarOutlineOutlinedIcon fontSize="small" />
          </IconButtonWrapper>
        </Tooltip>
      )}
      {favId && (
        <ActionMenuPopup menu={menuOptions}>
          <Tooltip arrow title={t('Modify Favorite')}>
            <IconButtonWrapper color="primary" sx={{ mr: 1 }}>
              <StarIcon fontSize="small" />
            </IconButtonWrapper>
          </Tooltip>
        </ActionMenuPopup>
      )}

      <TextPopupEditor
        open={open}
        onClose={closeEditor}
        closeWindow={false}
        cancelButton={false}
        label="Save Page as Favorite..."
        onSave={saveFavoriteSubmit}
        initialValue={currentLabel ?? ''}
        helperText={fullPath}
        placeholder={currentLabel ?? fullPath}
      />
    </>
  )
}

export default FavoriteToggleButton

import { useCallback, useMemo } from 'react'
import { resetStore, useAppDispatch, useAppSelector } from '../../../index'
import { clearAuth, setAuth } from '../authSlice'
import { setProfile } from '../../profile/profileSlice'
import useGlobalContext from '../../../../hooks/useGlobalContext'
import { AuthState } from '../../../../packages/WdAuthenticator/types'
import { LoginResponseType } from './types'

// interface useFakeHookInterface {}
interface useAuthResponse {
  auth: AuthState
  logoutState: () => void
  loginState: (r: LoginResponseType | null) => Promise<void>
}

const useAuthState = (): useAuthResponse => {
  const { setLoaded: setGlobalLoaded } = useGlobalContext()
  const auth = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()

  const logoutState = useCallback(() => {
    dispatch(clearAuth())
    resetStore()
    setGlobalLoaded(false)
  }, [dispatch, setGlobalLoaded])

  const loginState = useCallback(
    async (resp: LoginResponseType | null): Promise<void> => {
      // console.log('Process Login', resp)
      if (!resp) {
        return logoutState() // Ensure a consistent return
      }

      // console.log('Login Response:', resp)
      const ghostId = resp.user.ghostId ?? null
      dispatch(
        setAuth({
          email: ghostId ? undefined : resp.user.display.email,
          googleId: resp.google?.googleId ?? undefined,
          sessionId: resp.session.id,
          sessionName: resp.session.name,
          ghostId,
          tokenId: resp.token
        })
      )

      if (!ghostId) {
        dispatch(
          setProfile({
            email: resp.user.display.email,
            picture: resp.user.display.picture,
            name: resp.user.display.name
          })
        )
      }

      return Promise.resolve() // Ensure a consistent return for an async function
    },
    [dispatch, logoutState]
  )

  // console.trace('Rerender useAuth')
  return useMemo(
    () => ({
      auth,
      logoutState,
      loginState
    }),
    [auth, loginState, logoutState]
  )
}

export default useAuthState

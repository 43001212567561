import { FC, MouseEventHandler } from 'react'
import { IconButton, styled, Tooltip } from '@mui/material'
import BugReportIcon from '@mui/icons-material/BugReport'
import { useTranslation } from 'react-i18next'
import { browserWindowPopUp } from '../../../utils/browser'

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        width: ${theme.spacing(4)};
        height: ${theme.spacing(4)};
        border-radius: ${theme.general.borderRadiusLg};
`
)

interface BugReportButtonProps {}

const BUG_REPORT_URL =
  'https://threesixty.youtrack.cloud/form/d6aeb732-6cb0-4b29-9d35-71cea4fa30d4'

const BugReportButton: FC<BugReportButtonProps> = () => {
  const { t }: { t: any } = useTranslation()

  const openBugReport: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()
    browserWindowPopUp(BUG_REPORT_URL)
  }

  // https://threesixty.youtrack.cloud/form/d6aeb732-6cb0-4b29-9d35-71cea4fa30d4

  return (
    <Tooltip arrow title={t('Submit a Bug Report')}>
      <IconButtonWrapper color="primary" onClick={openBugReport} sx={{ mr: 1 }}>
        <BugReportIcon fontSize="small" />
      </IconButtonWrapper>
    </Tooltip>
  )
}

export default BugReportButton

// <script id="d6aeb732-6cb0-4b29-9d35-71cea4fa30d4" data-yt-url="https://threesixty.youtrack.cloud" src="https://threesixty.youtrack.cloud/static/simplified/form/form-entry.js" data-theme="light" data-lang="en"></script>

// <script id="d6aeb732-6cb0-4b29-9d35-71cea4fa30d4" data-yt-url="https://threesixty.youtrack.cloud" src="https://threesixty.youtrack.cloud/static/simplified/form/form-entry.js" data-theme="light" data-lang="en"></script>

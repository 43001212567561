import { SliceModes } from '../../wdql/types'
import { storeDispatch } from '../../index'
import { FavoriteEntity, PartialFavoriteEntity } from './entity'
import {
  deleteFavorite,
  mergeFavorites,
  replaceFavorites,
  setFavorites
} from './reducer'

export const wdqlProcessFavorites = (
  data: Array<FavoriteEntity> | Array<PartialFavoriteEntity>,
  mode: SliceModes
) => {
  switch (mode) {
    case 'replace':
      storeDispatch(setFavorites(data))
      break
    case 'merge':
      storeDispatch(mergeFavorites(data))
      break
    case 'clear':
      storeDispatch(replaceFavorites(data))
      break
    case 'delete':
      storeDispatch(deleteFavorite(data[0]))
      break
  }
}

import BusinessTwoToneIcon from '@mui/icons-material/BusinessTwoTone'
import SupportAgentTwoToneIcon from '@mui/icons-material/SupportAgentTwoTone'
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone'
import BadgeTwoToneIcon from '@mui/icons-material/BadgeTwoTone'
import Groups2TwoToneIcon from '@mui/icons-material/Groups2TwoTone'
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import StyleIcon from '@mui/icons-material/Style'
import AddIcon from '@mui/icons-material/Add'
import MapIcon from '@mui/icons-material/Map'
import { SidebarMenuType } from '../types'
import { triggerNewJobTicketPopup } from '../../../content/pages/JobTickets/New/NewJobTicketPopup'
import { ControlCenterBucketIcon } from '../icons'

const menuItems: Array<SidebarMenuType> = [
  {
    heading: 'General',
    items: [
      {
        name: 'Customers',
        icon: BusinessTwoToneIcon,
        link: '/app/customers',
        access: 'customers'
      },
      {
        name: 'Support',
        icon: SupportAgentTwoToneIcon,
        link: '/app/support',
        access: 'support'
      },
      {
        name: 'Job Tickets',
        icon: DocumentScannerIcon,
        access: 'job_tickets',
        items: [
          {
            name: 'Dashboard',
            icon: DocumentScannerIcon,
            link: '/app/job-tickets',
            access: 'job_tickets'
          },
          {
            name: 'New Ticket',
            icon: AddIcon,
            access: 'job_tickets',
            onClick: ({ trigger }) => triggerNewJobTicketPopup({ trigger })
          }
        ]
      },
      {
        name: 'Revenue',
        icon: PointOfSaleIcon,
        access: 'revenue-team',
        items: [
          {
            name: 'Income Report',
            icon: PointOfSaleIcon,
            link: '/app/sales/reports/income',
            access: 'reports.sales-income'
          }
        ]
      }
    ]
  },
  {
    heading: 'Management',
    access: 'management',
    items: [
      {
        name: 'User Management',
        icon: Groups2TwoToneIcon,
        access: { any: ['management.users', 'management.roles'] },
        items: [
          {
            name: 'People',
            icon: PeopleAltTwoToneIcon,
            link: '/app/management/users',
            access: 'management.users'
          },
          {
            name: 'Roles',
            icon: BadgeTwoToneIcon,
            link: '/app/management/roles',
            access: 'management.roles'
          }
        ]
      },
      {
        name: 'Pricebook',
        icon: AutoStoriesIcon,
        access: 'management.pricebook',
        items: [
          {
            name: 'Products & Services',
            icon: StyleIcon,
            link: '/app/management/pricebook/items',
            access: 'management.pricebook'
          }
        ]
      }
    ]
  },
  {
    heading: 'Administration',
    access: 'administration',
    items: [
      {
        name: 'Bucket Management',
        icon: ControlCenterBucketIcon,
        access: 'administration',
        items: [
          {
            name: 'Bucket-Project Map',
            icon: MapIcon,
            link: '/app/administration/bucket-map-manager',
            access: 'administration'
          }
        ]
      }
    ]
  }
]

export default menuItems

import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { RouteComponentLoader } from '../utils/routes'
import managementRoutes from './management'
import supportRoutes from './support'
import jobTicketRoutes from './job-tickets'
import salesRoutes from './sales'
import adminRoutes from './administration'

// Dashboards
const Home = RouteComponentLoader(
  lazy(() => import('../content/dashboards/Home'))
)
const Analytics = RouteComponentLoader(
  lazy(() => import('../content/dashboards/Analytics'))
)

const Customers = RouteComponentLoader(
  lazy(() => import('../content/pages/Customers'))
)

const CustomerDetails = RouteComponentLoader(
  lazy(() => import('../content/pages/Customers/Details'))
)

const UserProfile = RouteComponentLoader(
  lazy(() => import('../content/pages/UserProfile'))
)

const dashboardsRoutes = [
  {
    path: '',
    element: <Home />
  },
  {
    path: 'analytics',
    element: <Analytics />
  },
  {
    path: 'management',
    children: managementRoutes
  },
  {
    path: 'administration',
    children: adminRoutes
  },
  {
    path: 'customers/detail/:customerId',
    element: <Navigate to={'dashboard'} />
  },
  {
    path: 'customers/detail/:customerId/:tab',
    element: <CustomerDetails />
  },
  {
    path: 'customers',
    element: <Customers />
  },
  {
    path: 'user/profile/:userId/:tab',
    element: <UserProfile />
  },
  {
    path: 'user/profile/:userId',
    element: <UserProfile />
  },
  {
    path: 'support',
    children: supportRoutes
  },
  {
    path: 'job-tickets',
    children: jobTicketRoutes
  },
  {
    path: 'sales',
    children: salesRoutes
  }
]

export default dashboardsRoutes

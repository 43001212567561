import { patch, post, remove } from '../../../remote'
import { WdqlResponse } from '../../wdql/types'
import { SearchEntity } from './entity'
import { wdqlProcessSearches } from './dispatch'

export const saveSearch = (search: Partial<SearchEntity>) => {
  if (search.id && search.userId && search.term) {
    wdqlProcessSearches([search as SearchEntity], 'merge')
  }
  if (search.id) {
    patch<WdqlResponse<SearchEntity>>(
      '/api/v2/searches/' + search.id,
      search
    ).then((resp) => {
      if (resp?.data && resp.data.valid) {
        wdqlProcessSearches([resp?.data.payload], 'merge')
      }
    })
  } else {
    post<WdqlResponse<SearchEntity>>('/api/v2/searches', search).then(
      (resp) => {
        if (resp?.data && resp.data.valid) {
          wdqlProcessSearches([resp?.data.payload], 'merge')
        }
      }
    )
  }
}

export const deleteSearch = (id: number | SearchEntity | null | undefined) => {
  if (!id) {
    return
  }
  const search = typeof id === 'number' ? { id } : id
  wdqlProcessSearches([search as SearchEntity], 'delete')
  remove<WdqlResponse<number>>('/api/v2/searches/' + search.id).then((resp) => {
    if (resp?.data && resp.data.valid) {
      wdqlProcessSearches([{ id: resp.data.payload } as SearchEntity], 'delete')
    }
  })
}

import { lazy } from 'react'
import { RouteComponentLoader } from '../utils/routes'

const UsersHome = RouteComponentLoader(
  lazy(() => import('../content/pages/Users'))
)

const RolesHome = RouteComponentLoader(
  lazy(() => import('../content/pages/Roles'))
)

const RoleAccessHome = RouteComponentLoader(
  lazy(() => import('../content/pages/Roles/RoleAccess'))
)

const PricebookHome = RouteComponentLoader(
  lazy(() => import('../content/pages/Pricebook/Items/PricebookHome'))
)

const PricebookItemEditorHome = RouteComponentLoader(
  lazy(() => import('../content/pages/Pricebook/Items/Editor/index'))
)

const managementRoutes = [
  {
    path: 'users',
    element: <UsersHome />
  },
  {
    path: 'roles',
    element: <RolesHome />
  },
  { path: 'role/:roleKey/access', element: <RoleAccessHome /> },
  {
    path: 'pricebook/items',
    element: <PricebookHome />
  },
  { path: 'pricebook/item/:itemId/edit', element: <PricebookItemEditorHome /> }
]

export default managementRoutes

import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteEntityHelperById,
  mergeEntitiesHelperById,
  setEntitiesHelperById
} from '../../../wdql/helpers'
import { PartialPdCompanyEntity, PdCompanyEntity } from './entity'

const initialState: Array<PdCompanyEntity> = []

export const pdcompanySlice = createSlice({
  name: 'pdcompanies',
  initialState,
  reducers: {
    resetPdCompanies: () => [...initialState],
    replacePdCompanies: (
      state,
      action: PayloadAction<Array<PartialPdCompanyEntity>>
    ) => action.payload as Array<PdCompanyEntity>,
    setPdCompanies: (
      state,
      action: PayloadAction<Array<PartialPdCompanyEntity>>
    ) => {
      setEntitiesHelperById(state, action.payload)
    },
    mergePdCompanies: (
      state,
      action: PayloadAction<Array<PartialPdCompanyEntity>>
    ) => {
      mergeEntitiesHelperById(state, action.payload)
    },
    patchPdCompany: (state, action: PayloadAction<PartialPdCompanyEntity>) => {
      mergeEntitiesHelperById(state, [action.payload], false)
    },
    deletePdCompany: (
      state,
      action: PayloadAction<number | Partial<PdCompanyEntity>>
    ) => deleteEntityHelperById(state, action.payload)
  }
})

export const {
  setPdCompanies,
  replacePdCompanies,
  mergePdCompanies,
  deletePdCompany,
  patchPdCompany,
  resetPdCompanies
} = pdcompanySlice.actions

export default pdcompanySlice.reducer

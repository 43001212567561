import { CircularProgress, CircularProgressProps } from '@mui/material'
import { FC } from 'react'

interface SpinnerIconProps {
  visible?: boolean
  size?: number
  color?: CircularProgressProps['color']
  sx?: CircularProgressProps['sx']
}

const SpinnerIcon: FC<SpinnerIconProps> = ({
  visible = true,
  size = 16,
  color = 'primary',
  sx
}: SpinnerIconProps) => {
  if (!visible) {
    return null
  }
  return <CircularProgress color={color} size={size} sx={sx} />
}

export default SpinnerIcon

import Reactotron from 'reactotron-react-js'
// import { reactotronRedux } from 'reactotron-redux'
//
// Reactotron.configure({}) // we can use plugins here -- more on this later
//   .connect() // let's connect!
//
// if (false && process.env.NODE_ENV !== 'production') {
//   // Reactotron.configure({ name: 'Control Center' })
//   //   .use(reactotronRedux())
//   //   .connect()
// }
const enableReactoTron = false

window.tron = (
  value: any,
  name: string | undefined = 'DEBUG', // Hide error with _name (until working again)
  preview: any | null | undefined = null
) => {
  if (process.env.NODE_ENV !== 'production') {
    if (!preview && value) {
      if (typeof value === 'string') {
        preview = value
      } else if (Array.isArray(value)) {
        preview = String(value)
      } else {
        preview = typeof value
      }
    }
    if (enableReactoTron) {
      Reactotron.display({
        name,
        preview,
        value
      })
    }
  }
}

export default Reactotron

import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteEntityHelperById,
  mergeEntitiesHelperById,
  setEntitiesHelperById
} from '../../../wdql/helpers'
import { PartialPdDealEntity, PdDealEntity } from './entity'

const initialState: Array<PdDealEntity> = []

export const pddealSlice = createSlice({
  name: 'pddeals',
  initialState,
  reducers: {
    resetPdDeals: () => [...initialState],
    replacePdDeals: (
      state,
      action: PayloadAction<Array<PartialPdDealEntity>>
    ) => action.payload as Array<PdDealEntity>,
    setPdDeals: (state, action: PayloadAction<Array<PartialPdDealEntity>>) => {
      setEntitiesHelperById(state, action.payload)
    },
    mergePdDeals: (
      state,
      action: PayloadAction<Array<PartialPdDealEntity>>
    ) => {
      mergeEntitiesHelperById(state, action.payload)
    },
    patchPdDeal: (state, action: PayloadAction<PartialPdDealEntity>) => {
      mergeEntitiesHelperById(state, [action.payload], false)
    },
    deletePdDeal: (
      state,
      action: PayloadAction<number | Partial<PdDealEntity>>
    ) => deleteEntityHelperById(state, action.payload)
  }
})

export const {
  setPdDeals,
  replacePdDeals,
  mergePdDeals,
  deletePdDeal,
  patchPdDeal,
  resetPdDeals
} = pddealSlice.actions

export default pddealSlice.reducer

import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteEntityHelperById,
  mergeEntitiesHelperById,
  setEntitiesHelperById
} from '../../wdql/helpers'
import { PartialUserEntity, UserEntity } from './entity'

const initialState: Array<UserEntity> = []

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUsers: () => [...initialState],
    replaceUsers: (state, action: PayloadAction<Array<PartialUserEntity>>) =>
      action.payload as Array<UserEntity>,
    setUsers: (state, action: PayloadAction<Array<PartialUserEntity>>) => {
      setEntitiesHelperById(state, action.payload)
    },
    patchUser: (state, action: PayloadAction<PartialUserEntity>) => {
      mergeEntitiesHelperById(state, [action.payload], false)
    },
    mergeUsers: (state, action: PayloadAction<Array<PartialUserEntity>>) => {
      mergeEntitiesHelperById(state, action.payload)
    },
    deleteUser: (state, action: PayloadAction<number | Partial<UserEntity>>) =>
      deleteEntityHelperById(state, action.payload)
  }
})

export const {
  setUsers,
  replaceUsers,
  mergeUsers,
  deleteUser,
  patchUser,
  resetUsers
} = userSlice.actions

export default userSlice.reducer

import { useNavigate } from 'react-router-dom'
import useProfile from '../store/slices/profile/useProfile'
import { AccessRequirement } from '../models/access'

// interface useFakeHookInterface {}

/** Returns a boolean true or false if the current user passes the permission check passed into it */
const useAccess = (
  access: AccessRequirement,
  redirect: boolean = false
): boolean => {
  const navigate = useNavigate()
  const { check } = useProfile()
  const success = check.required(access)
  if (!success && redirect) {
    navigate('/')
  }
  return success
}

export default useAccess

import { FC, Fragment } from 'react'
import { usePopupStackContext } from '../../contexts/PopupStackContext'

interface PopupStackRenderProps {}

const PopupStackRender: FC<PopupStackRenderProps> = (
  _props: PopupStackRenderProps
) => {
  const { stack } = usePopupStackContext()
  return (
    <>
      {stack.map((s) => (
        <Fragment key={s.code}>{s.component}</Fragment>
      ))}
    </>
  )
}

export default PopupStackRender

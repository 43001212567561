import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteEntityHelperById,
  mergeEntitiesHelperById,
  setEntitiesHelperById
} from '../../../wdql/helpers'
import { PartialPdContactEntity, PdContactEntity } from './entity'

const initialState: Array<PdContactEntity> = []

export const pdcontactSlice = createSlice({
  name: 'pdcontacts',
  initialState,
  reducers: {
    resetPdContacts: () => [...initialState],
    replacePdContacts: (
      state,
      action: PayloadAction<Array<PartialPdContactEntity>>
    ) => action.payload as Array<PdContactEntity>,
    setPdContacts: (
      state,
      action: PayloadAction<Array<PartialPdContactEntity>>
    ) => {
      setEntitiesHelperById(state, action.payload)
    },
    mergePdContacts: (
      state,
      action: PayloadAction<Array<PartialPdContactEntity>>
    ) => {
      mergeEntitiesHelperById(state, action.payload)
    },
    patchPdContact: (state, action: PayloadAction<PartialPdContactEntity>) => {
      mergeEntitiesHelperById(state, [action.payload], false)
    },
    deletePdContact: (
      state,
      action: PayloadAction<number | Partial<PdContactEntity>>
    ) => deleteEntityHelperById(state, action.payload)
  }
})

export const {
  setPdContacts,
  replacePdContacts,
  mergePdContacts,
  deletePdContact,
  patchPdContact,
  resetPdContacts
} = pdcontactSlice.actions

export default pdcontactSlice.reducer

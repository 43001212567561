import { SidebarMenuType } from '../types'

const menuItems: Array<SidebarMenuType> = [
  {
    heading: 'Favorites',
    items: []
  }
]

export default menuItems

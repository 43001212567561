import { format, formatDistance, formatDistanceToNow, parseISO } from 'date-fns'
import { toZonedTime, fromZonedTime } from 'date-fns-tz'
import { SERVER_TIMEZONE } from '../config'
import { DATEFNS_DATE_FORMAT, DATEFNS_DATETIME_FORMAT } from '../constants'

type NewDateTimeUnit =
  | 'second'
  | 'seconds'
  | 'minute'
  | 'minutes'
  | 'hour'
  | 'hours'
  | 'day'
  | 'days'
  | 'week'
  | 'weeks'
  | 'month'
  | 'months'
  | 'year'
  | 'years'
type NewDateModifier = `${'+' | '-'}${number} ${NewDateTimeUnit}`

export const newDate = (modifier?: NewDateModifier): Date => {
  const d = new Date()

  if (!modifier) return d

  const regex =
    /([+-]?\d+)\s*(seconds?|minutes?|hours?|days?|weeks?|months?|years?)/i
  const match = regex.exec(modifier)

  if (!match) return d

  const value = parseInt(match[1], 10)
  const unit = match[2].toLowerCase()

  const unitsMap: { [key: string]: (date: Date, value: number) => void } = {
    second: (date, value) => date.setSeconds(date.getSeconds() + value),
    seconds: (date, value) => date.setSeconds(date.getSeconds() + value),
    minute: (date, value) => date.setMinutes(date.getMinutes() + value),
    minutes: (date, value) => date.setMinutes(date.getMinutes() + value),
    hour: (date, value) => date.setHours(date.getHours() + value),
    hours: (date, value) => date.setHours(date.getHours() + value),
    day: (date, value) => date.setDate(date.getDate() + value),
    days: (date, value) => date.setDate(date.getDate() + value),
    week: (date, value) => date.setDate(date.getDate() + value * 7),
    weeks: (date, value) => date.setDate(date.getDate() + value * 7),
    month: (date, value) => date.setMonth(date.getMonth() + value),
    months: (date, value) => date.setMonth(date.getMonth() + value),
    year: (date, value) => date.setFullYear(date.getFullYear() + value),
    years: (date, value) => date.setFullYear(date.getFullYear() + value)
  }

  const manipulateDate = unitsMap[unit]
  if (manipulateDate) manipulateDate(d, value)

  return d
}

export const isDate = (d?: string | null): boolean => {
  if (!d) {
    return false
  }
  const pattern = /^\d{4}-\d{2}-\d{2}$/
  return pattern.test(d) && d !== '0000-00-00'
}

export const isDatetime = (d?: string | null): boolean => {
  if (!d) {
    return false
  }
  const pattern = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/
  return pattern.test(d) && d !== '0000-00-00 00:00:00'
}

export const isDateOrDatetime = (d?: string | null): boolean =>
  isDate(d) || isDatetime(d)

export const strToDatetime = (dt: string | Date): string => {
  if (dt instanceof Date) {
    return format(dt, DATEFNS_DATETIME_FORMAT)
  }
  if (isDatetime(dt)) {
    return dt
  } else if (isDate(dt)) {
    return dt + ' 00:00:00'
  }
  return format(new Date(dt), DATEFNS_DATETIME_FORMAT)
}

export const toDateObject = (d: string | Date): Date => {
  if (typeof d === 'string') {
    return toLocalDate(strToDatetime(d))
  }
  return d
}

export const toNow = (formatAs: 'datetime' | 'date' = 'datetime') =>
  format(
    new Date(),
    formatAs === 'date' ? DATEFNS_DATE_FORMAT : DATEFNS_DATETIME_FORMAT
  )

export const toDateString = (
  d: Date,
  formatAs: 'datetime' | 'date' = 'datetime'
): string =>
  format(d, formatAs === 'date' ? DATEFNS_DATE_FORMAT : DATEFNS_DATETIME_FORMAT)

export const toLocalDate = (
  datetimeStr: string,
  timezone: string | null = null
): Date => {
  const serverTime = fromZonedTime(
    strToDatetime(datetimeStr),
    SERVER_TIMEZONE ?? 'America/New_York'
  )
  const utcTime = parseISO(serverTime.toISOString())
  const localTimezone = !!timezone
    ? timezone
    : Intl.DateTimeFormat().resolvedOptions().timeZone
  return toZonedTime(utcTime, localTimezone)
}

export const toUTCDate = (datetimeStr: string) =>
  toLocalDate(datetimeStr, 'Etc/UTC')

export const toServerDate = (d: Date) =>
  toLocalDate(strToDatetime(d), SERVER_TIMEZONE)

// export const toStringUTC = (d: string | Date): string => {
//   const dtString = toDatetimeString(d)
//   const [year, month, day, hour, minute, second] = dtString.split(/[- :]/g)
//   const timestamp = Date.UTC(
//     Number(year),
//     Number(month) - 1,
//     Number(day),
//     Number(hour),
//     Number(minute),
//     Number(second)
//   )
//   const utcDate = new Date(timestamp)
//
//   return format(utcDate, DATEFNS_DATETIME_FORMAT)
// }

export const asDateText = (d?: Date | null) => {
  if (!d) {
    return null
  }
  return format(d, 'LLL do, yyyy')
}

export const asRelativeNow = (
  date?: Date | null | string,
  suffix: boolean = true,
  invalidText: string = 'sometime ago'
) => {
  if (!date) {
    return invalidText
  }
  const fromDate = typeof date === 'string' ? toDateObject(date) : date
  return formatDistanceToNow(fromDate, {
    addSuffix: suffix
  })
}

export const fuzzyDateDiff = (
  earlyDate?: Date | null,
  laterDate?: Date | null
) => {
  if (!earlyDate && !laterDate) {
    return null
  }
  const ld = !laterDate ? new Date() : laterDate
  const ed = !earlyDate ? new Date() : earlyDate
  return formatDistance(ld, ed)
}

export const supTagDateSuffix = (dateString: string): string => {
  const dayRegex = /(\d+)(st|nd|rd|th)/
  const matches = dateString.match(dayRegex)

  if (!matches) {
    return dateString
  }

  const day = matches[1]
  const suffix = matches[2]

  const supTag = `<sup>${suffix}</sup>`
  const formattedDay = day.replace(suffix, supTag)

  return dateString.replace(day, formattedDay)
}

export default null

import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useState
} from 'react'
import { getUniqueId } from '../utils/randomId'

// Define a common interface for PopupStackInstanceProps
interface PopupStackInstanceProps {
  popupCode: string
  popupOnClose: (code: string) => void
}

export type PopupStackTrigger = <TProps extends PopupStackInstanceProps>(
  element: React.ComponentType<TProps>,
  data: Omit<TProps, 'popupCode' | 'popupOnClose'> // Omit popupCode and popupOnClose
) => string

// Define the context interface
interface PopupStackContextInterface {
  stack: PopupStackInstance[]
  trigger: PopupStackTrigger
  close: (code: string) => void
}

// Define the type for PopupStackInstance
type PopupStackInstance = {
  code: string
  component: ReactElement
}

const PopupStackContext = createContext<PopupStackContextInterface | undefined>(
  undefined
)

export const usePopupStackContext = () => {
  const context = useContext(PopupStackContext)
  if (!context) {
    throw new Error(
      'usePopupStackContext must be used within a wrapped PopupStackContextProvider component'
    )
  }
  return context
}

type PopupStackProviderProps = {
  children: ReactNode
}

export const PopupStackProvider = ({ children }: PopupStackProviderProps) => {
  const [stack, setStack] = useState<PopupStackInstance[]>([])

  const trigger: PopupStackTrigger = useCallback(
    <TProps extends PopupStackInstanceProps>(
      element: React.ComponentType<TProps>,
      data: Omit<TProps, 'popupCode' | 'popupOnClose'> // Omit popupCode and popupOnClose
    ): string => {
      const onClose = (code: string) => {
        setStack((prevStack) => prevStack.filter((s) => s.code !== code))
      }

      const code = getUniqueId()

      const mergedProps = {
        popupCode: code,
        popupOnClose: onClose,
        ...(data || {}) // Spread the extra props if provided
      } as TProps

      const newPopup: PopupStackInstance = {
        code,
        component: React.createElement(element, mergedProps)
      }

      setStack((prevStack) => [...prevStack, newPopup])
      return code
    },
    []
  )

  const close = useCallback((code: string) => {
    setStack((prevStack) => prevStack.filter((s) => s.code !== code))
  }, [])

  return (
    <PopupStackContext.Provider value={{ stack, trigger, close }}>
      {children}
    </PopupStackContext.Provider>
  )
}

import { getStoreInstance, RootState } from '../../index'
import { deleteSearch, saveSearch } from './api'
import { SearchEntity } from './entity'

export const saveRecentSearch = (term: string) => {
  if (term.trim() !== '') {
    const sItem: Partial<SearchEntity> = {
      term,
      type: 'RECENT'
    }
    saveSearch(sItem)
  }
}

export const saveSavedSearch = (id: number) => {
  const searches: RootState['entities']['searches'] =
    getStoreInstance()?.getState().entities.searches ?? []
  const item = searches.find((s) => s.id === id)
  if (item) {
    saveSearch({ ...item, type: 'SAVED' })
  }
}

export const deleteSearchItem = (id: number) => {
  deleteSearch(id)
}

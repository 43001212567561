import {
  Box,
  CardHeader,
  Card,
  List,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Divider,
  InputAdornment,
  styled,
  TextField,
  ListItemButton
} from '@mui/material'

import { useTranslation } from 'react-i18next'
import {
  ChangeEvent,
  FC,
  Fragment,
  useCallback,
  useMemo,
  useState
} from 'react'
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone'
import { byString, byValue } from 'sort-es'
import { formatDistance } from 'date-fns'
import SimpleContentPopup, {
  SimpleContentPopupTrigger
} from '../../modals/SimpleContentPopup'
import useUsers from '../../../store/slices/users/useUsers'
import useProfile from '../../../store/slices/profile/useProfile'
import useGhost from '../../../store/slices/auth/useGhost'
import { prefixApiUrl } from '../../../utils/tools'

interface GhostLoginPopupProps {
  children: SimpleContentPopupTrigger
  onClose?: () => void
}

const SearchInputWrapper = styled(TextField)(
  ({ theme }) => `
      .MuiInputBase-input {
          font-size: ${theme.typography.pxToRem(17)};
          padding:9px
      }
`
)

const GhostLoginPopup: FC<GhostLoginPopupProps> = (
  props: GhostLoginPopupProps
) => {
  const { children: trigger, onClose } = props
  const { id: myId, check } = useProfile()
  const [isOpen, setOpen] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const { t } = useTranslation()
  const { users } = useUsers()
  const { ghostLogin } = useGhost()

  const open = useCallback(() => {
    setOpen(true)
  }, [])
  const close = useCallback(() => {
    setSearch('')
    setOpen(false)
    if (onClose) {
      onClose()
    }
  }, [onClose])

  const filteredUsers = useMemo(
    () =>
      users
        .filter(
          (u) =>
            u.id >= 100000 &&
            u.status === 'ACTIVE' &&
            (search.trim() === '' ||
              u.username.toLowerCase().includes(search.trim().toLowerCase()) ||
              u.fullName.toLowerCase().includes(search.trim().toLowerCase()))
        )
        .sort(byValue((i) => i.fullName, byString())),
    [users, search]
  )

  const changeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }, [])

  const clickGhost = useCallback(
    (id: number) => {
      close()
      ghostLogin(id)
    },
    [close, ghostLogin]
  )

  if (!check.access('ghost.login')) {
    return null
  }

  return (
    <>
      <SimpleContentPopup
        onOpen={open}
        onClose={close}
        open={isOpen}
        trigger={trigger}
        title="Ghost Login..."
        description="Select the user you wish to login as. This will require you to logout if you wish to go back to your primary user."
      >
        <Card variant="outlined">
          <CardHeader
            sx={{
              p: 0.5
            }}
            disableTypography
            title={
              <SearchInputWrapper
                autoFocus
                value={search}
                onChange={changeSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  )
                }}
                placeholder={t('Search users...') ?? ''}
                fullWidth
              />
            }
          />
          <Divider />
          <List
            sx={{
              py: 0
            }}
          >
            {filteredUsers.map((u) => {
              const pic = prefixApiUrl(u.picture) ?? ''
              return (
                <Fragment key={u.id}>
                  <ListItemButton
                    sx={{
                      px: 2,
                      py: 1.95
                    }}
                    disabled={u.id === myId}
                    onClick={() => clickGhost(u.id)}
                  >
                    <ListItemAvatar
                      sx={{
                        mr: 2,
                        minWidth: 0
                      }}
                    >
                      <Avatar src={pic} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography color="text.primary" variant="h5">
                          {t(u.fullName)}
                        </Typography>
                      }
                      secondary={
                        <Typography variant="subtitle2" noWrap>
                          {u.jobTitle}
                        </Typography>
                      }
                    />
                    <Box display="flex" alignItems="right">
                      <ListItemText
                        secondary={
                          <Typography variant="subtitle2" noWrap>
                            {!!u.lastLoginAt
                              ? formatDistance(
                                  u.lastLoginAt.localDate(),
                                  new Date(),
                                  { addSuffix: true }
                                )
                              : 'Never'}
                          </Typography>
                        }
                      />
                    </Box>
                  </ListItemButton>
                  <Divider />
                </Fragment>
              )
            })}
          </List>
        </Card>
      </SimpleContentPopup>
    </>
  )
}

export default GhostLoginPopup

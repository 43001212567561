import { useCallback, useEffect, useMemo, useState } from 'react'
import useIsMounted from 'ismounted'
import { byString, byValue } from 'sort-es'
import { useAppSelector } from '../../index'
import { get } from '../../../remote'
import { WdqlResponse } from '../../wdql/types'
import useDeepEqualMemo from '../../../hooks/useDeepEqualMemo'
import useProfile from '../profile/useProfile'
import { SearchEntity } from './entity'

const findSearchFunction =
  (
    userId: number | null,
    searchesList: Array<SearchEntity>
  ): ((term: string) => SearchEntity | null) =>
  (term: string): SearchEntity | null =>
    searchesList?.find((f) => f.term === term && f.userId === userId) ?? null

const fetchSearches = async (onlyMine: boolean) =>
  get<WdqlResponse<Array<SearchEntity>>>(
    onlyMine ? '/api/v2/searches/list/mine' : '/api/v2/searches/list'
  )

interface useSearchesResponse {
  searches: Array<SearchEntity>
  loadMine: () => Promise<void>
  loading: boolean
  findSearch: (term: string) => SearchEntity | null
  recent: Array<SearchEntity>
  saved: Array<SearchEntity>
}

const useSearches = (
  initLoad: boolean = true,
  onlyMine: boolean = true
): useSearchesResponse => {
  const isMounted = useIsMounted()
  const [loading, setLoading] = useState<boolean>(false)
  const { id: profileId } = useProfile()
  const searches = useAppSelector((state) => state.entities.searches)
  const my_favs = useMemo(
    () =>
      searches
        .filter((f) => f.userId === profileId)
        .sort(byValue((i) => i.createdAt ?? '', byString({ desc: true }))),
    [searches, profileId]
  )
  const mySearches = useDeepEqualMemo(my_favs)

  const savedSearches = useMemo(
    () =>
      (mySearches?.filter((s) => s.type === 'SAVED') ?? []).sort(
        byValue((i) => i.createdAt ?? '', byString({ desc: true }))
      ),
    [mySearches]
  )

  const recentSearches = useMemo(
    () =>
      (mySearches?.filter((s) => s.type === 'RECENT') ?? [])
        .sort(byValue((i) => i.createdAt ?? '', byString({ desc: true })))
        .slice(0, savedSearches.length === 0 ? 10 : 5),
    [mySearches, savedSearches.length]
  )

  const findSearch = useCallback(
    (term: string) => findSearchFunction(profileId, mySearches ?? [])(term),
    [mySearches, profileId]
  )

  const loadMySearches = useCallback(async () => {
    if (isMounted.current) {
      setLoading(true)
      fetchSearches(onlyMine).then(() => {
        if (isMounted.current) {
          setLoading(false)
        }
      })
    }
  }, [isMounted, onlyMine])

  useEffect(() => {
    if (initLoad && profileId) {
      loadMySearches().then()
    }
  }, [initLoad, loadMySearches, profileId])

  // const d = {
  //   initLoad: initLoad,
  //   onlyMine: onlyMine,
  //   isMounted: isMounted,
  //   loading: loading,
  //   setLoading: setLoading,
  //   profileId: profileId,
  //   searches: searches,
  //   mySearches: mySearches,
  //   searchMenu: searchMenu,
  //   loadMySearches: loadMySearches
  // }
  // useTraceUpdate(d, 'useSearches')

  return useMemo(
    () => ({
      searches: (onlyMine ? mySearches : searches) ?? [],
      loadMine: loadMySearches,
      loading,
      findSearch,
      saved: savedSearches,
      recent: recentSearches
    }),
    [
      onlyMine,
      mySearches,
      searches,
      loadMySearches,
      loading,
      findSearch,
      savedSearches,
      recentSearches
    ]
  )
}

export default useSearches

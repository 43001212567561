import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteEntityHelperById,
  mergeEntitiesHelperById,
  setEntitiesHelperById
} from '../../wdql/helpers'
import { PartialFollowEntity, FollowEntity } from './entity'

const initialState: Array<FollowEntity> = []

export const followSlice = createSlice({
  name: 'follows',
  initialState,
  reducers: {
    resetFollows: () => [...initialState],
    replaceFollows: (
      state,
      action: PayloadAction<Array<PartialFollowEntity>>
    ) => action.payload as Array<FollowEntity>,
    setFollows: (state, action: PayloadAction<Array<PartialFollowEntity>>) => {
      setEntitiesHelperById(state, action.payload)
    },
    mergeFollows: (
      state,
      action: PayloadAction<Array<PartialFollowEntity>>
    ) => {
      mergeEntitiesHelperById(state, action.payload)
    },
    patchFollow: (state, action: PayloadAction<PartialFollowEntity>) => {
      mergeEntitiesHelperById(state, [action.payload], false)
    },
    deleteFollow: (
      state,
      action: PayloadAction<number | Partial<FollowEntity>>
    ) => deleteEntityHelperById(state, action.payload)
  }
})

export const {
  setFollows,
  replaceFollows,
  mergeFollows,
  deleteFollow,
  patchFollow,
  resetFollows
} = followSlice.actions

export default followSlice.reducer

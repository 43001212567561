import { ReactElement, ReactNode } from 'react'
import BallotIcon from '@mui/icons-material/Ballot'
import HandshakeIcon from '@mui/icons-material/Handshake'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import PreviewIcon from '@mui/icons-material/Preview'
import RateReviewIcon from '@mui/icons-material/RateReview'
import DoDisturbIcon from '@mui/icons-material/DoDisturb'
import DrawIcon from '@mui/icons-material/Draw'
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import { Card, SvgIconProps } from '@mui/material'
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import CancelIcon from '@mui/icons-material/Cancel'
import {
  JobTicketState,
  JobTicketStatus,
  JobTicketType
} from '../../../../store/slices/job-tickets/entity'
import NoContentComplex from '../../../../components/helpers/NoContentComplex'
import SpinnerIcon from '../../../../components/icons/SpinnerIcon'

export const getJobTicketTypeIcon = (
  type: JobTicketType
): { icon: ReactElement<SvgIconProps>; label: string } => {
  switch (type) {
    case 'service':
      return { label: 'Service Ticket', icon: <BallotIcon /> }
    case 'contract':
      return { label: 'Contract Ticket', icon: <HandshakeIcon /> }
    case 'change':
      return { label: 'Change Ticket', icon: <SettingsSuggestIcon /> }
    default:
      return { label: 'Unknown Ticket', icon: <HelpOutlineIcon /> } // Default case
  }
}

export const getStateIcon = (
  state: JobTicketState
): { icon: ReactNode; label: string } => {
  switch (state) {
    case 'draft':
      return { label: 'Draft', icon: <ModeEditIcon /> }
    case 'submitted':
      return { label: 'Submitted', icon: <PreviewIcon /> }
    case 'revision':
      return { label: 'Revision', icon: <RateReviewIcon /> }
    case 'denied':
      return { label: 'Denied', icon: <DoDisturbIcon /> }
  }
  return { label: state.ucWords(), icon: null }
}

export const getStatusDetails = (
  status: JobTicketStatus
): { icon: ReactNode; label: string } => {
  switch (status) {
    case 'pending':
      return { label: 'Pending', icon: <DrawIcon /> }
    case 'pre_process':
      return { label: 'Pre-Processing', icon: <AccountTreeOutlinedIcon /> }
    case 'in_process':
      return { label: 'Active', icon: <PendingActionsIcon /> }
    case 'post_process':
      return { label: 'Post-Processing', icon: <AccountTreeIcon /> }
    case 'closed':
      return { label: 'Closed', icon: <AssignmentTurnedInIcon /> }
    case 'cancelled':
      return { label: 'Cancelled', icon: <CancelIcon /> }
  }
  return { label: status.ucWords(), icon: null }
}

export const NoTickets = ({ loading = false }: { loading?: boolean }) => (
  <Card sx={{ minHeight: 200 }}>
    <NoContentComplex
      heading={<>No Matching Job Tickets</>}
      icon={loading ? <SpinnerIcon size={40} /> : <DocumentScannerIcon />}
    />
  </Card>
)

import { FC, ReactNode, useState, useContext } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import clsx from 'clsx'

import {
  Button,
  Tooltip,
  Badge,
  Collapse,
  ListItem,
  TooltipProps,
  tooltipClasses,
  styled,
  Box
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone'
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone'
import { SidebarContext } from '../../../../contexts/SidebarContext'
import { usePopupStackContext } from '../../../../contexts/PopupStackContext'
import { SidebarMenuItemType } from '../../../../router/menu/types'

interface SidebarMenuItemProps {
  children?: ReactNode
  link?: string
  icon?: any
  badge?: string
  badgeTooltip?: string
  open?: boolean
  active?: boolean
  name: string
  onClick?: SidebarMenuItemType['onClick']
}

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => {
  const { children, ...rest } = props
  return (
    <Tooltip {...rest} classes={{ popper: className }}>
      {children}
    </Tooltip>
  )
})(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}))

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  children,
  link,
  icon: Icon,
  badge,
  badgeTooltip,
  open: openParent,
  name,
  onClick
}) => {
  const [menuToggle, setMenuToggle] = useState<boolean>(openParent ?? false)
  const { t } = useTranslation()
  const { closeSidebar } = useContext(SidebarContext)
  const { trigger } = usePopupStackContext()

  const toggleMenu = (): void => {
    setMenuToggle((open) => !open)
  }

  const onClickAction = onClick
    ? () => {
        onClick({ trigger })
        closeSidebar()
      }
    : undefined

  const renderBadge = () => {
    if (badgeTooltip) {
      return (
        <TooltipWrapper title={badgeTooltip} arrow placement="right">
          {badge === '' ? (
            <Badge color="primary" variant="dot" />
          ) : (
            <Badge badgeContent={badge} />
          )}
        </TooltipWrapper>
      )
    }
    return badge === '' ? (
      <Badge color="primary" variant="dot" />
    ) : (
      <Badge badgeContent={badge} />
    )
  }

  if (children) {
    return (
      <ListItem component="div" className="Mui-children" key={name}>
        <Button
          className={clsx({ active: menuToggle })}
          startIcon={Icon && <Icon />}
          endIcon={
            menuToggle ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />
          }
          onClick={toggleMenu}
        >
          {renderBadge()}
          {t(name)}
        </Button>
        <Collapse in={menuToggle}>{children}</Collapse>
      </ListItem>
    )
  }

  return (
    <ListItem component="div" key={name}>
      <Button
        disableRipple
        component={onClickAction ? Box : RouterLink}
        onClick={onClickAction || closeSidebar}
        to={onClickAction ? undefined : (link ?? '/')}
        startIcon={Icon && <Icon />}
      >
        {t(name)}
        {renderBadge()}
      </Button>
    </ListItem>
  )
}

export default SidebarMenuItem

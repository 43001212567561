import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBucket,
  faEraser,
  faReceipt
} from '@fortawesome/free-solid-svg-icons'
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core'
import { CSSProperties } from 'react'

export interface ControlCenterIconInterface {
  size?: SizeProp | undefined
  style?: CSSProperties
}

const ControlCenterIcon = ({
  icon,
  size,
  style
}: ControlCenterIconInterface & {
  icon: IconProp
}) => (
  <FontAwesomeIcon icon={icon} size={size} style={{ marginTop: 0, ...style }} />
)

export const ControlCenterBucketIcon = (props: ControlCenterIconInterface) => (
  <ControlCenterIcon icon={faBucket} {...props} />
)

export const ControlCenterInvoiceIcon = (props: ControlCenterIconInterface) => (
  <ControlCenterIcon icon={faReceipt} {...props} />
)

export const ControlCenterEraseIcon = (props: ControlCenterIconInterface) => (
  <ControlCenterIcon icon={faEraser} {...props} />
)

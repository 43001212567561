import {
  Box,
  IconButton,
  Badge,
  Tooltip,
  TooltipProps,
  alpha,
  tooltipClasses,
  styled,
  useTheme
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import PowerSettingsNewTwoToneIcon from '@mui/icons-material/PowerSettingsNewTwoTone'
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone'
import AutoAwesomeSharpIcon from '@mui/icons-material/AutoAwesomeSharp'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { SidebarContext } from '../../../../contexts/SidebarContext'
import useFavorites from '../../../../store/slices/favorites/useFavorites'
import useWdAuthenticator from '../../../../packages/WdAuthenticator/useWdAuthenticator'

const LightTooltip = styled(({ className, ...props }: TooltipProps) => {
  const { children, ...rest } = props
  return (
    <Tooltip {...rest} classes={{ popper: className }}>
      {children}
    </Tooltip>
  )
})(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    boxShadow: theme.shadows[24],
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(12)
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}))

function SidebarFooter() {
  const { showFavorites, toggleFavorites } = useContext(SidebarContext)
  const { favorites } = useFavorites()
  const { t }: { t: any } = useTranslation()
  const theme = useTheme()
  const { logout } = useWdAuthenticator()
  const navigate = useNavigate()
  const favoriteCount = favorites?.length ?? 0

  const handleLogout = async (): Promise<void> => {
    try {
      await logout()
      navigate('/')
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Box
      sx={{
        height: 60
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {showFavorites && (
        <LightTooltip placement="top" arrow title={t('Hide Favorites')}>
          <IconButton
            sx={{
              background: `${theme.colors.alpha.trueWhite[30]}`,
              color: `${theme.colors.alpha.trueWhite[70]}`,
              transition: `${theme.transitions.create(['all'])}`,

              '&:hover': {
                background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`,
                color: `${theme.colors.alpha.trueWhite[100]}`
              },
              mx: 1
            }}
            onClick={() => toggleFavorites(false)}
          >
            <AutoAwesomeSharpIcon fontSize="small" />
          </IconButton>
        </LightTooltip>
      )}
      {!showFavorites && favoriteCount > 0 && (
        <LightTooltip placement="top" arrow title={t('Show Favorites')}>
          <Badge
            sx={{
              '.MuiBadge-badge': {
                fontSize: theme.typography.pxToRem(9),
                right: 12,
                top: 2,
                color: '#000000'
              }
            }}
            overlap="circular"
            color="secondary"
            badgeContent={favoriteCount}
            showZero={false}
          >
            <IconButton
              onClick={() => toggleFavorites(true)}
              sx={{
                background: `${theme.colors.alpha.trueWhite[10]}`,
                color: `${theme.colors.alpha.trueWhite[70]}`,
                transition: `${theme.transitions.create(['all'])}`,

                '&:hover': {
                  background: `${alpha(
                    theme.colors.alpha.trueWhite[100],
                    0.2
                  )}`,
                  color: `${theme.colors.alpha.trueWhite[100]}`
                },
                mx: 1
              }}
            >
              <AutoAwesomeTwoToneIcon fontSize="small" />
            </IconButton>
          </Badge>
        </LightTooltip>
      )}
      <LightTooltip placement="top" arrow title={t('Logout')}>
        <IconButton
          sx={{
            background: `${theme.colors.alpha.trueWhite[10]}`,
            color: `${theme.colors.alpha.trueWhite[70]}`,
            transition: `${theme.transitions.create(['all'])}`,

            '&:hover': {
              background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`,
              color: `${theme.colors.alpha.trueWhite[100]}`
            }
          }}
          onClick={handleLogout}
        >
          <PowerSettingsNewTwoToneIcon fontSize="small" />
        </IconButton>
      </LightTooltip>
    </Box>
  )
}

export default SidebarFooter

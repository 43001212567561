import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteEntityHelperById,
  mergeEntitiesHelperById,
  setEntitiesHelperById
} from '../../../wdql/helpers'
import { PartialPricebookItemEntity, PricebookItemEntity } from './entity'

const initialState: Array<PricebookItemEntity> = []

export const pricebookitemSlice = createSlice({
  name: 'pricebookitems',
  initialState,
  reducers: {
    resetPricebookItems: () => [...initialState],
    replacePricebookItems: (
      state,
      action: PayloadAction<Array<PartialPricebookItemEntity>>
    ) => [...action.payload] as Array<PricebookItemEntity>,
    setPricebookItems: (
      state,
      action: PayloadAction<Array<PartialPricebookItemEntity>>
    ) => {
      setEntitiesHelperById(state, action.payload)
    },
    mergePricebookItems: (
      state,
      action: PayloadAction<Array<PartialPricebookItemEntity>>
    ) => {
      mergeEntitiesHelperById(state, action.payload)
    },
    patchPricebookItem: (
      state,
      action: PayloadAction<PartialPricebookItemEntity>
    ) => {
      mergeEntitiesHelperById(state, [action.payload], false)
    },
    deletePricebookItem: (
      state,
      action: PayloadAction<number | Partial<PricebookItemEntity>>
    ) => deleteEntityHelperById(state, action.payload)
  }
})

export const {
  setPricebookItems,
  replacePricebookItems,
  mergePricebookItems,
  deletePricebookItem,
  patchPricebookItem,
  resetPricebookItems
} = pricebookitemSlice.actions

export default pricebookitemSlice.reducer

import type { TypedUseSelectorHook } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'
import {
  combineReducers,
  configureStore,
  EnhancedStore
} from '@reduxjs/toolkit'
import baseReducer from './slices/base/baseSlice'
import authReducer from './slices/auth/authSlice'
import profileReducer, { resetProfile } from './slices/profile/profileSlice'
import roleReducer, { resetRoles } from './slices/roles/reducer'
import userReducer, { resetUsers } from './slices/users/reducer'
import favoriteReducer, { resetFavorites } from './slices/favorites/reducer'
import customerReducer from './slices/customers/reducer'
import domainReducer from './slices/domains/reducer'
import folderReducer from './slices/folders/reducer'
import searchReducer from './slices/searches/reducer'
import pdContactReducer from './slices/pipelinedeals/contacts/reducer'
import pdCompanyReducer from './slices/pipelinedeals/companies/reducer'
import pdDealReducer from './slices/pipelinedeals/deals/reducer'
import customerStatesReducer from './slices/customerstates/reducer'
import followReducer from './slices/follows/reducer'
import activityReducer from './slices/activities/reducer'
import paymoProjectReducer from './slices/paymo/projects/reducer'
import paymoTaskReducer from './slices/paymo/tasks/reducer'
import paymoTimeEntryReducer from './slices/paymo/timeEntries/reducer'
import helpScoutConversationReducer from './slices/helpscout/conversations/reducer'
import jobTicketsReducer from './slices/job-tickets/reducer'
import pricebookItemsReducer from './slices/pricebook/items/reducer'
import pricebookCategoriesReducer from './slices/pricebook/categories/reducer'
import pricebookDiscountsReducer from './slices/pricebook/discounts/reducer'
import accountExecutiveReducer from './slices/account-executives/reducer'
import bucketReducer from './slices/retainers/buckets/reducer'
import bucketLedgerEntryReducer from './slices/retainers/bucket-ledger-entries/reducer'
import bucketProjectHistoryReducer from './slices/retainers/bucket-project-histories/reducer'

const store = configureStore({
  reducer: combineReducers({
    base: baseReducer,
    auth: authReducer,
    profile: profileReducer,
    entities: combineReducers({
      accountExecutives: accountExecutiveReducer,
      activities: activityReducer,
      customers: customerReducer,
      domains: domainReducer,
      favorites: favoriteReducer,
      follows: followReducer,
      folders: folderReducer,
      helpscout: combineReducers({
        conversations: helpScoutConversationReducer
      }),
      jobTickets: jobTicketsReducer,
      paymo: combineReducers({
        projects: paymoProjectReducer,
        tasks: paymoTaskReducer,
        timeEntries: paymoTimeEntryReducer
      }),
      pipelineDeals: combineReducers({
        contacts: pdContactReducer,
        companies: pdCompanyReducer,
        deals: pdDealReducer
      }),
      pricebook: combineReducers({
        items: pricebookItemsReducer,
        categories: pricebookCategoriesReducer,
        discounts: pricebookDiscountsReducer
      }),
      retainers: combineReducers({
        buckets: bucketReducer,
        bucketLedgerEntries: bucketLedgerEntryReducer,
        bucketProjectHistories: bucketProjectHistoryReducer
      }),
      roles: roleReducer,
      searches: searchReducer,
      users: userReducer,
      customerStates: customerStatesReducer
    })
  }),
  devTools: true
})
export const storeDispatch = store.dispatch

export const resetStore = () => {
  storeDispatch(resetRoles())
  storeDispatch(resetUsers())
  storeDispatch(resetFavorites())
  storeDispatch(resetProfile())
}

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

let storeInstance: EnhancedStore | null = null

export function setStoreInstance(store: EnhancedStore) {
  storeInstance = store
}

export function getStoreInstance(): EnhancedStore<RootState> | null {
  return storeInstance
}

setStoreInstance(store)

export default store

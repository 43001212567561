import { FC } from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import NoContentSimple from '../../../../components/helpers/NoContentSimple'
import useSearches from '../../../../store/slices/searches/useSearches'
import SearchTrigger from './SearchTrigger'

interface RecentSearchesProps {
  setSearch: (s: string) => void
}

const RecentSearches: FC<RecentSearchesProps> = ({ setSearch }) => {
  const { t }: { t: any } = useTranslation()
  const { recent } = useSearches()

  // return <Box>No Recent Searches</Box>

  return (
    <>
      <Typography
        sx={{
          pb: 0.5
        }}
        variant="h5"
      >
        {t('Recent searches...')}
      </Typography>
      {recent.map((r) => (
        <SearchTrigger
          key={r.id}
          id={r.id}
          term={r.term}
          type="recent"
          onActivate={setSearch}
        />
      ))}
      {recent.length === 0 && (
        <NoContentSimple>No Recent Searches</NoContentSimple>
      )}
    </>
  )
}

export default RecentSearches

import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteEntityHelperById,
  mergeEntitiesHelperById,
  setEntitiesHelperById
} from '../../../wdql/helpers'
import { PartialPaymoTimeEntryEntity, PaymoTimeEntryEntity } from './entity'

const initialState: Array<PaymoTimeEntryEntity> = []

export const paymotimeentrySlice = createSlice({
  name: 'timeEntries',
  initialState,
  reducers: {
    resetPaymoTimeEntries: () => [...initialState],
    replacePaymoTimeEntries: (
      state,
      action: PayloadAction<Array<PartialPaymoTimeEntryEntity>>
    ) => action.payload as Array<PaymoTimeEntryEntity>,
    setPaymoTimeEntries: (
      state,
      action: PayloadAction<Array<PartialPaymoTimeEntryEntity>>
    ) => {
      setEntitiesHelperById(state, action.payload)
    },
    mergePaymoTimeEntries: (
      state,
      action: PayloadAction<Array<PartialPaymoTimeEntryEntity>>
    ) => {
      mergeEntitiesHelperById(state, action.payload)
    },
    patchPaymoTimeEntry: (
      state,
      action: PayloadAction<PartialPaymoTimeEntryEntity>
    ) => {
      mergeEntitiesHelperById(state, [action.payload], false)
    },
    deletePaymoTimeEntry: (
      state,
      action: PayloadAction<number | Partial<PaymoTimeEntryEntity>>
    ) => deleteEntityHelperById(state, action.payload)
  }
})

export const {
  setPaymoTimeEntries,
  replacePaymoTimeEntries,
  mergePaymoTimeEntries,
  deletePaymoTimeEntry,
  patchPaymoTimeEntry,
  resetPaymoTimeEntries
} = paymotimeentrySlice.actions

export default paymotimeentrySlice.reducer

import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteEntityHelperById,
  mergeEntitiesHelperById,
  setEntitiesHelperById
} from '../../../wdql/helpers'
import { PartialBucketEntity, BucketEntity } from './entity'

const initialState: Array<BucketEntity> = []

export const bucketSlice = createSlice({
  name: 'buckets',
  initialState,
  reducers: {
    resetBuckets: () => [...initialState],
    replaceBuckets: (
      state,
      action: PayloadAction<Array<PartialBucketEntity>>
    ) => action.payload as Array<BucketEntity>,
    setBuckets: (state, action: PayloadAction<Array<PartialBucketEntity>>) => {
      setEntitiesHelperById(state, action.payload)
    },
    mergeBuckets: (
      state,
      action: PayloadAction<Array<PartialBucketEntity>>
    ) => {
      mergeEntitiesHelperById(state, action.payload)
    },
    patchBucket: (state, action: PayloadAction<PartialBucketEntity>) => {
      mergeEntitiesHelperById(state, [action.payload], false)
    },
    deleteBucket: (
      state,
      action: PayloadAction<number | Partial<BucketEntity>>
    ) => deleteEntityHelperById(state, action.payload)
  }
})

export const {
  setBuckets,
  replaceBuckets,
  mergeBuckets,
  deleteBucket,
  patchBucket,
  resetBuckets
} = bucketSlice.actions

export default bucketSlice.reducer

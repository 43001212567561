import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react'
import { useDispatch } from 'react-redux'
import useProfile from '../store/slices/profile/useProfile'
import { get } from '../remote'
import { WdqlResponse } from '../store/wdql/types'
import useGlobalContext from '../hooks/useGlobalContext'
import useDomains from '../store/slices/domains/useDomains'
import {
  PermissionList,
  setPermissionList
} from '../store/slices/base/baseSlice'
import useWdAuthenticator from '../packages/WdAuthenticator/useWdAuthenticator'
// import useTraceUpdate from '../hooks/useTraceUpdate'

type AppContextType = {
  loaded: boolean
  loading: boolean
  setLoading: (isLoading: boolean) => void
}

interface AppContextProps {
  children: ReactNode
}

export const AppContext = createContext<AppContextType>({
  loaded: false,
  loading: false,
  setLoading: () => null
})

interface BaseDataResponse {
  permissionList: PermissionList
}

const AppContextWrapper: FC<AppContextProps> = (props: AppContextProps) => {
  const { children } = props
  const { loaded: globalLoaded } = useGlobalContext()
  const [appLoading, setAppLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loaded, setLoaded] = useState<boolean>(false)
  const { authcheck } = useWdAuthenticator()
  const { load: loadProfile } = useProfile()
  const { load: loadDomains } = useDomains()
  const dispatch = useDispatch()

  const loadBaseData = useCallback(async () => {
    const baseData = await get<WdqlResponse<BaseDataResponse>>(
      '/api/v2/base-loader'
    )
    if (baseData) {
      dispatch(setPermissionList(baseData.data.payload.permissionList))
    }
  }, [dispatch])

  const loadAppContent = useCallback(async () => {
    // console.log('loadingAppContent', loaded, loading, globalLoaded)
    if (!loaded && !globalLoaded) {
      // console.log('LOAD EVERYTHING...')
      setLoading(true)
      loadDomains().then()
      // Call function to load all initial app content
      await Promise.all([authcheck(), loadBaseData(), loadProfile()]).then(
        () => {
          // setGlobalLoaded(true)
          setLoaded(true)
        }
      )
      setLoaded(true)
      setLoading(false)
    }
  }, [authcheck, globalLoaded, loadBaseData, loadDomains, loadProfile, loaded])

  useEffect(() => {
    if (!loading) {
      loadAppContent().then()
    }
  }, [loadAppContent, loading])

  // useTraceUpdate(
  //   {
  //     loaded: loaded,
  //     appLoading: appLoading,
  //     setAppLoading: setAppLoading,
  //     loadAppContent: loadAppContent,
  //     authcheck: authcheck,
  //     globalLoaded: globalLoaded,
  //     loadBaseData: loadBaseData,
  //     loadDomains: loadDomains,
  //     loadProfile: loadProfile,
  //     loading: loading
  //   },
  //   'AppContext'
  // )

  return (
    <AppContext.Provider
      value={{
        loaded,
        loading: appLoading,
        setLoading: setAppLoading
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppContextWrapper

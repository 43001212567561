// import { wdqlProcessRoles } from '../slices/roles/dispatch'
// import { wdqlProcessUsers } from '../slices/users/dispatch'
// import { wdqlProcessFavorites } from '../slices/favorites/dispatch'
// import { wdqlProcessCustomers } from '../slices/customers/dispatch'
// import { wdqlProcessDomains } from '../slices/domains/dispatch'
// import { wdqlProcessSearches } from '../slices/searches/dispatch'
// import { wdqlProcessCustomerStates } from '../slices/customerstates/dispatch'
// import { wdqlProcessFollows } from '../slices/follows/dispatch'
// import { wdqlProcessActivities } from '../slices/activities/dispatch'
// import { wdqlProcessPaymoProjects } from '../slices/paymo/projects/dispatch'
// import { wdqlProcessPdContacts } from '../slices/pipelinedeals/contacts/dispatch'
// import { wdqlProcessPaymoTasks } from '../slices/paymo/tasks/dispatch'
// import { wdqlProcessPaymoTimeEntries } from '../slices/paymo/timeEntries/dispatch'
// import { wdqlProcessJobTickets } from '../slices/job-tickets/dispatch'
// import { wdqlProcessPricebookItems } from '../slices/pricebook/items/dispatch'
// import { wdqlProcessPdCompanies } from '../slices/pipelinedeals/companies/dispatch'
// import { wdqlProcessPdDeals } from '../slices/pipelinedeals/deals/dispatch'
// import { wdqlProcessFolders } from '../slices/folders/dispatch'
// import { wdqlProcessPricebookCategories } from '../slices/pricebook/categories/dispatch'
// import { wdqlProcessPricebookDiscounts } from '../slices/pricebook/discounts/dispatch'
// import { wdqlProcessAccountExecutives } from '../slices/account-executives/dispatch'
// import { wdqlProcessBuckets } from '../slices/retainers/buckets/dispatch'
// import { wdqlProcessBucketLedgerEntries } from '../slices/retainers/bucket-ledger-entries/dispatch'
// import { wdqlProcessBucketProjectHistories } from '../slices/retainers/bucket-project-histories/dispatch'
// import { SliceResponseType } from './types'

// export const wdqlSlice = (d: SliceResponseType | Array<SliceResponseType>) => {
//   return wdqlProcess(Array.isArray(d) ? d : [d])
// }

import { dispatchMap } from '../dispatchMap'
import { SliceResponseType, DispatchFunction } from './types'

export const wdqlSlice = (d: SliceResponseType | Array<SliceResponseType>) =>
  wdqlProcess(Array.isArray(d) ? d : [d])

export const wdqlProcess = async (wdqlData: Array<SliceResponseType>) => {
  await Promise.all(
    wdqlData.map(async (wBlock) => {
      const processFunction = await dispatchMap[wBlock.slice]?.()
      if (processFunction) {
        ;(processFunction as DispatchFunction<typeof wBlock.data>)(
          wBlock.data,
          wBlock.mode
        )
      } else {
        console.error(`No dispatch function found for slice: ${wBlock.slice}`)
      }
    })
  )
}

// export const wdqlProcess2 = (wdqlData: Array<SliceResponseType>) => {
//   wdqlData.forEach((wBlock) => {
//     switch (wBlock.slice) {
//       case 'roles':
//         wdqlProcessRoles(wBlock.data, wBlock.mode)
//         break
//       case 'users':
//         wdqlProcessUsers(wBlock.data, wBlock.mode)
//         break
//       case 'favorites':
//         wdqlProcessFavorites(wBlock.data, wBlock.mode)
//         break
//       case 'follows':
//         wdqlProcessFollows(wBlock.data, wBlock.mode)
//         break
//       case 'customers':
//         wdqlProcessCustomers(wBlock.data, wBlock.mode)
//         break
//       case 'domains':
//         wdqlProcessDomains(wBlock.data, wBlock.mode)
//         break
//       case 'folders':
//         wdqlProcessFolders(wBlock.data, wBlock.mode)
//         break
//       case 'searches':
//         wdqlProcessSearches(wBlock.data, wBlock.mode)
//         break
//       case 'customerStates':
//         wdqlProcessCustomerStates(wBlock.data, wBlock.mode)
//         break
//       case 'activities':
//         wdqlProcessActivities(wBlock.data, wBlock.mode)
//         break
//       case 'pdContacts':
//         wdqlProcessPdContacts(wBlock.data, wBlock.mode)
//         break
//       case 'pdCompanies':
//         wdqlProcessPdCompanies(wBlock.data, wBlock.mode)
//         break
//       case 'pdDeals':
//         wdqlProcessPdDeals(wBlock.data, wBlock.mode)
//         break
//       case 'paymoProjects':
//         wdqlProcessPaymoProjects(wBlock.data, wBlock.mode)
//         break
//       case 'paymoTasks':
//         wdqlProcessPaymoTasks(wBlock.data, wBlock.mode)
//         break
//       case 'paymoTimeEntries':
//         wdqlProcessPaymoTimeEntries(wBlock.data, wBlock.mode)
//         break
//       case 'helpScoutConversations':
//         wdqlProcessPaymoTasks(wBlock.data, wBlock.mode)
//         break
//       case 'jobTickets':
//         wdqlProcessJobTickets(wBlock.data, wBlock.mode)
//         break
//       case 'pricebookItems':
//         wdqlProcessPricebookItems(wBlock.data, wBlock.mode)
//         break
//       case 'pricebookCategories':
//         wdqlProcessPricebookCategories(wBlock.data, wBlock.mode)
//         break
//       case 'pricebookDiscounts':
//         wdqlProcessPricebookDiscounts(wBlock.data, wBlock.mode)
//         break
//       case 'accountExecutives':
//         wdqlProcessAccountExecutives(wBlock.data, wBlock.mode)
//         break
//       case 'buckets':
//         wdqlProcessBuckets(wBlock.data, wBlock.mode)
//         break
//       case 'bucketLedgerEntries':
//         wdqlProcessBucketLedgerEntries(wBlock.data, wBlock.mode)
//         break
//       case 'bucketProjectHistories':
//         wdqlProcessBucketProjectHistories(wBlock.data, wBlock.mode)
//         break
//     }
//   })
// }

import { Box } from '@mui/material'
import DashboardButton from '../../../../components/buttons/DashboardButton/DashboardButton'
import FavoriteToggleButton from '../../../../components/buttons/FavoriteToggleButton'
import DebugButton from '../../../../components/buttons/DebugButton'
import BugReportButton from '../../../../components/buttons/BugReportButton'

function HeaderMenu() {
  return (
    <>
      <Box
        sx={{
          display: { xs: 'none', md: 'inline-flex' }
        }}
      >
        <DashboardButton />
        <FavoriteToggleButton />
        <DebugButton />
        <BugReportButton />
      </Box>
      <Box
        sx={{
          display: { xs: 'inline-flex', md: 'none' }
        }}
      >
        <DashboardButton />
      </Box>
    </>
  )
}

export default HeaderMenu

import { v4 as uuidv4 } from 'uuid'

export const getUniqueId = () => uuidv4()
const randomId = (): string => {
  const arr = new Uint8Array(12)
  window.crypto.getRandomValues(arr)
  return Array.from(arr, (v) => v.toString(16).padStart(2, '0')).join('')
}

export default randomId

import { useRoutes } from 'react-router-dom'

import { SnackbarProvider } from 'notistack'
import { CssBaseline } from '@mui/material'

import { Toaster } from 'react-hot-toast'
import router from './router'
import ThemeProvider from './theme/ThemeProvider'
import LoadingGlobal from './components/helpers/LoadingGlobal'
import useGlobalContext from './hooks/useGlobalContext'
import PopupStackRender from './components/app/PopupStackRender'

function App() {
  const content = useRoutes(router)
  const { isSpinning } = useGlobalContext()
  // const { isInitialized } = useAuth()
  // console.log('render App')

  return (
    <ThemeProvider>
      <LoadingGlobal show={isSpinning} />
      <SnackbarProvider
        maxSnack={6}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <CssBaseline />
        <PopupStackRender />
        {/* <AppInit /> */}
        {content}
        {/* {isInitialized ? content : <AppInit />} */}
        <Toaster
          position="top-right"
          reverseOrder={true}
          toastOptions={{ duration: 3000 }}
        />
      </SnackbarProvider>
    </ThemeProvider>
  )
}
export default App

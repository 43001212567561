import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteEntityHelperByKey,
  mergeEntitiesHelperByKey,
  setEntitiesHelperByKey
} from '../../wdql/helpers'
import { PartialRoleEntity, RoleEntity } from './entity'

const initialState: Array<RoleEntity> = []

export const roleSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    resetRoles: () => [...initialState],
    replaceRoles: (_state, action: PayloadAction<Array<RoleEntity>>) =>
      action.payload,
    setRoles: (state, action: PayloadAction<Array<RoleEntity>>) => {
      setEntitiesHelperByKey(state, action.payload)
    },
    patchRole: (state, action: PayloadAction<PartialRoleEntity>) => {
      mergeEntitiesHelperByKey(state, [action.payload], false)
    },
    mergeRoles: (state, action: PayloadAction<Array<RoleEntity>>) => {
      mergeEntitiesHelperByKey(state, action.payload)
    },
    deleteRole: (state, action: PayloadAction<string | PartialRoleEntity>) =>
      deleteEntityHelperByKey(state, action.payload)
  }
})

export const {
  setRoles,
  replaceRoles,
  mergeRoles,
  deleteRole,
  resetRoles,
  patchRole
} = roleSlice.actions

export default roleSlice.reducer

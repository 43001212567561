import React, {
  FC,
  forwardRef,
  ReactElement,
  ReactNode,
  Ref,
  useCallback
} from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Slide,
  styled
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { DialogProps } from '@mui/material/Dialog'
import { TransitionProps } from '@mui/material/transitions'
import DialogContentText from '@mui/material/DialogContentText'

export const Transition = forwardRef(
  (
    props: TransitionProps & { children: ReactElement<any, any> },
    ref: Ref<unknown>
  ) => {
    const { children, ...rest } = props
    return (
      <Slide direction="down" ref={ref} {...rest}>
        {children}
      </Slide>
    )
  }
)

const TitleWrapper = styled(DialogTitle)(
  () => `
      font-weight: bold;
      font-size: 20px;
      padding-bottom:10px;
`
)

const DescriptionWrapper = styled(DialogContentText)(
  () => `
      font-size: 12px;
      padding-bottom:10px;
`
)

export type SimpleContentPopupTrigger = ReactElement<{
  onClick: (event: HTMLElementEventMap['click']) => void
}>

interface SimpleContentPopupProps {
  children: ReactNode
  trigger?: SimpleContentPopupTrigger | null
  onOpen?: () => void
  onClose: () => void
  open?: boolean
  title?: string
  description?: string
  maxWidth?: DialogProps['maxWidth']
  fullWidth?: boolean
  closeWindow?: boolean
  actions?: ReactNode | Array<ReactNode>
  autoClose?: boolean // If set to false, you cannot click outside the popup to automatically close it
}

const SimpleContentPopup: FC<SimpleContentPopupProps> = ({
  trigger,
  children,
  onOpen,
  onClose,
  title,
  description,
  maxWidth,
  actions,
  open = false,
  fullWidth = true,
  closeWindow = true,
  autoClose = true
}: SimpleContentPopupProps) => {
  const handleClose = () => {
    onClose()
  }

  const handleClick = useCallback(
    (event: HTMLElementEventMap['click']) => {
      if (
        trigger &&
        typeof trigger === 'object' &&
        'props' in trigger &&
        typeof trigger.props.onClick === 'function'
      ) {
        trigger.props.onClick(event)
      }
      if (!!onOpen) {
        onOpen()
      }
    },
    [onOpen, trigger]
  )

  const triggerElement = !!trigger
    ? React.cloneElement(trigger, {
        onClick: handleClick
      })
    : null

  return (
    <>
      {triggerElement}
      <Dialog
        open={open}
        onClose={autoClose ? handleClose : undefined}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        TransitionComponent={Transition}
      >
        {!!title && <TitleWrapper>{title}</TitleWrapper>}
        {closeWindow ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
        <DialogContent sx={{ mt: 0, pt: 0 }}>
          {!!description && (
            <DescriptionWrapper>{description}</DescriptionWrapper>
          )}
          {children}
        </DialogContent>
        {!!actions && <DialogActions>{actions}</DialogActions>}
      </Dialog>
    </>
  )
}

export default SimpleContentPopup

import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteEntityHelperById,
  mergeEntitiesHelperById,
  setEntitiesHelperById
} from '../../wdql/helpers'
import { PartialCustomerStateEntity, CustomerStateEntity } from './entity'

const initialState: Array<CustomerStateEntity> = []

export const customerstateSlice = createSlice({
  name: 'customerstates',
  initialState,
  reducers: {
    resetCustomerStates: () => [...initialState],
    replaceCustomerStates: (
      state,
      action: PayloadAction<Array<PartialCustomerStateEntity>>
    ) => action.payload as Array<CustomerStateEntity>,
    setCustomerStates: (
      state,
      action: PayloadAction<Array<PartialCustomerStateEntity>>
    ) => {
      setEntitiesHelperById(state, action.payload)
    },
    mergeCustomerStates: (
      state,
      action: PayloadAction<Array<PartialCustomerStateEntity>>
    ) => {
      mergeEntitiesHelperById(state, action.payload)
    },
    patchCustomerState: (
      state,
      action: PayloadAction<PartialCustomerStateEntity>
    ) => {
      mergeEntitiesHelperById(state, [action.payload], false)
    },
    deleteCustomerState: (
      state,
      action: PayloadAction<number | Partial<CustomerStateEntity>>
    ) => deleteEntityHelperById(state, action.payload)
  }
})

export const {
  setCustomerStates,
  replaceCustomerStates,
  mergeCustomerStates,
  deleteCustomerState,
  patchCustomerState,
  resetCustomerStates
} = customerstateSlice.actions

export default customerstateSlice.reducer

import { patch, post, remove } from '../../../remote'
import { WdqlResponse } from '../../wdql/types'
import { FavoriteEntity, PartialFavoriteEntity } from './entity'
import { wdqlProcessFavorites } from './dispatch'

export const saveFavorite = (fav: PartialFavoriteEntity) => {
  if (fav.id && fav.userId && fav.url && fav.name) {
    wdqlProcessFavorites([fav as FavoriteEntity], 'merge')
  }
  if (fav.id) {
    patch<WdqlResponse<FavoriteEntity>>('/api/v2/favorite/' + fav.id, fav).then(
      (resp) => {
        if (resp?.data && resp.data.valid) {
          wdqlProcessFavorites([resp?.data.payload], 'merge')
        }
      }
    )
  } else {
    post<WdqlResponse<FavoriteEntity>>('/api/v2/favorite', fav).then((resp) => {
      if (resp?.data && resp.data.valid) {
        wdqlProcessFavorites([resp?.data.payload], 'merge')
      }
    })
  }
}

export const deleteFavorite = (
  id: number | FavoriteEntity | null | undefined
) => {
  if (!id) {
    return
  }
  const fav = typeof id === 'number' ? { id } : id
  wdqlProcessFavorites([fav as FavoriteEntity], 'delete')
  remove<WdqlResponse<number>>('/api/v2/favorite/' + fav.id).then((resp) => {
    if (resp?.data && resp.data.valid) {
      wdqlProcessFavorites(
        [{ id: resp.data.payload } as FavoriteEntity],
        'delete'
      )
    }
  })
}

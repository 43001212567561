import { splitKeyValueString } from '../utils/tools'

export interface AccessRecord {
  role?: Record<string, boolean>
  permission?: Record<string, boolean>
}

type AccessRequirementItem =
  | { all?: Array<string>; any?: Array<string> }
  | string

export type AccessRequirement =
  | AccessRequirementItem
  | Array<AccessRequirementItem>
  | null

export const checkAccessRequirement = (
  r: AccessRequirement,
  access: AccessRecord,
  type: 'permission' | 'role' | null = null,
  anyAll: 'all' | 'any' = 'all'
): boolean => {
  // console.log('checkAccessRequirement', r, access, type, anyAll)
  if (!!access?.role?.superadmin) {
    return true
  }
  if (typeof r === 'string') {
    const aCheck = splitKeyValueString(r)
    const permYes =
      type === 'permission' || type === null
        ? !!access?.permission?.[aCheck.value]
        : false
    if (aCheck.key === 'permission') {
      return permYes
    }
    const roleYes =
      type === 'role' || type === null ? !!access?.role?.[aCheck.value] : false
    if (aCheck.key === 'role') {
      return roleYes
    }
    return permYes || roleYes
  }
  if (Array.isArray(r)) {
    return r.reduce(
      (a, i) =>
        anyAll === 'all'
          ? a && checkAccessRequirement(i, access, type)
          : a || checkAccessRequirement(i, access, type),
      anyAll === 'all'
    )
  }
  if (typeof r === 'object') {
    if (r?.all) {
      return checkAccessRequirement(r.all, access, type, 'all')
    }
    if (r?.any) {
      return checkAccessRequirement(r.any, access, type, 'any')
    }
  }
  return false
}

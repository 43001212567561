import { useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../index'
import { get } from '../../../remote'
import useDeepEqualMemo from '../../../hooks/useDeepEqualMemo'
import {
  AccessRecord,
  AccessRequirement,
  checkAccessRequirement
} from '../../../models/access'
import { prefixApiUrl } from '../../../utils/tools'
import { ProfileState, setProfile } from './profileSlice'

export type ProfileResponse = Partial<ProfileState> & { valid: boolean }

// interface useFakeHookInterface {}
export interface UseProfileResponse {
  id: number | null
  name: string
  picture: string
  title: string
  load: (force?: boolean) => Promise<void>
  access: AccessRecord
  reload: () => Promise<void>
  check: {
    access: (accessKey: string | string[]) => boolean
    role: (roleKey: string | string[]) => boolean
    required: (required?: AccessRequirement) => boolean
  }
}

const useProfile = (loadInit: boolean = true): UseProfileResponse => {
  const dispatch = useAppDispatch()
  const profile = useAppSelector((state) => state.profile)
  const users = useAppSelector((state) => state.entities.users)
  const userList = useDeepEqualMemo(users)
  const profileId = profile.id ?? null
  const activeUser = useMemo(
    () => userList?.find((u) => u.id === profileId),
    [profileId, userList]
  )
  const userCache = useDeepEqualMemo(activeUser?.cached ?? {})
  const access = useMemo((): AccessRecord => userCache ?? {}, [userCache])

  const check = useMemo(
    () => ({
      access: (k: string | string[]) =>
        checkAccessRequirement(k, access, 'permission'),
      role: (r: string | string[]) => checkAccessRequirement(r, access, 'role'),
      required: (r?: AccessRequirement) =>
        !r ? true : checkAccessRequirement(r, access)
    }),
    [access]
  )

  const loadProfile = useCallback(
    async (force: boolean = false) => {
      await get<ProfileResponse>('/api/v2/profile', { noCache: force }).then(
        (resp) => {
          if (resp) {
            const { valid, ...rest } = resp.data
            if (valid) {
              dispatch(setProfile({ ...rest }))
            }
          }
        }
      )
    },
    [dispatch]
  )

  const reload = useCallback(() => loadProfile(), [loadProfile])

  useEffect(() => {
    if (loadInit && !profile.email) {
      loadProfile().then()
    }
  }, [loadInit, loadProfile, profile.email])

  return useMemo(
    () => ({
      id: profileId,
      name: profile.name ?? 'User',
      picture: prefixApiUrl(profile.picture) ?? '',
      title: profile.title ?? '',
      load: loadProfile,
      access,
      reload,
      check
    }),
    [
      access,
      check,
      loadProfile,
      profile.name,
      profile.picture,
      profile.title,
      profileId,
      reload
    ]
  )
}

export default useProfile

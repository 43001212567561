import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteEntityHelperById,
  mergeEntitiesHelperById,
  setEntitiesHelperById
} from '../../wdql/helpers'
import { PartialFolderEntity, FolderEntity } from './entity'

const initialState: Array<FolderEntity> = []

export const folderSlice = createSlice({
  name: 'folders',
  initialState,
  reducers: {
    resetFolders: () => [...initialState],
    replaceFolders: (
      state,
      action: PayloadAction<Array<PartialFolderEntity>>
    ) => action.payload as Array<FolderEntity>,
    setFolders: (state, action: PayloadAction<Array<PartialFolderEntity>>) => {
      setEntitiesHelperById(state, action.payload)
    },
    mergeFolders: (
      state,
      action: PayloadAction<Array<PartialFolderEntity>>
    ) => {
      mergeEntitiesHelperById(state, action.payload)
    },
    patchFolder: (state, action: PayloadAction<PartialFolderEntity>) => {
      mergeEntitiesHelperById(state, [action.payload], false)
    },
    deleteFolder: (
      state,
      action: PayloadAction<number | Partial<FolderEntity>>
    ) => deleteEntityHelperById(state, action.payload)
  }
})

export const {
  setFolders,
  replaceFolders,
  mergeFolders,
  deleteFolder,
  patchFolder,
  resetFolders
} = folderSlice.actions

export default folderSlice.reducer

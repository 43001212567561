import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteEntityHelperById,
  mergeEntitiesHelperById,
  setEntitiesHelperById
} from '../../wdql/helpers'
import { PartialSearchEntity, SearchEntity } from './entity'

const initialState: Array<SearchEntity> = []

export const searchSlice = createSlice({
  name: 'searches',
  initialState,
  reducers: {
    resetSearches: () => [...initialState],
    replaceSearches: (
      state,
      action: PayloadAction<Array<PartialSearchEntity>>
    ) => action.payload as Array<SearchEntity>,
    setSearches: (state, action: PayloadAction<Array<PartialSearchEntity>>) => {
      setEntitiesHelperById(state, action.payload)
    },
    mergeSearches: (
      state,
      action: PayloadAction<Array<PartialSearchEntity>>
    ) => {
      mergeEntitiesHelperById(state, action.payload)
    },
    patchSearch: (state, action: PayloadAction<PartialSearchEntity>) => {
      mergeEntitiesHelperById(state, [action.payload], false)
    },
    deleteSearch: (
      state,
      action: PayloadAction<number | Partial<SearchEntity>>
    ) => deleteEntityHelperById(state, action.payload)
  }
})

export const {
  setSearches,
  replaceSearches,
  mergeSearches,
  deleteSearch,
  patchSearch,
  resetSearches
} = searchSlice.actions

export default searchSlice.reducer

import { FC, useState } from 'react'
import { IconButton, styled, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone'
import { saveRecentSearch } from '../../../../store/slices/searches/actions'
import useSearches from '../../../../store/slices/searches/useSearches'
import SearchDialog from './SearchDialog'

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        width: ${theme.spacing(4)};
        height: ${theme.spacing(4)};
        border-radius: ${theme.general.borderRadiusLg};
`
)

interface HeaderSearchProps {}

const HeaderSearch: FC<HeaderSearchProps> = () => {
  const { t }: { t: any } = useTranslation()
  const [open, setOpen] = useState(false)
  useSearches()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Tooltip arrow title={t('Search')}>
        <IconButtonWrapper color="primary" onClick={handleClickOpen}>
          <SearchTwoToneIcon fontSize="small" />
        </IconButtonWrapper>
      </Tooltip>
      <SearchDialog
        open={open}
        onClose={handleClose}
        onRecent={saveRecentSearch}
      />
    </>
  )
}

export default HeaderSearch

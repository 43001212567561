import { FC } from 'react'
import { IconButton, styled, Tooltip } from '@mui/material'
import TerminalIcon from '@mui/icons-material/Terminal'
import { useLocation } from 'react-router-dom'
import { IN_DEV_MODE } from '../../../config'
import { get } from '../../../remote'
import useAccess from '../../../hooks/useAccess'

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        width: ${theme.spacing(4)};
        height: ${theme.spacing(4)};
        border-radius: ${theme.general.borderRadiusLg};
`
)

interface DebugButtonProps {}

const DebugButton: FC<DebugButtonProps> = () => {
  const superadmin = useAccess('superadmin')
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const devModeParam = queryParams.get('devmode')
  const isDevMode = devModeParam === 'true' && superadmin

  if (!IN_DEV_MODE && !isDevMode) {
    return null
  }

  const debugAction = () => {
    get('/api/v2/app/_dev').then((r) => {
      console.log('DEBUG RESPONSE', r)
    })
  }

  return (
    <Tooltip arrow title={'Run Debug Function'}>
      <IconButtonWrapper color="primary" onClick={debugAction} sx={{ mr: 1 }}>
        <TerminalIcon fontSize="small" />
      </IconButtonWrapper>
    </Tooltip>
  )
}

export default DebugButton

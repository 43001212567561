import { ReactNode } from 'react'
import { formatDistanceToNow } from 'date-fns'
import { prefixApiUrl } from '../../../utils/tools'
import { PartialCustomerEntity } from './entity'

export interface CustomerMutatorInterface {
  mutated: true
  update: (entity: PartialCustomerEntity) => CustomerMutatorClass
  htmlBlockAddress: () => ReactNode
  stringAddress: () => string | null
  onboarded: () => string | null
  logo: () => string | undefined
  formattedAccountNumber: () => string | null
}
class CustomerMutatorClass implements CustomerMutatorInterface {
  private entity: PartialCustomerEntity

  public mutated: true = true

  constructor(entity: PartialCustomerEntity) {
    this.entity = entity
  }

  update(entity: PartialCustomerEntity): CustomerMutatorClass {
    this.entity = entity
    return this
  }

  logo(): string | undefined {
    return !!this.entity.logo
      ? (prefixApiUrl(this.entity.logo) ?? undefined)
      : undefined
  }

  onboarded(): string | null {
    if (this.entity.onboardOn) {
      return formatDistanceToNow(new Date(this.entity.onboardOn), {
        addSuffix: true
      })
    }
    return null
  }

  formattedAccountNumber(): string | null {
    if (this.entity.accountNumber) {
      return (this.entity.accountNumber + '').replace(
        /(\d{4})(\d{4})(\d{4})/,
        '$1-$2-$3'
      )
    }
    return null
  }

  htmlBlockAddress(): ReactNode {
    const a1 = !!this.entity.address1 ? (
      <>
        {this.entity.address1}
        <br />
      </>
    ) : null
    const a2 = !!this.entity.address2 ? (
      <>
        {this.entity.address2}
        <br />
      </>
    ) : null
    let csz = ''
    if (!!this.entity.city) {
      csz += this.entity.city
    }
    if (csz !== '' && !!this.entity.state) {
      csz += ', ' + this.entity.state
    } else if (!!this.entity.state) {
      csz += this.entity.state
    }
    if (csz !== '' && !!this.entity.zipCode) {
      csz += ' ' + this.entity.zipCode
    } else if (!!this.entity.zipCode) {
      csz += this.entity.zipCode
    }
    return (
      <>
        {a1}
        {a2}
        {csz}
      </>
    )
  }

  stringAddress(): string | null {
    const addressProps = [
      this.entity.address1,
      this.entity.address2,
      this.entity.city,
      this.entity.state,
      this.entity.zipCode
    ]
    const address = addressProps
      .filter((prop) => !!prop && prop !== '')
      .join(' ')
    return address || null
  }
}

const CustomerMutator = (
  entity: PartialCustomerEntity | CustomerMutatorInterface
): CustomerMutatorInterface =>
  'mutated' in entity ? entity : new CustomerMutatorClass(entity)

export default CustomerMutator

import { FC, forwardRef, ReactElement, Ref, useCallback, useState } from 'react'
import { TransitionProps } from '@mui/material/transitions'
import {
  Box,
  CircularProgress,
  Dialog,
  Divider,
  Slide,
  styled,
  Typography,
  useTheme
} from '@mui/material'
import ContactSupportTwoToneIcon from '@mui/icons-material/ContactSupportTwoTone'
import { useTranslation } from 'react-i18next'
import Scrollbar from '../../../../components/helpers/Scrollbar'
import wait from '../../../../utils/wait'
import NoContentSimple from '../../../../components/helpers/NoContentSimple'
import SearchForm from './SearchForm'
import getSearchResults, { SearchResultSet } from './getSearchResults'
import SearchResultItem from './SearchResultItem'
import SearchQuickLinks from './SearchQuickLinks'

const Transition = forwardRef(
  (
    props: TransitionProps & { children: ReactElement<any, any> },
    ref: Ref<unknown>
  ) => {
    const { children, ...rest } = props
    return (
      <Slide direction="down" ref={ref} {...rest}>
        {children}
      </Slide>
    )
  }
)

const DialogWrapper = styled(Dialog)(
  () => `
    .MuiDialog-container {
        height: auto;
    }
    
    .MuiDialog-paperScrollPaper {
        max-height: calc(100vh - 64px)
    }
`
)

interface SearchDialogProps {
  open?: boolean
  onClose: () => void
  onRecent: (s: string) => void
}

const SearchDialog: FC<SearchDialogProps> = (props: SearchDialogProps) => {
  const { open = false, onClose, onRecent } = props
  const { t }: { t: any } = useTranslation()
  const theme = useTheme()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchLoading, setSearchLoading] = useState<boolean>(false)
  const [results, setResults] = useState<SearchResultSet>({})
  const hasSearchString = searchTerm !== ''

  const onSearchChange = useCallback(async (search: string) => {
    // console.log('CHANGE SEARCH', search)
    setSearchTerm(search)
    setSearchLoading(true)
    const sR = await getSearchResults(search)
    setResults(sR)
    await wait(300)
    setSearchLoading(false)
  }, [])

  const onSearchSubmit = useCallback(
    async (search: string) => {
      setSearchTerm(search)
      setSearchLoading(true)
      const sR = await getSearchResults(search)
      setResults(sR)
      onRecent(search)
      await wait(300)
      setSearchLoading(false)
    },
    [onRecent]
  )

  const onItemClick = useCallback(() => {
    onRecent(searchTerm)
    onClose()
  }, [onClose, onRecent, searchTerm])

  return (
    <DialogWrapper
      open={open}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="sm"
      fullWidth
      scroll="paper"
      onClose={onClose}
    >
      <Box>
        <SearchForm
          onChange={onSearchChange}
          onSubmit={onSearchSubmit}
          searched={searchTerm}
        />
      </Box>
      <Divider />
      {!searchLoading && (
        <>
          {!hasSearchString && (
            <Typography
              variant="subtitle1"
              component="div"
              sx={{
                background: theme.colors.info.lighter,
                color: theme.colors.info.main,
                borderRadius: theme.general.borderRadius,
                fontSize: theme.typography.pxToRem(13),
                display: 'flex',
                alignItems: 'center',
                p: 1,
                mx: 2,
                my: 2
              }}
            >
              <ContactSupportTwoToneIcon
                sx={{
                  mr: 0.8,
                  fontSize: theme.typography.pxToRem(18)
                }}
              />
              {t(
                'Start typing to see the search results... Hit enter to retain as a RECENT SEARCH'
              )}
            </Typography>
          )}
        </>
      )}
      {searchLoading ? (
        <SearchResultsLoading />
      ) : (
        <>
          {hasSearchString ? (
            <Box
              sx={{
                height: 450
              }}
            >
              <Scrollbar>
                {Object.keys(results).map((type, index) => (
                  <Box px={2} py={1} key={index}>
                    <Typography
                      sx={{
                        py: 1
                      }}
                      variant="h5"
                    >
                      {type}
                    </Typography>
                    {results[type].map((result) => (
                      <SearchResultItem
                        key={result.label}
                        item={result}
                        onClick={onItemClick}
                      />
                    ))}
                    {results[type].length === 0 && (
                      <NoContentSimple>No Matching Results</NoContentSimple>
                    )}
                  </Box>
                ))}
              </Scrollbar>
            </Box>
          ) : (
            <SearchQuickLinks setSearch={onSearchChange} />
          )}
        </>
      )}
    </DialogWrapper>
  )
}

const SearchResultsLoading: FC = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      my: 5
    }}
  >
    <CircularProgress />
  </Box>
)

export default SearchDialog

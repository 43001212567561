import { isEqual } from 'lodash'
import { useRef } from 'react'

export default function useDeepEqualMemo<T>(value: T): T {
  const ref = useRef<T>(value)

  if (!isEqual(ref.current, value)) {
    ref.current = value
  }

  return ref.current
}

import { Suspense } from 'react'
import SuspenseLoader from '../components/helpers/SuspenseLoader'

export const RouteComponentLoader = (Component: any) => (props: any) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
)

export default null

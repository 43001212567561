import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteEntityHelperById,
  mergeEntitiesHelperById,
  setEntitiesHelperById
} from '../../../wdql/helpers'
import {
  PartialPricebookDiscountEntity,
  PricebookDiscountEntity
} from './entity'

const initialState: Array<PricebookDiscountEntity> = []

export const pricebookdiscountSlice = createSlice({
  name: 'pricebookdiscounts',
  initialState,
  reducers: {
    resetPricebookDiscounts: () => [...initialState],
    replacePricebookDiscounts: (
      state,
      action: PayloadAction<Array<PartialPricebookDiscountEntity>>
    ) => [...action.payload] as Array<PricebookDiscountEntity>,
    setPricebookDiscounts: (
      state,
      action: PayloadAction<Array<PartialPricebookDiscountEntity>>
    ) => {
      setEntitiesHelperById(state, action.payload)
    },
    mergePricebookDiscounts: (
      state,
      action: PayloadAction<Array<PartialPricebookDiscountEntity>>
    ) => {
      mergeEntitiesHelperById(state, action.payload)
    },
    patchPricebookDiscount: (
      state,
      action: PayloadAction<PartialPricebookDiscountEntity>
    ) => {
      mergeEntitiesHelperById(state, [action.payload], false)
    },
    deletePricebookDiscount: (
      state,
      action: PayloadAction<number | Partial<PricebookDiscountEntity>>
    ) => deleteEntityHelperById(state, action.payload)
  }
})

export const {
  setPricebookDiscounts,
  replacePricebookDiscounts,
  mergePricebookDiscounts,
  deletePricebookDiscount,
  patchPricebookDiscount,
  resetPricebookDiscounts
} = pricebookdiscountSlice.actions

export default pricebookdiscountSlice.reducer

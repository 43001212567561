import { useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import {
  alpha,
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  styled,
  Typography,
  useTheme
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import UnfoldMoreTwoToneIcon from '@mui/icons-material/UnfoldMoreTwoTone'
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone'
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone'
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone'
import PersonPinIcon from '@mui/icons-material/PersonPin'
import useProfile from '../../../../store/slices/profile/useProfile'
import GhostLoginPopup from '../../../../components/popups/GhostLoginPopup'
import { SHOW_DEV_CODE } from '../../../../_devmode'
import useWdAuthenticator from '../../../../packages/WdAuthenticator/useWdAuthenticator'

const MenuUserBox = styled(Box)(
  ({ theme }) => `
    background: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(2)};
`
)

const UserBoxText = styled(Box)(
  ({ theme }) => `
    text-align: left;
    padding-left: ${theme.spacing(1)};
`
)

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
    font-weight: ${theme.typography.fontWeightBold};
    color: ${theme.sidebar.menuItemColor};
    display: block;

    &.popoverTypo {
      color: ${theme.palette.secondary.main};
    }
`
)

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
    color: ${alpha(theme.sidebar.menuItemColor ?? '', 0.6)};

    &.popoverTypo {
      color: ${theme.palette.secondary.light};
    }
`
)

function SidebarTopSection() {
  const { t }: { t: any } = useTranslation()
  const theme = useTheme()

  const navigate = useNavigate()
  const location = useLocation()
  const { logout } = useWdAuthenticator()
  const { name, picture, title, check } = useProfile()

  const ref = useRef<any>(null)
  const [isOpen, setOpen] = useState<boolean>(false)

  const handleOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose()
      await logout()
      navigate('/')
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Box
      sx={{
        textAlign: 'center',
        mx: 2,
        pt: 1,
        position: 'relative'
      }}
    >
      <Avatar
        sx={{
          width: 68,
          height: 68,
          mb: 2,
          mx: 'auto'
        }}
        alt={name}
        src={picture}
      />

      <Typography
        variant="h4"
        sx={{
          color: `${theme.colors.alpha.trueWhite[100]}`
        }}
      >
        {name}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          color: `${theme.colors.alpha.trueWhite[70]}`
        }}
      >
        {title}
      </Typography>
      <IconButton
        size="small"
        sx={{
          position: 'absolute',
          right: theme.spacing(0),
          color: `${theme.colors.alpha.trueWhite[70]}`,
          top: theme.spacing(0),
          background: `${theme.colors.alpha.trueWhite[10]}`,

          '&:hover': {
            color: `${theme.colors.alpha.trueWhite[100]}`,
            background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`
          }
        }}
        ref={ref}
        onClick={handleOpen}
      >
        <UnfoldMoreTwoToneIcon fontSize="small" />
      </IconButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
      >
        <MenuUserBox
          sx={{
            minWidth: 210
          }}
          display="flex"
        >
          <Avatar variant="rounded" alt={name} src={picture} />
          <UserBoxText>
            <UserBoxLabel className="popoverTypo" variant="body1">
              {name}
            </UserBoxLabel>
            <UserBoxDescription className="popoverTypo" variant="body2">
              {title}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider
          sx={{
            mb: 0
          }}
        />
        <List
          sx={{
            p: 1
          }}
          component="nav"
        >
          {SHOW_DEV_CODE && (
            <ListItemButton
              onClick={() => {
                handleClose()
              }}
              to={`/${
                location.pathname.split('/')[1]
              }/management/users/single/1`}
              component={NavLink}
            >
              <AccountBoxTwoToneIcon fontSize="small" />
              <ListItemText primary={t('Profile')} />
            </ListItemButton>
          )}
          {check.access('ghost.login') && (
            <GhostLoginPopup onClose={handleClose}>
              <ListItemButton>
                <PersonPinIcon fontSize="small" />
                <ListItemText primary={t('Ghost As...')} />
              </ListItemButton>
            </GhostLoginPopup>
          )}
          {SHOW_DEV_CODE && (
            <ListItemButton
              onClick={() => {
                handleClose()
              }}
              to={`/${
                location.pathname.split('/')[1]
              }/applications/projects-board`}
              component={NavLink}
            >
              <AccountTreeTwoToneIcon fontSize="small" />
              <ListItemText primary={t('Projects')} />
            </ListItemButton>
          )}
        </List>
        <Divider />
        <Box m={1}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon
              sx={{
                mr: 1
              }}
            />
            {t('Sign out')}
          </Button>
        </Box>
      </Popover>
    </Box>
  )
}

export default SidebarTopSection

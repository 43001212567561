import { byString, byValue } from 'sort-es'
import { UserEntity } from '../users/entity'
import { changeCase } from '../../../utils/tools'

export type RoleList = Array<{
  key: string
  label: string
}>

export const getRoles = (access: UserEntity['cached']): RoleList => {
  const roles = access?.role
  if (access && roles) {
    return Object.keys(roles)
      .map((k) =>
        roles[k] && k !== 'default' ? { key: k, label: changeCase(k) } : null
      )
      .filter((v): v is NonNullable<typeof v> => v !== null)
      .sort(byValue((i) => i?.key ?? '', byString()))
  }
  return []
}

import { ToWords } from 'to-words'

export const s = (
  count?: number | null,
  withS: string = 's',
  noS: string = ''
) => {
  if (count === 0) {
    return withS
  }
  if (count === undefined || count === null) {
    return noS
  }
  if (count !== 1 && count !== -1) {
    return withS
  }
  return noS
}

export const timeFromSeconds = (
  seconds: number,
  suffix: boolean = true
): string => {
  const suffixString = seconds > 3600 ? 'hr' : 'min'
  const num = seconds > 3600 ? seconds / 60 / 60 : seconds / 60
  let value: string
  if (num % 1 !== 0) {
    value = num.toFixed(2)
  } else {
    value = num.toFixed().replace(/\.0+$/, '')
  }
  if (suffixString === 'min') {
    return value + (suffix ? ' ' + suffixString : '')
  }
  return value + (suffix ? ' ' + suffixString + s(num) : '')
}

export const toWords = new ToWords({
  localeCode: 'en-US',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: true,
    doNotAddOnly: true
  }
})

/**
 * Original Package: https://github.com/joshaven/string_score
 *
 * Scores a string against another string.
 *    score('Hello World', 'he');         //=> 0.5931818181818181
 *    score('Hello World', 'Hello');    //=> 0.7318181818181818
 */
export const scoreString = (
  haystack: string,
  needle: string,
  fuzziness?: number
): number => {
  // If the string is equal to the needle, perfect match.
  if (haystack === needle) {
    return 1
  }

  // if it's not a perfect match and is empty return 0
  if (needle === '') {
    return 0
  }

  let runningScore = 0,
    charScore,
    finalScore,
    idxOf,
    startAt = 0,
    fuzzies = 1,
    fuzzyFactor,
    i

  const string = haystack,
    lString = string.toLowerCase(),
    strLength = string.length,
    lWord = needle.toLowerCase(),
    needleLength = needle.length

  // Cache fuzzyFactor for speed increase
  if (fuzziness) {
    fuzzyFactor = 1 - fuzziness
  }

  // Walk through needle and add up scores.
  // Code duplication occurs to prevent checking fuzziness inside for loop
  if (fuzziness) {
    for (i = 0; i < needleLength; i += 1) {
      // Find next first case-insensitive match of a character.
      idxOf = lString.indexOf(lWord[i], startAt)

      if (idxOf === -1) {
        fuzzies += fuzzyFactor ?? 0
      } else {
        if (startAt === idxOf) {
          // Consecutive letter & start-of-string Bonus
          charScore = 0.7
        } else {
          charScore = 0.1

          // Acronym Bonus
          // Weighing Logic: Typing the first character of an acronym is as if you
          // preceded it with two perfect character matches.
          if (string[idxOf - 1] === ' ') {
            charScore += 0.8
          }
        }

        // Same case bonus.
        if (string[idxOf] === needle[i]) {
          charScore += 0.1
        }

        // Update scores and startAt position for next round of indexOf
        runningScore += charScore
        startAt = idxOf + 1
      }
    }
  } else {
    for (i = 0; i < needleLength; i += 1) {
      idxOf = lString.indexOf(lWord[i], startAt)
      if (-1 === idxOf) {
        return 0
      }

      if (startAt === idxOf) {
        charScore = 0.7
      } else {
        charScore = 0.1
        if (string[idxOf - 1] === ' ') {
          charScore += 0.8
        }
      }
      if (string[idxOf] === needle[i]) {
        charScore += 0.1
      }
      runningScore += charScore
      startAt = idxOf + 1
    }
  }

  // Reduce penalty for longer strings.
  finalScore =
    (0.5 * (runningScore / strLength + runningScore / needleLength)) / fuzzies

  if (lWord[0] === lString[0] && finalScore < 0.85) {
    finalScore += 0.15
  }

  return finalScore
}

export default null

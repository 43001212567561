import { FC, Fragment, ReactElement, ReactNode } from 'react'
import {
  Avatar,
  Box,
  Button,
  Card,
  styled,
  Typography,
  useTheme
} from '@mui/material'
import ViewWeekIcon from '@mui/icons-material/ViewWeek'

const AvatarPending = styled(Avatar)(
  ({ theme }) => `
      width: ${theme.spacing(10)};
      height: ${theme.spacing(10)};
      margin: 0 auto ${theme.spacing(2)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(42)};
      }
`
)

interface NoContentComplexProps {
  color?: 'info' | 'warning' | 'error' | 'success'
  useIcon?: boolean
  icon?: ReactNode | null
  heading?: string | ReactElement
  description?: string | null
  useButton?: boolean
  buttonText?: string
  onClick?: () => void
  card?: boolean
  minWidth?: number // Pass 0 to disable
  padY?: number
}

const NoContentComplex: FC<NoContentComplexProps> = ({
  color = 'info',
  useIcon = true,
  icon = null,
  heading = 'No Records Available',
  description = null,
  useButton = false,
  buttonText = 'Create Entry',
  onClick,
  card = false,
  minWidth = 0,
  padY = 1
}) => {
  const theme = useTheme()
  const bgColor = theme.colors[color].lighter
  const mainColor = theme.colors[color].main

  const CardWrapper = card ? Card : Fragment

  return (
    <CardWrapper>
      <Box
        sx={{
          py: { xs: padY * 2, md: padY * 6, lg: padY * 8 },
          px: { xs: 1 },
          textAlign: 'center'
        }}
        minWidth={minWidth === 0 ? undefined : minWidth}
      >
        {useIcon && (
          <AvatarPending sx={{ backgroundColor: bgColor, color: mainColor }}>
            {icon ?? <ViewWeekIcon />}
          </AvatarPending>
        )}
        <Typography variant="h2" sx={{ pb: !!description ? 0 : 3 }}>
          {heading}
        </Typography>
        {!!description && (
          <Typography
            variant="h4"
            sx={{
              pt: 1,
              pb: 3
            }}
            fontWeight="normal"
            color="text.secondary"
          >
            {description}
          </Typography>
        )}
        {useButton && (
          <Button
            onClick={onClick}
            color={color}
            variant="outlined"
            sx={{
              borderWidth: '2px',
              '&:hover': {
                borderWidth: '2px'
              }
            }}
          >
            {buttonText}
          </Button>
        )}
      </Box>
    </CardWrapper>
  )
}

export default NoContentComplex

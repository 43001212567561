import { version } from './_version'

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_KEY,
  environment: process.env.REACT_APP_ROLLBAR_ENV,
  version,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    client: {
      javascript: {
        code_version: version,
        source_map_enabled: true
      }
    }
  }
}

export default rollbarConfig

import { useCallback, useEffect, useMemo, useState } from 'react'
import useIsMounted from 'ismounted'
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone'
import { produce } from 'immer'
import { useAppSelector } from '../../index'
import { get } from '../../../remote'
import { WdqlResponse } from '../../wdql/types'
import rawFavoriteMenu from '../../../router/menu/segments/favorites'
import { SidebarMenuType } from '../../../router/menu/types'
import useDeepEqualMemo from '../../../hooks/useDeepEqualMemo'
import useProfile from '../profile/useProfile'
import { FavoriteEntity } from './entity'

const getFavoriteMenu = (myFavorites: Array<FavoriteEntity>) =>
  produce(rawFavoriteMenu, (draftMenu: Array<SidebarMenuType>) => {
    draftMenu[0].items =
      myFavorites
        ?.map((f) => ({
          name: f.name,
          icon: StarTwoToneIcon,
          link: f.url
        }))
        .sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        ) ?? []
  })

const findFavoriteFunction =
  (
    userId: number | null,
    favoritesList: Array<FavoriteEntity>
  ): ((path: string) => FavoriteEntity | null) =>
  (path: string): FavoriteEntity | null =>
    favoritesList?.find((f) => f.url === path && f.userId === userId) ?? null

const fetchFavorites = async (onlyMine: boolean) =>
  get<WdqlResponse<Array<FavoriteEntity>>>(
    onlyMine ? '/api/v2/favorites/list/mine' : '/api/v2/favorites/list'
  )

interface useFavoritesResponse {
  favorites: Array<FavoriteEntity>
  loadMine: () => Promise<void>
  loading: boolean
  favoriteMenu: Array<SidebarMenuType>
  findFavorite: (path: string) => FavoriteEntity | null
}

const useFavorites = (
  initLoad: boolean = true,
  onlyMine: boolean = true
): useFavoritesResponse => {
  const isMounted = useIsMounted()
  const [loading, setLoading] = useState<boolean>(false)
  const { id: profileId } = useProfile()
  const favorites = useAppSelector((state) => state.entities.favorites)
  const my_favs = useMemo(
    () => favorites.filter((f) => f.userId === profileId),
    [favorites, profileId]
  )
  const myFavorites = useDeepEqualMemo(my_favs)

  const favoriteMenu = useMemo(
    () => getFavoriteMenu(myFavorites ?? []),
    [myFavorites]
  )

  const findFavorite = useCallback(
    (path: string) => findFavoriteFunction(profileId, myFavorites ?? [])(path),
    [myFavorites, profileId]
  )

  const loadMyFavorites = useCallback(async () => {
    if (isMounted.current) {
      setLoading(true)
      fetchFavorites(onlyMine).then(() => {
        if (isMounted.current) {
          setLoading(false)
        }
      })
    }
  }, [isMounted, onlyMine])

  useEffect(() => {
    if (initLoad && profileId) {
      loadMyFavorites().then()
    }
  }, [initLoad, loadMyFavorites, profileId])

  // const d = {
  //   initLoad: initLoad,
  //   onlyMine: onlyMine,
  //   isMounted: isMounted,
  //   loading: loading,
  //   setLoading: setLoading,
  //   profileId: profileId,
  //   favorites: favorites,
  //   my_favs: my_favs,
  //   myFavorites: myFavorites,
  //   favoriteMenu: favoriteMenu,
  //   loadMyFavorites: loadMyFavorites
  // }
  // useTraceUpdate(d, 'useFavorites')

  return useMemo(
    () => ({
      favorites: (onlyMine ? myFavorites : favorites) ?? [],
      loadMine: loadMyFavorites,
      loading,
      favoriteMenu,
      findFavorite
    }),
    [
      favoriteMenu,
      favorites,
      findFavorite,
      loadMyFavorites,
      loading,
      myFavorites,
      onlyMine
    ]
  )
}

export default useFavorites

import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteEntityHelperById,
  mergeEntitiesHelperById,
  setEntitiesHelperById
} from '../../wdql/helpers'
import { PartialDomainEntity, DomainEntity } from './entity'

const initialState: Array<DomainEntity> = []

export const domainSlice = createSlice({
  name: 'domains',
  initialState,
  reducers: {
    resetDomains: () => [...initialState],
    replaceDomains: (
      state,
      action: PayloadAction<Array<PartialDomainEntity>>
    ) => action.payload as Array<DomainEntity>,
    setDomains: (state, action: PayloadAction<Array<PartialDomainEntity>>) => {
      setEntitiesHelperById(state, action.payload)
    },
    mergeDomains: (
      state,
      action: PayloadAction<Array<PartialDomainEntity>>
    ) => {
      mergeEntitiesHelperById(state, action.payload)
    },
    patchDomain: (state, action: PayloadAction<PartialDomainEntity>) => {
      mergeEntitiesHelperById(state, [action.payload], false)
    },
    deleteDomain: (
      state,
      action: PayloadAction<number | Partial<DomainEntity>>
    ) => deleteEntityHelperById(state, action.payload)
  }
})

export const {
  setDomains,
  replaceDomains,
  mergeDomains,
  deleteDomain,
  patchDomain,
  resetDomains
} = domainSlice.actions

export default domainSlice.reducer

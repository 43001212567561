import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface ProfileState {
  id: number | null
  email: string | null
  name: string | null
  picture: string | null
  title: string | null
}

const initialState: ProfileState = {
  id: null,
  email: null,
  name: null,
  picture: null,
  title: null
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    resetProfile: () => ({ ...initialState }),
    setProfile: (state, action: PayloadAction<Partial<ProfileState>>) => {
      Object.assign(state, action.payload)
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload
    },
    clearEmail: (state) => {
      state.email = null
    }
  }
})

export const { setEmail, clearEmail, setProfile, resetProfile } =
  profileSlice.actions

export default profileSlice.reducer

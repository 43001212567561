import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteEntityHelperById,
  mergeEntitiesHelperById,
  setEntitiesHelperById
} from '../../../wdql/helpers'
import { PartialPaymoTaskEntity, PaymoTaskEntity } from './entity'

const initialState: Array<PaymoTaskEntity> = []

export const paymotaskSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    resetPaymoTasks: () => [...initialState],
    replacePaymoTasks: (
      state,
      action: PayloadAction<Array<PartialPaymoTaskEntity>>
    ) => action.payload as Array<PaymoTaskEntity>,
    setPaymoTasks: (
      state,
      action: PayloadAction<Array<PartialPaymoTaskEntity>>
    ) => {
      setEntitiesHelperById(state, action.payload)
    },
    mergePaymoTasks: (
      state,
      action: PayloadAction<Array<PartialPaymoTaskEntity>>
    ) => {
      mergeEntitiesHelperById(state, action.payload)
    },
    patchPaymoTask: (state, action: PayloadAction<PartialPaymoTaskEntity>) => {
      mergeEntitiesHelperById(state, [action.payload], false)
    },
    deletePaymoTask: (
      state,
      action: PayloadAction<number | Partial<PaymoTaskEntity>>
    ) => deleteEntityHelperById(state, action.payload)
  }
})

export const {
  setPaymoTasks,
  replacePaymoTasks,
  mergePaymoTasks,
  deletePaymoTask,
  patchPaymoTask,
  resetPaymoTasks
} = paymotaskSlice.actions

export default paymotaskSlice.reducer

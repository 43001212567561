import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteEntityHelperById,
  mergeEntitiesHelperById,
  setEntitiesHelperById
} from '../../wdql/helpers'
import { JobTicketEntity, PartialJobTicketEntity } from './entity'

const initialState: Array<JobTicketEntity> = []

export const jobticketSlice = createSlice({
  name: 'jobtickets',
  initialState,
  reducers: {
    // resetJobTickets: () => {
    //   return [...initialState]
    // },
    replaceJobTickets: (
      _state,
      action: PayloadAction<Array<PartialJobTicketEntity>>
    ) => action.payload as Array<JobTicketEntity>,
    setJobTickets: (
      state,
      action: PayloadAction<Array<PartialJobTicketEntity>>
    ) => {
      setEntitiesHelperById(state, action.payload)
    },
    mergeJobTickets: (
      state,
      action: PayloadAction<Array<PartialJobTicketEntity>>
    ) => {
      mergeEntitiesHelperById(state, action.payload)
    },
    patchJobTicket: (state, action: PayloadAction<PartialJobTicketEntity>) => {
      mergeEntitiesHelperById(state, [action.payload], false)
    },
    deleteJobTicket: (
      state,
      action: PayloadAction<number | Partial<JobTicketEntity>>
    ) => deleteEntityHelperById(state, action.payload)
  }
})

export const {
  setJobTickets,
  replaceJobTickets,
  mergeJobTickets,
  deleteJobTicket,
  patchJobTicket
  // resetJobTickets
} = jobticketSlice.actions

export default jobticketSlice.reducer

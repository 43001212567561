import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import {
  ChangeEvent,
  FC,
  forwardRef,
  ReactElement,
  Ref,
  useCallback,
  useEffect,
  useState
} from 'react'
import { IconButton, Slide } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import ForwardIcon from '@mui/icons-material/Forward'
import { TransitionProps } from '@mui/material/transitions'
import LoadingGlobal from '../../helpers/LoadingGlobal'

const Transition = forwardRef(
  (
    props: TransitionProps & { children: ReactElement<any, any> },
    ref: Ref<unknown>
  ) => {
    const { children, ...rest } = props
    return (
      <Slide direction="down" ref={ref} {...rest}>
        {children}
      </Slide>
    )
  }
)

interface TextPopupEditorProps {
  onSave: (s: string) => void
  onClose: (s?: string) => void
  label: string

  initialValue?: string
  open?: boolean
  title?: string
  description?: string
  maxWidth?: DialogProps['maxWidth']
  fullWidth?: boolean
  cancelButton?: boolean
  closeWindow?: boolean
  helperText?: string
  placeholder?: string
  numbersOnly?: boolean
  loading?: boolean
}

const TextPopupEditor: FC<TextPopupEditorProps> = ({
  onClose,
  label,
  onSave,
  title,
  description,
  maxWidth,
  open = false,
  fullWidth = true,
  cancelButton = true,
  closeWindow = true,
  initialValue = '',
  helperText,
  placeholder,
  numbersOnly = false,
  loading = false
}) => {
  const [value, setValue] = useState<string>(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const onCloseAction = useCallback(() => {
    onClose(value)
    setValue(initialValue)
  }, [onClose, value, initialValue])

  const onSaveAction = useCallback(() => {
    onSave(value)
    setValue('')
  }, [onSave, value])

  const setValueField = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let v = e.target.value
      if (numbersOnly) {
        v = v.replace(/\D/g, '')
      }
      setValue(v)
    },
    [numbersOnly, setValue]
  )

  return (
    <Dialog
      open={open}
      onClose={onCloseAction}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      TransitionComponent={Transition}
    >
      <LoadingGlobal show={loading} />
      {!!title && <DialogTitle>{title}</DialogTitle>}
      {closeWindow ? (
        <IconButton
          aria-label="close"
          onClick={onCloseAction}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <DialogContent>
        {!!description && <DialogContentText>{description}</DialogContentText>}
        <TextField
          autoFocus
          margin="dense"
          label={label}
          type="text"
          fullWidth
          variant="standard"
          value={value}
          onChange={setValueField}
          helperText={helperText}
          placeholder={placeholder}
        />
      </DialogContent>
      <DialogActions>
        {cancelButton && (
          <Button
            onClick={onCloseAction}
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Cancel
          </Button>
        )}
        <Button
          onClick={onSaveAction}
          variant="contained"
          endIcon={<ForwardIcon />}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TextPopupEditor

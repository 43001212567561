import {
  AuthcheckResponse,
  LoginResponseType
} from '../../store/slices/auth/useAuthState/types'
import { get, post } from '../../remote'
import {
  FailedCodeValidation,
  PostLoginData,
  PostNewAuthCodeResponse
} from './types'

export const postGoogleLogin = async (
  data: PostLoginData
): Promise<LoginResponseType | null> => {
  const resp = await post<LoginResponseType>('/api/v2/login/google', data)
  return resp?.data ?? null
}

export const postGhostLogin = async (
  id: number,
  data: PostLoginData
): Promise<LoginResponseType | null> => {
  const resp = await post<LoginResponseType>('/api/v2/login/ghost/' + id, data)
  return resp?.data ?? null
}

export const getAuthCheck = async (): Promise<boolean> => {
  let checkStatus = true
  try {
    await get<AuthcheckResponse>('/api/v2/authcheck').then(
      (r) => {
        if (r === null || (!r?.cached && r?.data && r.data.status === 401)) {
          checkStatus = false
        }
      },
      () => {
        checkStatus = false
      }
    )
  } catch (_e) {}
  return checkStatus
}

export const postClearAuthCode = async (code: string): Promise<boolean> => {
  const resp = await post<{ success: boolean }>('/api/v2/login/auth/clear', {
    code
  })
  return resp?.data.success ?? false
}

export const postNewAuthCode = async (
  email: string
): Promise<PostNewAuthCodeResponse | null> => {
  const resp = await post<PostNewAuthCodeResponse>('/api/v2/login/auth/new', {
    email
  })
  return resp?.data ?? null
}

type ValidationResponse = LoginResponseType | FailedCodeValidation
export const postValidateAuthCode = async (data: {
  code: string
  email: string
  validation: string
}): Promise<ValidationResponse | null> => {
  const resp = await post<ValidationResponse>(
    '/api/v2/login/auth/validate',
    data
  )
  return resp?.data ?? null
}

export const getIsAuthCode = async (code: string | null): Promise<boolean> => {
  if (!code) {
    return false
  }
  const resp = await post<boolean>('/api/v2/login/auth/code', {
    code: code.replace(/"/g, '')
  })
  return resp?.data ?? false
}

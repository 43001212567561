import { useCallback, useEffect, useMemo, useState } from 'react'
import useIsMounted from 'ismounted'
import { byString, byValue } from 'sort-es'
import { useAppSelector } from '../../index'
import { get, post } from '../../../remote'
import { WdqlResponse } from '../../wdql/types'
import useDeepEqualMemo from '../../../hooks/useDeepEqualMemo'
import { prefixApiUrl } from '../../../utils/tools'
import { getRoles, RoleList } from '../roles/utility'
import { PartialUserEntity, UserEntity } from './entity'
import { wdqlProcessUsers } from './dispatch'

// interface useFakeHookInterface {}
interface useUsersResponse {
  users: Array<UserEntity>
  load: () => Promise<void>
  loading: boolean
  findUser: (id: number) => UserEntity | null
  toggleUser: (user: number, status: boolean) => void
  userRoles: (userOrId: number | PartialUserEntity) => RoleList
  usersById: (ids: number | Array<number | UserEntity>) => Array<UserEntity>
}

export const useActiveUsers = (initLoad: boolean = true): useUsersResponse => {
  const all = useUsers(initLoad)
  const users = useMemo(
    () =>
      all.users
        .filter((u) => u.status === 'ACTIVE' && u.id > 10000)
        .sort(byValue((u) => u.fullName, byString())),
    [all.users]
  )

  return useMemo(() => ({ ...all, users }), [all, users])
}

const useUsers = (initLoad: boolean = true): useUsersResponse => {
  const isMounted = useIsMounted()
  const [loading, setLoading] = useState<boolean>(false)
  const users = useAppSelector((state) => state.entities.users)
  useEffect(() => {
    const updatedUsers = users.map((u) => {
      const newPicture = !!u.picture
        ? (prefixApiUrl(u.picture) ?? undefined)
        : undefined
      return newPicture !== u.picture ? { ...u, picture: newPicture } : u
    })
    if (updatedUsers.some((u, i) => u !== users[i])) {
      wdqlProcessUsers(updatedUsers, 'merge')
    }
  }, [users])

  const userList = useDeepEqualMemo(users)

  const findUser = useCallback(
    (id: number): UserEntity | null =>
      userList?.find((u) => u.id === id) ?? null,
    [userList]
  )

  const userRoles = useCallback(
    (userOrId: number | PartialUserEntity) => {
      const user = typeof userOrId === 'number' ? findUser(userOrId) : userOrId
      return getRoles(user?.cached)
    },
    [findUser]
  )

  const loadUsers = useCallback(async () => {
    if (isMounted.current) {
      setLoading(true)
      await get<WdqlResponse<Array<UserEntity>>>('/api/v2/users').then(
        (resp) => {
          if (resp?.data.valid && !resp.cached) {
            wdqlProcessUsers(resp.data.payload, 'merge')
          }
          if (isMounted.current) {
            setLoading(false)
          }
        }
      )
    }
  }, [isMounted])

  const toggleUser = useCallback(async (userId: number, active: boolean) => {
    wdqlProcessUsers(
      [{ id: userId, status: active ? 'ACTIVE' : 'INACTIVE' }],
      'patch'
    )
    await post<WdqlResponse<Partial<UserEntity>>>('/api/v2/users/toggle', {
      userId,
      active
    }).then((resp) => {
      if (!resp?.data.valid) {
        wdqlProcessUsers(
          [{ id: userId, status: active ? 'INACTIVE' : 'ACTIVE' }],
          'patch'
        )
      }
    })
  }, [])

  const usersById = useCallback(
    (userIds: number | Array<number | UserEntity>) => {
      const ids =
        typeof userIds === 'number'
          ? [userIds]
          : userIds.map((u) => (typeof u === 'number' ? u : u.id))

      return userList.filter((uL) => ids.includes(uL.id))
    },
    [userList]
  )

  useEffect(() => {
    if (initLoad) {
      loadUsers().then()
    }
  }, [initLoad, loadUsers])

  return useMemo(
    () => ({
      users: userList ?? [],
      load: loadUsers,
      loading,
      findUser,
      toggleUser,
      userRoles,
      usersById
    }),
    [findUser, loadUsers, loading, toggleUser, userList, userRoles, usersById]
  )
}

export default useUsers

import { SliceModes } from '../../wdql/types'
import { storeDispatch } from '../../index'
import { PartialFollowEntity, FollowEntity } from './entity'
import {
  deleteFollow,
  mergeFollows,
  patchFollow,
  replaceFollows,
  setFollows
} from './reducer'

export const wdqlProcessFollows = (
  data: Array<FollowEntity> | Array<PartialFollowEntity>,
  mode: SliceModes
) => {
  switch (mode) {
    case 'replace':
      storeDispatch(setFollows(data))
      break
    case 'merge':
      storeDispatch(mergeFollows(data))
      break
    case 'patch':
      storeDispatch(patchFollow(data[0]))
      break
    case 'clear':
      storeDispatch(replaceFollows(data))
      break
    case 'delete':
      storeDispatch(deleteFollow(data[0]))
      break
  }
}

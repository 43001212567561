import _ from 'lodash'
import { IN_DEV_MODE } from '../config'

const debug = {
  init: () => null,
  log: (...a: any[]) => {
    if (IN_DEV_MODE) {
      console.log(...a)
    }
  },
  update: (...a: any[]) => {
    if (IN_DEV_MODE) {
      console.log(...a)
    }
  },
  live: (...a: any[]) => {
    console.log(...a)
  },
  error: (...a: any[]) => {
    console.error(...a)
  },
  warn: (...a: any[]) => {
    console.warn(...a)
  },
  tron: (d: any, label?: string, preview?: any | null) => {
    if (IN_DEV_MODE) {
      const lab = label ?? 'debug.tron'
      window.tron(d, lab, preview)
    }
  }
}

export const objCompare = <T>(
  obj1: Partial<T>,
  obj2: Partial<T>,
  path: string = ''
): string[] => {
  let nonMatchingPaths: string[] = []

  if (_.isObject(obj1) && _.isObject(obj2)) {
    Object.keys(obj1).forEach((key) => {
      const currentPath = path ? `${path}.${key}` : key

      if (_.has(obj2, key)) {
        const value1 = obj1[key as keyof T]
        const value2 = obj2[key as keyof T]

        if (_.isObject(value1) && _.isObject(value2)) {
          nonMatchingPaths = nonMatchingPaths.concat(
            objCompare(value1 as Partial<T>, value2 as Partial<T>, currentPath)
          )
        } else if (_.isArray(value1) && _.isArray(value2)) {
          if (value1.length !== value2.length) {
            nonMatchingPaths.push(currentPath)
          } else {
            value1.forEach((item, index) => {
              if (!_.isEqual(item, value2[index])) {
                nonMatchingPaths.push(`${currentPath}[${index}]`)
              }
            })
          }
        } else if (!_.isEqual(value1, value2)) {
          nonMatchingPaths.push(currentPath)
        }
      } else {
        nonMatchingPaths.push(currentPath)
      }
    })
  } else if (!_.isEqual(obj1, obj2)) {
    nonMatchingPaths.push(path)
  }

  return nonMatchingPaths
}

export default debug

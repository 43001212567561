import { DispatchMap } from './wdql/types'

export const dispatchMap: DispatchMap = {
  roles: () =>
    import('./slices/roles/dispatch').then((module) => module.wdqlProcessRoles),
  users: () =>
    import('./slices/users/dispatch').then((module) => module.wdqlProcessUsers),
  favorites: () =>
    import('./slices/favorites/dispatch').then(
      (module) => module.wdqlProcessFavorites
    ),
  customers: () =>
    import('./slices/customers/dispatch').then(
      (module) => module.wdqlProcessCustomers
    ),
  domains: () =>
    import('./slices/domains/dispatch').then(
      (module) => module.wdqlProcessDomains
    ),
  searches: () =>
    import('./slices/searches/dispatch').then(
      (module) => module.wdqlProcessSearches
    ),
  customerStates: () =>
    import('./slices/customerstates/dispatch').then(
      (module) => module.wdqlProcessCustomerStates
    ),
  follows: () =>
    import('./slices/follows/dispatch').then(
      (module) => module.wdqlProcessFollows
    ),
  activities: () =>
    import('./slices/activities/dispatch').then(
      (module) => module.wdqlProcessActivities
    ),
  paymoProjects: () =>
    import('./slices/paymo/projects/dispatch').then(
      (module) => module.wdqlProcessPaymoProjects
    ),
  pdContacts: () =>
    import('./slices/pipelinedeals/contacts/dispatch').then(
      (module) => module.wdqlProcessPdContacts
    ),
  paymoTasks: () =>
    import('./slices/paymo/tasks/dispatch').then(
      (module) => module.wdqlProcessPaymoTasks
    ),
  paymoTimeEntries: () =>
    import('./slices/paymo/timeEntries/dispatch').then(
      (module) => module.wdqlProcessPaymoTimeEntries
    ),
  jobTickets: () =>
    import('./slices/job-tickets/dispatch').then(
      (module) => module.wdqlProcessJobTickets
    ),
  pricebookItems: () =>
    import('./slices/pricebook/items/dispatch').then(
      (module) => module.wdqlProcessPricebookItems
    ),
  pdCompanies: () =>
    import('./slices/pipelinedeals/companies/dispatch').then(
      (module) => module.wdqlProcessPdCompanies
    ),
  pdDeals: () =>
    import('./slices/pipelinedeals/deals/dispatch').then(
      (module) => module.wdqlProcessPdDeals
    ),
  folders: () =>
    import('./slices/folders/dispatch').then(
      (module) => module.wdqlProcessFolders
    ),
  pricebookCategories: () =>
    import('./slices/pricebook/categories/dispatch').then(
      (module) => module.wdqlProcessPricebookCategories
    ),
  pricebookDiscounts: () =>
    import('./slices/pricebook/discounts/dispatch').then(
      (module) => module.wdqlProcessPricebookDiscounts
    ),
  accountExecutives: () =>
    import('./slices/account-executives/dispatch').then(
      (module) => module.wdqlProcessAccountExecutives
    ),
  buckets: () =>
    import('./slices/retainers/buckets/dispatch').then(
      (module) => module.wdqlProcessBuckets
    ),
  bucketLedgerEntries: () =>
    import('./slices/retainers/bucket-ledger-entries/dispatch').then(
      (module) => module.wdqlProcessBucketLedgerEntries
    ),
  bucketProjectHistories: () =>
    import('./slices/retainers/bucket-project-histories/dispatch').then(
      (module) => module.wdqlProcessBucketProjectHistories
    ),
  helpScoutConversations: () =>
    import('./slices/helpscout/conversations/dispatch').then(
      (module) => module.wdqlProcessHelpScoutConversations
    )
  // Add more mappings as needed
}

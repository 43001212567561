import { FC, useState, createContext, ReactNode, useCallback } from 'react'

type SidebarContext = {
  sidebarToggle: any
  toggleSidebar: () => void
  closeSidebar: () => void
  toggleFavorites: (force: boolean | null) => void
  showFavorites: boolean
}

interface SidebarProviderInterface {
  children: ReactNode
}

export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext
)

export const SidebarProvider: FC<SidebarProviderInterface> = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState<boolean>(false)
  const [showFavorites, setFavoriteToggle] = useState<boolean>(false)

  const toggleSidebar = useCallback(() => {
    setSidebarToggle(!sidebarToggle)
  }, [sidebarToggle])

  const closeSidebar = useCallback(() => {
    setSidebarToggle(false)
  }, [])

  const toggleFavorites = useCallback(
    (force: boolean | null = null) => {
      if (force !== null) {
        setFavoriteToggle(force)
        return // Explicitly return to satisfy consistent-return rule
      }
      setFavoriteToggle(!showFavorites)
    },
    [showFavorites]
  )

  return (
    <SidebarContext.Provider
      value={{
        sidebarToggle,
        toggleSidebar,
        closeSidebar,
        toggleFavorites,
        showFavorites
      }}
    >
      {children}
    </SidebarContext.Provider>
  )
}

import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  initializeAuth,
  setAuthValue
} from '../../../packages/WdAuthenticator/utilities'
import { AuthState } from '../../../packages/WdAuthenticator/types'

const initialState = initializeAuth()

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearAuth: (state) => {
      state.email = null
      setAuthValue('email', null)
      state.tokenId = null
      setAuthValue('tokenId', null)
      state.googleId = null
      setAuthValue('googleId', null)
      state.sessionId = null
      setAuthValue('sessionId', null)
      state.sessionName = null
      setAuthValue('sessionName', null)
      state.ghostId = null
      setAuthValue('ghostId', null)
    },
    setAuth: (state, action: PayloadAction<Partial<AuthState>>) => {
      const keys = Object.keys(action.payload) as Array<keyof AuthState>
      keys.forEach((k) => {
        const v = action.payload[k]
        if (v !== undefined) {
          setAuthValue(k, v)
          state[k] = v
        }
      })
    },
    setEmail: (state, action: PayloadAction<string>) => {
      setAuthValue('email', action.payload)
      state.email = action.payload
    },
    clearEmail: (state) => {
      setAuthValue('email', null)
      state.email = null
    },
    setTokenId: (state, action: PayloadAction<string>) => {
      setAuthValue('tokenId', action.payload)
      state.tokenId = action.payload
    },
    clearTokenId: (state) => {
      setAuthValue('tokenId', null)
      state.tokenId = null
    },
    setGoogleId: (state, action: PayloadAction<string>) => {
      setAuthValue('googleId', action.payload)
      state.googleId = action.payload
    },
    clearGoogleId: (state) => {
      setAuthValue('googleId', null)
      state.googleId = null
    },
    setSessionId: (state, action: PayloadAction<string>) => {
      setAuthValue('sessionId', action.payload)
      state.sessionId = action.payload
    },
    clearSessionId: (state) => {
      setAuthValue('sessionId', null)
      state.sessionId = null
    },
    setSessionName: (state, action: PayloadAction<string>) => {
      setAuthValue('sessionName', action.payload)
      state.sessionName = action.payload
    },
    clearSessionName: (state) => {
      setAuthValue('sessionName', null)
      state.sessionName = null
    },
    setGhostId: (state, action: PayloadAction<string>) => {
      setAuthValue('ghostId', action.payload)
      state.ghostId = action.payload
    },
    clearGhostId: (state) => {
      setAuthValue('ghostId', null)
      state.ghostId = null
    }
  }
})

export const {
  clearAuth,
  setAuth,
  setEmail,
  clearEmail,
  clearGoogleId,
  clearSessionId,
  clearSessionName,
  clearGhostId,
  clearTokenId,
  setGhostId,
  setGoogleId,
  setSessionId,
  setSessionName,
  setTokenId
} = authSlice.actions

export default authSlice.reducer

import { FC, ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import useInterval from '../../../hooks/useInterval'
import { AUTHCHECK_INTERVAL } from '../../../config'
import AppContextWrapper from '../../../contexts/AppContext'
import useWdAuthenticator from '../../../packages/WdAuthenticator/useWdAuthenticator'
// import useAuth from '.././../hooks/useAuth'

interface AuthenticatedProps {
  children: ReactNode
}

const Authenticated: FC<AuthenticatedProps> = (props: { children: any }) => {
  const { children } = props
  const { isLoggedIn, authcheck } = useWdAuthenticator()
  useInterval(() => (isLoggedIn ? authcheck() : null), AUTHCHECK_INTERVAL)
  if (!isLoggedIn) {
    return <Navigate to="/" replace />
  }
  // console.log('Render Authenticate')

  return <AppContextWrapper>{children}</AppContextWrapper>
}

export default Authenticated

import axios, { AxiosResponse } from 'axios'
import {
  buildMemoryStorage,
  CacheAxiosResponse,
  defaultHeaderInterpreter,
  defaultKeyGenerator,
  InternalCacheRequestConfig,
  setupCache
} from 'axios-cache-interceptor'
import debug from '../utils/debug'
import { API_URL_PREFIX } from '../config'
import { wdqlProcess } from '../store/wdql'
import { SliceResponseType } from '../store/wdql/types'
import { splitDataBlobs } from '../utils/tools'
import {
  authorizationToken,
  sessionData
} from '../packages/WdAuthenticator/utilities'

const updateHeaders = (
  config: InternalCacheRequestConfig
): InternalCacheRequestConfig => {
  if (!config || !config.headers) {
    return config
  }
  // console.log('UPDATE HEADERS')
  const { sessionId, sessionName } = sessionData()
  config.headers.Authorization = authorizationToken()

  // axios.defaults.params = {}
  if (sessionId) {
    config.headers.phpsession = sessionName + '::' + sessionId
  }
  // config.headers.randomIdTEST = randomId()
  return config
}

const axiosApi = setupCache(axios.create(), {
  storage: buildMemoryStorage(),
  generateKey: defaultKeyGenerator,
  headerInterpreter: defaultHeaderInterpreter,
  debug: undefined // console.log,
})

axiosApi.interceptors.request.use(updateHeaders)

// axiosApi.defaults.validateStatus = (status) => {
//   console.log('STATUS', status)
//   if (status === 401) {
//     return true
//   }
//   return status < 400 // Resolve only if the status code is less than 500
// }

export const buildGetUrl = (url: string, options?: any): string => {
  let queryString = ''
  if (options) {
    const query = new URLSearchParams()
    Object.keys(options).forEach((k: string) => {
      query.append(k, options[k])
    })
    const connector = url.includes('?') ? '&' : '?'
    queryString = connector + query.toString()
  }
  return url + queryString
}

const forceLogout = () => {
  console.log('FORCE LOG OUT')
  sessionStorage.clear()
  localStorage.clear()
  window.location.replace('/')
}

// const preLogout = () => {
//   //console.log('Pre-Logout from Axios')
//   //window.location.replace('/logout')
// }

interface PostOptions {
  request?: Record<string, any>
}

interface PatchOptions {
  request?: Record<string, any>
}

interface RemoveOptions {
  data?: Record<string, any>
  request?: Record<string, any>
}

interface GetOptions {
  noCache?: boolean
}

export async function get<RespType>(
  url: string,
  options: GetOptions = {}
): Promise<CacheAxiosResponse<RespType> | null> {
  const FullUrl = API_URL_PREFIX + url
  try {
    const response = await axiosApi.get<RespType>(FullUrl, {
      cache: options.noCache !== true ? { interpretHeader: true } : false
    })
    if (typeof response !== 'object') {
      return null
    }

    const request = {
      url: FullUrl,
      axiosDefaults: axios.defaults,
      storage: {
        local: localStorage,
        session: sessionStorage
      }
      // state: store ? store.getState() : null,
    }
    debug.tron({ request, response }, 'AXIOS', 'GET: ' + FullUrl)

    if (!validAuthHeaderCheck(response) && !noAuthCheckPath()) {
      debug.error('GET: Authentication Check Failed - ' + FullUrl)
      // LOGOUT HERE
      return null
    }

    const responseData = response.data as Record<string, any>
    if (
      !response.cached &&
      !!response.data &&
      typeof response.data === 'object' &&
      'wdql' in response.data &&
      responseData.wdql
    ) {
      await wdqlProcess(responseData.wdql as Array<SliceResponseType>)
    }

    return response
  } catch (e) {
    if (axios.isAxiosError(e) && e.response) {
      if (e.response.status === 440) {
        forceLogout()
      }
    }
    throw new Error('GET FAILED :' + e)
  }
}

export async function post<RespType>(
  url: string,
  payload: Record<string, any>,
  options: PostOptions = {}
): Promise<AxiosResponse<RespType> | null> {
  const FullUrl = API_URL_PREFIX + url
  const data = Object.assign(
    {},
    { payload },
    options.request ? options.request : {}
  )

  try {
    const response = await axiosApi.post<RespType>(FullUrl, data)
    if (typeof response !== 'object') {
      return null
    }

    const request = {
      url: FullUrl,
      data,
      axiosDefaults: axios.defaults,
      storage: {
        local: localStorage,
        session: sessionStorage
      }
    }
    debug.tron({ request, response }, 'AXIOS', 'POST: ' + FullUrl)

    if (!validAuthHeaderCheck(response) && !noAuthCheckPath()) {
      debug.error('POST: Authentication Check Failed - ' + FullUrl)
      // Ideally, forceLogout or similar logic would go here.
      return null
    }
    return response
  } catch (e) {
    if (axios.isAxiosError(e) && e.response) {
      if (e.response.status === 440) {
        forceLogout()
        // Optionally, you could return null or a custom error message here if you don't want to throw an error after logging out.
        return null // Adjust based on your error handling preferences.
      }
    }
    // Adjust the error handling according to your application's needs.
    throw new Error('POST FAILED :' + e)
  }
}

export async function postMultipart<RespType>(
  url: string,
  payload: Record<string, any>,
  options: PostOptions = {}
): Promise<AxiosResponse<RespType> | null> {
  const FullUrl = API_URL_PREFIX + url
  const { data: newPayload, blobs } = splitDataBlobs(payload)
  const data = Object.assign(
    {},
    { payload: newPayload },
    options.request ? options.request : {}
  )

  const formData = new FormData()

  // Iterate over data object's own properties
  Object.keys(data).forEach((dataKey) => {
    formData.append(dataKey, data[dataKey])
  })

  // Iterate over blobs object's own properties
  Object.keys(blobs).forEach((blobKey) => {
    formData.append(blobKey, blobs[blobKey])
  })

  const headers = blobs ? { 'Content-Type': 'multipart/form-data' } : {}

  try {
    const response = await axiosApi.post<RespType>(FullUrl, formData, {
      headers
    })
    if (typeof response !== 'object') {
      return null
    }

    const request = {
      url: FullUrl,
      data,
      axiosDefaults: axios.defaults,
      storage: {
        local: localStorage,
        session: sessionStorage
      }
    }
    debug.tron({ request, response }, 'AXIOS', 'POST: ' + FullUrl)

    if (!validAuthHeaderCheck(response) && !noAuthCheckPath()) {
      debug.error('POST: Authentication Check Failed - ' + FullUrl)
      return null
    }
    return response
  } catch (e) {
    if (axios.isAxiosError(e) && e.response && e.response.status === 440) {
      forceLogout()
      return null // Prevent further execution or rethrow a specific error if needed
    }
    throw new Error('POST FAILED :' + e)
  }
}

export async function patch<RespType>(
  url: string,
  payload: Record<string, any>,
  options: PatchOptions = {}
): Promise<AxiosResponse<RespType> | null> {
  const FullUrl = API_URL_PREFIX + url
  const data = Object.assign(
    {},
    { payload },
    options.request ? options.request : {}
  )

  try {
    const response = await axiosApi.patch<RespType>(FullUrl, data)
    if (typeof response !== 'object') {
      return null
    }

    const request = {
      url: FullUrl,
      data,
      axiosDefaults: axios.defaults,
      storage: {
        local: localStorage,
        session: sessionStorage
      }
    }
    debug.tron({ request, response }, 'AXIOS', 'PATCH: ' + FullUrl)

    if (!validAuthHeaderCheck(response) && !noAuthCheckPath()) {
      debug.error('PATCH: Authentication Check Failed - ' + FullUrl)
      return null
    }
    return response
  } catch (e) {
    if (axios.isAxiosError(e) && e.response && e.response.status === 440) {
      forceLogout()
      return null // Optionally handle differently if needed
    }
    throw new Error('PATCH FAILED :' + e)
  }
}

export async function remove<RespType>(
  url: string,
  options: RemoveOptions = {}
): Promise<AxiosResponse<RespType> | null> {
  const FullUrl = API_URL_PREFIX + url
  const config = {
    // Axios DELETE requests expect the request body to be in the config parameter
    data: options.data ?? {},
    ...(options.request ? options.request : {})
  }

  try {
    const response = await axiosApi.delete<RespType>(FullUrl, config)
    if (typeof response !== 'object') {
      return null
    }

    const request = {
      url: FullUrl,
      data: config.data, // Reflecting the corrected structure for Axios DELETE request
      axiosDefaults: axios.defaults,
      storage: {
        local: localStorage,
        session: sessionStorage
      }
    }
    debug.tron({ request, response }, 'AXIOS', 'DELETE: ' + FullUrl)

    if (!validAuthHeaderCheck(response) && !noAuthCheckPath()) {
      debug.error('DELETE: Authentication Check Failed - ' + FullUrl)
      return null
    }
    return response
  } catch (e) {
    if (axios.isAxiosError(e) && e.response && e.response.status === 440) {
      forceLogout()
      return null // Optionally handle differently if needed
    }
    throw new Error('DELETE FAILED :' + e)
  }
}

export const validAuthHeaderCheck = (
  response: { headers: any } | null
): boolean =>
  response !== null ? response.headers['auth-verified'] === 'true' : false

export function noAuthCheckPath(): boolean {
  const path = String(window.location.pathname).toLowerCase()
  return !path.startsWith('/app')
}

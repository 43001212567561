import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface PermissionListElement {
  value: boolean
  caption?: string
  settable?: boolean
  group?: string
}
export interface PermissionList {
  role: { [k: string]: PermissionListElement }
  permission: { [k: string]: PermissionListElement }
}

export interface BaseState {
  permissionList: PermissionList
}

const initialState: BaseState = {
  permissionList: { role: {}, permission: {} }
}

export const baseSlice = createSlice({
  name: 'base',
  initialState,
  reducers: {
    setPermissionList: (state, action: PayloadAction<PermissionList>) => {
      state.permissionList = action.payload
    }
  }
})

export const { setPermissionList } = baseSlice.actions

export default baseSlice.reducer

import { FC } from 'react'
import { IconButton, styled, Tooltip } from '@mui/material'
import DashboardIcon from '@mui/icons-material/Dashboard'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        width: ${theme.spacing(4)};
        height: ${theme.spacing(4)};
        border-radius: ${theme.general.borderRadiusLg};
`
)

interface DashboardButtonProps {}

const DashboardButton: FC<DashboardButtonProps> = () => {
  const { t }: { t: any } = useTranslation()
  const navigate = useNavigate()
  const goToDashboard = () => navigate('/app')

  return (
    <Tooltip arrow title={t('Go to Dashboard')}>
      <IconButtonWrapper color="primary" onClick={goToDashboard} sx={{ mr: 1 }}>
        <DashboardIcon fontSize="small" />
      </IconButtonWrapper>
    </Tooltip>
  )
}

export default DashboardButton

import { lazy } from 'react'
import { RouteComponentLoader } from '../utils/routes'

const ProjectMapManagerHome = RouteComponentLoader(
  lazy(
    () => import('../content/pages/Administration/Buckets/ProjectMapManager')
  )
)

const adminRoutes = [
  {
    path: 'bucket-map-manager',
    element: <ProjectMapManagerHome />
  }
]

export default adminRoutes

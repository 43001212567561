import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteEntityHelperById,
  mergeEntitiesHelperById,
  setEntitiesHelperById
} from '../../../wdql/helpers'
import {
  PartialBucketProjectHistoryEntity,
  BucketProjectHistoryEntity
} from './entity'

const initialState: Array<BucketProjectHistoryEntity> = []

export const bucketprojecthistorySlice = createSlice({
  name: 'bucketprojecthistories',
  initialState,
  reducers: {
    resetBucketProjectHistories: () => [...initialState],
    replaceBucketProjectHistories: (
      state,
      action: PayloadAction<Array<PartialBucketProjectHistoryEntity>>
    ) => action.payload as Array<BucketProjectHistoryEntity>,
    setBucketProjectHistories: (
      state,
      action: PayloadAction<Array<PartialBucketProjectHistoryEntity>>
    ) => {
      setEntitiesHelperById(state, action.payload)
    },
    mergeBucketProjectHistories: (
      state,
      action: PayloadAction<Array<PartialBucketProjectHistoryEntity>>
    ) => {
      mergeEntitiesHelperById(state, action.payload)
    },
    patchBucketProjectHistory: (
      state,
      action: PayloadAction<PartialBucketProjectHistoryEntity>
    ) => {
      mergeEntitiesHelperById(state, [action.payload], false)
    },
    deleteBucketProjectHistory: (
      state,
      action: PayloadAction<number | Partial<BucketProjectHistoryEntity>>
    ) => deleteEntityHelperById(state, action.payload)
  }
})

export const {
  setBucketProjectHistories,
  replaceBucketProjectHistories,
  mergeBucketProjectHistories,
  deleteBucketProjectHistory,
  patchBucketProjectHistory,
  resetBucketProjectHistories
} = bucketprojecthistorySlice.actions

export default bucketprojecthistorySlice.reducer

import { lazy } from 'react'
import { RouteComponentLoader } from '../utils/routes'

const SupportHome = RouteComponentLoader(
  lazy(() => import('../content/pages/Support'))
)

const SupportWizard = RouteComponentLoader(
  lazy(() => import('../content/pages/Support/Wizard'))
)

const supportRoutes = [
  {
    path: '',
    element: <SupportHome />
  },
  { path: 'new/:customerId?', element: <SupportWizard /> }
]

export default supportRoutes

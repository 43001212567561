import { RouteObject } from 'react-router'

import Authenticated from '../components/access/Authenticated'

import BaseLayout from '../layouts/BaseLayout'
import SidebarLayout from '../layouts/SidebarLayout'

import contentRoutes from './content'
import baseRoutes from './base'

const router: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: baseRoutes
  },
  {
    path: 'app',
    element: (
      <Authenticated>
        <SidebarLayout />
      </Authenticated>
    ),
    children: contentRoutes
  }
]

export default router

import { API_URL_PREFIX } from '../config'
import { isBlob } from './checks'

export const randomInt = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1)) + min

export const prefixApiUrl = (s?: string | null): string | null => {
  if (!s) {
    return null
  }
  if (API_URL_PREFIX && s.startsWith(API_URL_PREFIX)) {
    return s
  }
  return (API_URL_PREFIX ?? '') + s
}

export const changeCase = (s: string): string => {
  const result = s.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export const changeDotCase = (s: string): string =>
  s
    .replaceAll('.', ' -> ')
    .replaceAll('_', ' ')
    .replaceAll(/\b\w/g, (match) => match.toUpperCase())

export const splitKeyValueString = (
  inputString: string,
  char: string = ':'
) => {
  const [key, value] = inputString.includes(char)
    ? inputString.split(char)
    : [null, inputString]
  return {
    key: key?.trim(),
    value: value.trim()
  }
}

export const splitDataBlobs = (
  data: Record<string, any>
): { data: Record<string, any>; blobs: Record<string, Blob> } => {
  const blobs: Record<string, Blob> = {}

  Object.keys(data).forEach((key) => {
    const value = data[key]
    if (isBlob(value)) {
      blobs[key] = value
      delete data[key]
    }
  })

  return { data, blobs }
}

export const xor = (condition1: boolean, condition2: boolean): boolean =>
  (condition1 || condition2) && !(condition1 && condition2)

export const cleanLink = (url: string | null | undefined): string | null => {
  if (!url) return null

  const lowerCaseUrl = url.toLowerCase()
  if (
    lowerCaseUrl.startsWith('http://') ||
    lowerCaseUrl.startsWith('https://')
  ) {
    return lowerCaseUrl
  }

  return 'http://' + lowerCaseUrl
}

export default null

import { Box, BoxProps, styled, Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { omit } from 'lodash'
import { forwardRef } from 'react'
import { LogoProps } from '../LogoSign/types'
import ControlCenterLogo from '../ControlCenterLogo'
import { APP_VERSION } from '../../../config'
import useGlobalContext from '../../../hooks/useGlobalContext'

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
)

const LogoTextWrapper = styled(Box)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        text-align: center;
`
)

const VersionBadge = styled(
  forwardRef<HTMLDivElement, { online: boolean } & BoxProps>((props, ref) => (
    <Box {...omit(props, ['online'])} ref={ref} />
  ))
)<{ online: boolean }>(
  ({ theme, online }) => `
        background: ${
          online ? theme.palette.info.main : theme.palette.error.main
        } ;
        color: #000000;
        padding: ${theme.spacing(0.4, 1)};
        border-radius: ${theme.general.borderRadiusSm};
        text-align: center;
        display: inline-block;
        line-height: 1;
        font-size: ${theme.typography.pxToRem(11)};
`
)

const LogoText = styled(Box)(
  ({ theme }) => `
        color: ${theme.palette.success.contrastText};
        font-size: ${theme.typography.pxToRem(18)};
        font-weight: ${theme.typography.fontWeightBold};
`
)

const Logo = (props: LogoProps) => {
  const { t }: { t: any } = useTranslation()
  const { online } = useGlobalContext()

  return (
    <LogoWrapper to="/app" replace>
      <ControlCenterLogo {...props} />
      <Box component="span">
        <LogoTextWrapper>
          <LogoText>Control Center</LogoText>
          <Tooltip
            title={online ? t('Version') + ' ' + APP_VERSION : 'Offline'}
            arrow
            placement="right"
          >
            <VersionBadge online={online}>{APP_VERSION}</VersionBadge>
          </Tooltip>
        </LogoTextWrapper>
      </Box>
    </LogoWrapper>
  )
}

export default Logo

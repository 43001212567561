import { useContext, useMemo } from 'react'
import { produce } from 'immer'
import { SidebarMenuItemType, SidebarMenuType } from '../router/menu/types'
import rawItems from '../router/menu'
import { AccessRequirement } from '../models/access'
import { SidebarContext } from '../contexts/SidebarContext'
import useFavorites from '../store/slices/favorites/useFavorites'
import useProfile from '../store/slices/profile/useProfile'
import useDeepEqualMemo from './useDeepEqualMemo'

// interface useFakeHookInterface {}
interface useSidebarMenuResponse {
  items: Array<SidebarMenuType>
}

const processChildren = (
  children: Array<SidebarMenuItemType>,
  required: (required?: AccessRequirement) => boolean
): Array<SidebarMenuItemType> =>
  children
    .map((child) => {
      child.display = required(child.access)
      if (child.items) {
        child.items = processChildren(child.items, required)
      }
      return child
    })
    .filter((v) => !!v.display)

const useSidebarMenu = (): useSidebarMenuResponse => {
  const { showFavorites } = useContext(SidebarContext)
  const { check } = useProfile()
  const { favoriteMenu } = useFavorites()
  // const favoriteMenu = rawItems

  const itemSet = showFavorites ? favoriteMenu : rawItems
  const items = useMemo(
    () =>
      produce(itemSet, (draftMenu: Array<SidebarMenuType>) => {
        draftMenu.map((i) => {
          i.display = check.required(i.access)
          // console.log('CHECK', i.heading, i.access, i.display)
          if (i.items) {
            i.items = processChildren(i.items, check.required)
          }
          return i
        })
      }).filter((v) => !!v.display),
    [check, itemSet]
  )
  const deepItems = useDeepEqualMemo(items)

  return useMemo(() => ({ items: deepItems ?? [] }), [deepItems])
}

export default useSidebarMenu

export const sleep = (seconds: number): Promise<void> =>
  new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve()
    }, seconds * 1000)
  })
const wait = (time: number): Promise<void> =>
  new Promise((res) => setTimeout(res, time))

export default wait

import { SliceModes } from '../../wdql/types'
import { storeDispatch } from '../../index'
import { PartialDomainEntity, DomainEntity } from './entity'
import {
  deleteDomain,
  mergeDomains,
  patchDomain,
  replaceDomains,
  setDomains
} from './reducer'

export const wdqlProcessDomains = (
  data: Array<DomainEntity> | Array<PartialDomainEntity>,
  mode: SliceModes
) => {
  switch (mode) {
    case 'replace':
      storeDispatch(setDomains(data))
      break
    case 'merge':
      storeDispatch(mergeDomains(data))
      break
    case 'patch':
      storeDispatch(patchDomain(data[0]))
      break
    case 'clear':
      storeDispatch(replaceDomains(data))
      break
    case 'delete':
      storeDispatch(deleteDomain(data[0]))
      break
  }
}

import { format, parseISO, startOfDay, differenceInDays } from 'date-fns'
import {
  isDateOrDatetime,
  toDateString,
  toLocalDate,
  toServerDate,
  toUTCDate
} from './date'

declare global {
  interface String {
    ucWords(): string
    ucFirst(): string
    nl2br(): string
    utcDate(): Date
    localDate(): Date
    isDatetime(): boolean
    cutOff(maxChars: number): string
    compareToDate(dateToCompare: string | Date): number | null
  }
  interface Number {
    money(options?: MoneyOptions | null): string
    centsToMoney(options?: MoneyOptions | null): string
    dollarsToCents(): number
    centsToDollars(): number
    humanBytes(base10?: boolean): string
    humanHours(): string
    floor(divisor?: number): number
    withCommas(): string
    minutesToText(): string
    roundDecimal(places?: number): number
  }
  interface Date {
    toDatabaseString(format?: 'date' | 'datetime'): string
    serverDate(): Date
    endOfDay(): Date
    midnight(): Date
    toPrettyDate(f?: string): string
    compareTo(dateToCompare: string | Date): number | null
    isBefore(dateToCompare: string | Date, allowOn: boolean): boolean
    isAfter(dateToCompare: string | Date, allowOn: boolean): boolean
    isBetween(
      dateToCompare1: string | Date,
      dateToCompare2: string | Date,
      allowOn: boolean
    ): boolean
  }
}

String.prototype.ucWords = function (): string {
  return this.replace(
    /\w+/g,
    (a) => a.charAt(0).toUpperCase() + a.slice(1).toLowerCase()
  )
}

String.prototype.ucFirst = function (): string {
  return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase()
}

String.prototype.nl2br = function () {
  const breakTag = '<br />'
  return this.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
}

String.prototype.cutOff = function (maxChars) {
  if (this.length > maxChars) {
    return this.slice(0, maxChars - 4) + '...'
  }
  return this.toString()
}

String.prototype.utcDate = function () {
  return toUTCDate(this.toString())
}

String.prototype.isDatetime = function (): boolean {
  if (!this) {
    return false
  }
  return isDateOrDatetime(this.toString())
}

String.prototype.localDate = function () {
  if (!this.isDatetime()) {
    return new Date()
  }
  return toLocalDate(this.toString())
}

String.prototype.compareToDate = function (
  dateToCompare: string | Date = new Date()
): number | null {
  return compareDates(this.valueOf(), dateToCompare)
}

Date.prototype.serverDate = function () {
  return toServerDate(this)
}

Date.prototype.endOfDay = function () {
  this.setHours(23)
  this.setMinutes(59)
  this.setSeconds(59)
  this.setMilliseconds(999)
  return this
}

Date.prototype.midnight = function () {
  this.setHours(0)
  this.setMinutes(0)
  this.setSeconds(0)
  this.setMilliseconds(0)
  return this
}

Date.prototype.toDatabaseString = function (
  format: 'date' | 'datetime' = 'datetime'
) {
  return toDateString(this, format)
}

Date.prototype.toPrettyDate = function (fString: string = 'EEE. MMM do, yyyy') {
  return format(this, fString)
}

Date.prototype.compareTo = function (
  dateToCompare: string | Date = new Date()
): number | null {
  return compareDates(this, dateToCompare)
}

Date.prototype.isBefore = function (
  dateToCompare: string | Date = new Date(),
  allowOn: boolean = false
): boolean {
  const diff = compareDates(this, dateToCompare)
  return allowOn ? diff <= 0 : diff < 0
}

Date.prototype.isAfter = function (
  dateToCompare: string | Date = new Date(),
  allowOn: boolean = false
): boolean {
  const diff = compareDates(this, dateToCompare)
  return allowOn ? diff >= 0 : diff > 0
}

Date.prototype.isBetween = function (
  dateStart: string | Date = new Date(),
  dateEnd: string | Date = new Date(),
  allowOn: boolean = false
): boolean {
  const isAfter = this.isAfter(dateStart, allowOn)
  const isBefore = this.isBefore(dateEnd, allowOn)
  return isAfter && isBefore
}

Number.prototype.dollarsToCents = function (): number {
  return Math.floor(Number(this) * 100)
}

Number.prototype.centsToDollars = function (): number {
  return Number(this) / 100
}

interface MoneyOptions {
  withSign?: boolean
  withCents?: boolean
  forceCents?: boolean
}

Number.prototype.money = function (options?: MoneyOptions | null): string {
  const {
    withSign = true,
    withCents = true,
    forceCents = false
  } = options ?? {}
  const useCents = forceCents || (withCents && String(this).indexOf('.') > -1)
  const isNegative = +this < 0
  return (
    (isNegative ? '-' : '') +
    (withSign ? '$' : '') +
    String(Math.abs(Number(this)).toFixed(useCents ? 2 : 0)).replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    )
  )
}

Number.prototype.centsToMoney = function (
  options?: MoneyOptions | null
): string {
  return (Number(this) / 100).money(options)
}

Number.prototype.humanBytes = function (base10: boolean = true) {
  let bytes = Number(this)
  const thresh = base10 ? 1000 : 1024
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }
  const units = base10
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  do {
    bytes /= thresh
    ++u
  } while (Math.abs(bytes) >= thresh && u < units.length - 1)
  return bytes.toFixed(1) + ' ' + units[u]
}

Number.prototype.humanHours = function () {
  const totalMin = Number(this) * 60
  const hrs = Math.floor(totalMin / 60)
  const min = totalMin - hrs * 60
  const hrsS = hrs === 1 ? '' : 's'
  let str = ''
  if (hrs > 0) {
    str += `${hrs} hr${hrsS}`
  }
  if (min > 0) {
    str += hrs > 0 ? ' ' : ''
    str += `${min} m`
  }
  if (totalMin === 0) {
    return '0 m'
  }
  return str
}

Number.prototype.floor = function (divisor = 1): number {
  return Math.floor(Number(this) / divisor) * divisor
}

Number.prototype.withCommas = function (): string {
  return this.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })
}

Number.prototype.minutesToText = function (): string {
  const hours = Math.floor(Number(this) / 60)
  const mins = Number(this) - hours * 60
  const hourLabel = `${hours} hr` + (hours === 1 ? '' : 's')
  const minLabel = `${mins} min` + (mins === 1 ? '' : 's')
  let label = 'No Time'
  if (hours > 0 || mins > 0) {
    label = (hours > 0 ? hourLabel + ' ' : '') + (mins > 0 ? minLabel : '')
  }
  return label
}

Number.prototype.roundDecimal = function (places: number): number {
  const multiplier = Math.pow(10, places)
  return Math.round(Number(this) * multiplier) / multiplier
}

export {}

// HELPER FUNCTIONS

function compareDates(
  dateToCheck: string | Date,
  dateToCompare: string | Date = new Date()
): number {
  // Helper function to parse and normalize the date to start of the day
  const normalizeDate = (date: string | Date): Date => {
    const parsedDate = typeof date === 'string' ? parseISO(date) : date
    return startOfDay(parsedDate)
  }

  // Normalize both dates to ignore time
  const normalizedDateToCheck = normalizeDate(dateToCheck)
  const normalizedDateToCompare = normalizeDate(dateToCompare)

  // Calculate the difference in days
  return differenceInDays(normalizedDateToCheck, normalizedDateToCompare)
}
